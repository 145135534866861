import React, { useEffect, useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { useDispatch } from "react-redux";
import { SlLocationPin } from "react-icons/sl";
import { FaSearch } from "react-icons/fa";

const GetLocationProfile = ({ setAddress, setFieldValue, values, text }) => {
  const dispatch = useDispatch();
  const [userLocation, setUserLocation] = useState({
    latitude: null,
    longitude: null,
    address: null,
  });

  const [searchInput, setSearchInput] = useState(values?.location.address);

  const fetchUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;

          // Fetch address using Google Maps Geocoder
          const geocoder = new window.google.maps.Geocoder();
          const location = new window.google.maps.LatLng(latitude, longitude);

          geocoder.geocode({ location }, (results, status) => {
            if (status === "OK") {
              const addressComponents = results[0].address_components;
              // Extract city, state, and country from address components
              const city = addressComponents.find((component) =>
                component.types.includes("locality")
              )?.long_name;

              const state = addressComponents.find((component) =>
                component.types.includes("administrative_area_level_1")
              )?.long_name;
              // const postalCode = addressComponents.find((component) =>
              //   component.types.includes("postal_code")
              // )?.long_name;

              const country = addressComponents.find((component) =>
                component.types.includes("country")
              )?.long_name;

              setSearchInput(`${city}, ${state}, ${country}`);
              setAddress(`${city}, ${state}, ${country}`);
              setFieldValue(
                "location.address",
                `${city}, ${state}, ${country}`
              );
              // setFieldValue("location.postcode", postalCode);
              setFieldValue("location", { lat: latitude, lng: longitude });
            } else {
              console.error("Geocoder failed due to:", status);
              // Handle errors here if needed
            }
          });
        },
        (error) => {
          console.error("Error getting user location:", error.message);
          // Handle errors here if needed
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser");
      // Handle the case where geolocation is not supported
    }
  };

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyDv8pOMxP-jYLK9F8KWZd4HLC4Dw-xLWXg",
    onPlaceSelected: (place) => {
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      setSearchInput(place.formatted_address);
      setFieldValue("location", { lat: latitude, lng: longitude });
      // setFieldValue("location.postcode", "000000");
      setAddress(place.formatted_address);
      setFieldValue("location.address", place.formatted_address);
    },
  });

  return (
    <div className="w-full flex justify-center  items-start pt-10">
      <div className="pb-10 w-full">
        <div className="w-full relative">
          <label htmlFor="" className="text-sm font-medium text-black">
            Map Location
          </label>
          <input
            ref={ref}
            placeholder="Street address search"
            className="w-full mt-3 h-12 px-8 rounded-lg border border-primary focus:outline-primary"
            id="standard-basic"
            variant="outlined"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          {/* <Image
          src="/Search.svg"
          alt=""
          width={16}
          height={16}
          className="object-contain absolute top-[60%] right-4 cursor-pointer"
        /> */}
          <FaSearch className="object-contain absolute top-[60%] right-4 cursor-pointer" />
          {/* <Image
          src="/Location.svg"
          alt=""
          width={16}
          height={16}
          className="object-contain absolute top-[60%] left-3 cursor-pointer"
        /> */}
          <SlLocationPin className="object-contain absolute top-[60%] left-3 cursor-pointer" />
        </div>
        <p
          onClick={fetchUserLocation}
          className="my-2 cursor-pointer text-primary text-sm font-semibold "
        >
          FIND MY LOCATION
        </p>
      </div>
    </div>
  );
};

export default GetLocationProfile;
