import React, { useState } from "react";
import { frontLine, nonFrontLine } from "../data";
import { useDispatch, useSelector } from "react-redux";
import { traderSignUpActions } from "../../../../redux/traderSignupSlice";
import { FaSearch } from "react-icons/fa";

const ProgressSix = ({ values, setFieldValue }) => {
  const dispatch = useDispatch();
  const [curr, setCurr] = useState(values?.licenseSector);

  const onFrontlineRoleClick = (role) => {
    setCurr(role);
    setFieldValue("licenseSector", role);
    dispatch(traderSignUpActions.setFrontLineRoleType(role));
  };

  return (
    <div className="w-full flex justify-center flex-wrap pt-8">
      <div className="text-center w-full md:w-7/12">
        <h1 className="text-4xl font-medium">
          Select the position that applies to you
        </h1>
        <p className="w-7/12 mx-auto my-2 text-center">
          Choose the trade that best suits your business. Once your application
          has been approved, you can explore additional options.
        </p>

        <div className="relative w-1/2 mx-auto mt-10">
          <input
            type="text"
            className="w-full mx-auto p-2 pl-10 rounded-xl border border-black focus:outline-primary"
            placeholder="Search here"
          />
          <FaSearch className="w-4 h-4 object-contain absolute left-3 top-0 bottom-0 m-auto" />
        </div>
      </div>
      <div className="w-10/12 mt-5 mx-auto flex flex-wrap gap-2 items-center">
        {values.licenceType === "front_line" ? (
          <>
            {frontLine.map((v, i) => (
              <div
                key={v}
                onClick={() => onFrontlineRoleClick(v)}
                className={
                  "px-3 py-2 cursor-pointer flex items-center gap-2 rounded-md border-2 text-center text-sm font-medium hover:border-primary hover:text-primary " +
                  (curr === v
                    ? "border-primary text-primary"
                    : "border-gray-300 text-secondary")
                }>
                <input
                  readOnly
                  type="radio"
                  className="cursor-pointer"
                  checked={curr && curr === v}
                />{" "}
                <span> {v}</span>
              </div>
            ))}
          </>
        ) : (
          <>
            {nonFrontLine.map((v, i) => (
              <div
                key={v}
                onClick={() => onFrontlineRoleClick(v)}
                className={
                  "px-3 py-2 cursor-pointer flex items-center gap-2 rounded-md border-2 text-center text-sm font-medium hover:border-primary hover:text-primary " +
                  (curr === v
                    ? "border-primary text-primary"
                    : "border-gray-300 text-secondary")
                }>
                <input
                  readOnly
                  type="radio"
                  className="cursor-pointer"
                  checked={curr && curr === v}
                />{" "}
                <span> {v}</span>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default ProgressSix;
