import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import paymentSuccess from "../../components/lottie/paymentSuccess.json";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PaymentSuccess = () => {
  const [secondsRemaining, setSecondsRemaining] = useState(5);
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.Authsection);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const session_id = searchParams.get("session_id") || "";
    const transaction_id = searchParams.get("transaction_id") || "";

    fetch(
      `${process.env.REACT_APP_BASE_URL}/client/payments/check-status?session_id=${session_id}&transaction_id=${transaction_id}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data)
      .catch((err) => err);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        navigate("/");
        clearInterval(intervalId);
      }
    }, 1000);
  }, [navigate, secondsRemaining]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: paymentSuccess,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <Lottie options={defaultOptions} height={"35%"} width={"20%"} />
      <p className="font-medium text-[20px] text-[#029ef2]">
        Payment Successful. You will be redirected to homepage...
      </p>
    </div>
  );
};

export default PaymentSuccess;
