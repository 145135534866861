import React from "react";

const MemberDetails = ({ currMember }) => {
  return (
    <>
      <div className="w-full py-10  border-y border-gray-200">
        <p className="text-lg underline font-medium mb-8">
          Professional details
        </p>
        <div className="flex gap-10 md:flex-row flex-col flex-wrap">
          <div className="mb-5">
            <p className="text-gray-400 text-sm font-normal">Name</p>
            <div className="font-semibold">
              {currMember?.guard_first_name} {currMember?.guard_last_name}
            </div>
          </div>
          <div>
            <p className="text-gray-400 text-sm font-normal">Trading name </p>
            <div className="font-semibold">
              {currMember?.trading_name || "/"}
            </div>
          </div>
          <div className="mb-5">
            <p className="text-gray-400 text-sm font-normal">
              Registered company name{" "}
            </p>
            <div className="font-semibold">
              {currMember?.registered_company_name || "/"}
            </div>
          </div>
          <div>
            <p className="text-gray-400 text-sm font-normal">
              Company registration number
            </p>
            <div className="font-semibold">
              {currMember?.company_reg_no || "/"}
            </div>
          </div>
          <div>
            <p className="text-gray-400 text-sm font-normal">
              Full Name of partners
            </p>
            <div className="font-semibold">
              {currMember?.fullNames_of_partners || "/"}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full py-10  border-t border-gray-200">
        <p className="text-lg underline font-medium mb-8">Personal details </p>
        <div className="flex gap-10 flex-wrap">
          <div className="mb-5">
            <p className="text-gray-400 text-sm font-normal">Email address</p>
            <div className="font-semibold">{currMember?.guard_email}</div>
          </div>
          <div>
            <p className="text-gray-400 text-sm font-normal">Phone number </p>
            <div className="font-semibold">{currMember?.guard_phone}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberDetails;
