import { Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ListLoader from "../../loader/listLoader";
import moment from "moment";
import { getClientReviewAction } from "../../../redux/client/action";

function ClientReviews() {
  const dispatch = useDispatch();
  const { getClientReview, getClientReviewLoading } = useSelector(
    (state) => state.ClientSection
  );
  const [data, setdata] = useState([]);
  useEffect(() => {
    dispatch(getClientReviewAction());
  }, []);

  useEffect(() => {
    if (getClientReview?.data?.length > 0) {
      setdata(getClientReview?.data);
    }
  }, [getClientReview]);

  return (
    <div className="bg-[#F1F2F6] w-full md:py-10 py-4 md:px-20 px-4 grid grid-cols-4 gap-10">
      {!getClientReviewLoading ? (
        data?.length > 0 && data?.length > 0 ? (
          data.map((item, index) => (
            <div
              key={index}
              className="p-4 bg-white rounded-lg border lg:col-span-2 col-span-4 border-blue-200 flex flex-col gap-5"
            >
              <div className="flex justify-between">
                <div className="text-xs">
                  <div className=" font-extrabold">
                    {item.guard.first_name} {item.guard.last_name}
                  </div>
                  <div className="font-medium mt-2">
                    {moment(item.meta?.created_on).format("DD MMMM YYYY")}
                  </div>
                </div>
                <div className="my-auto">
                  <Rating
                    precision={0.5}
                    value={item.rating}
                    readOnly
                    size="small"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <div className="text-[10px] text-gray-400">Job type</div>
                <div className="text-xs font-extrabold">{item.job.title}</div>
                <div className="text-xs text-gray-500">{item.description}</div>
              </div>
            </div>
          ))
        ) : (
          <NothingHere />
        )
      ) : (
        <div className="m-auto col-span-4">
          <ListLoader />
        </div>
      )}
    </div>
  );
}

export default ClientReviews;

const NothingHere = () => (
  <div className="flex text-[20px] font-bold col-span-4  leading-[24px] text-[#AAAA]  text-center m-auto 	">
    You haven't posted any reviews yet!
  </div>
);
