import React from "react";
import { PiGraduationCap } from "react-icons/pi";
import { BsBag } from "react-icons/bs";

import { motion } from "framer-motion";

const _inital = {
  y: "20%",
  opacity: 0,
  scale: 0.8,
};
const _viewport = { once: true };

const _whileInView = {
  y: 1,
  opacity: 1,
  scale: 1,
  transition: { duration: 0.8 },
};

const StudentsReview = () => {
  const data = [
    {
      image:
        "https://framerusercontent.com/images/SINMSNquI52spo94SYFgkji9nRc.png",
      paragraph:
        "Iguardu  has been a game-changer in my job search. Within days of signing up, I started receiving tailored job alerts that matched my preferences perfectly. The real-time notifications kept me updated on new opportunities, ensuring I never missed out. The platform's intuitive interface made it easy for me to navigate, and the support team was always available to assist me. Thanks to iGuardu, I found my dream security job faster!!",
      name: "Sarah G",
      college: "Oregon '23",
      work: "Private Credit at Goldman Sachs",
    },
    {
      image:
        "https://framerusercontent.com/images/s77rGYxm5ldBHfXLW6mq5Q4tLhg.png",
      paragraph:
        "Using Iguardu for recruitment has been a breeze. Posting job listings and managing applications was effortless, and the quality of candidates I received exceeded my expectations. The platform's networking tracking feature allowed me to stay organized and connect with industry professionals seamlessly. Iguardu user-friendly interface and excellent customer support made the entire hiring process smooth and efficient. I highly recommend Iguardu to any employer in the security industry.",
      name: "Fiona L",
      college: "Claremont McKenna '26",
      work: "IB at Greenhill",
    },
    {
      image:
        "https://framerusercontent.com/images/kuKE7xyS1YivhRFZdKadhnRFN4.png",
      paragraph:
        "Iguardu has been instrumental in advancing my security career. The platform's job alerts kept me informed about relevant opportunities, and the resources for professional development helped me enhance my skills.  Overall, Iguardu has been a valuable asset in my career growth journey.",
      name: "Kiara W",
      college: "UCLA '25",
      work: "IB at RBC",
    },
  ];

  return (
    <div className="relative">
      <div className="">
        <div className="lg:py-28 lg:p-20 p-5">
          <motion.div
            initial={_inital}
            viewport={_viewport}
            whileInView={_whileInView}
            className=" text-[#e1e5ec] leading-[1.1] text-center font-medium text-[clamp(42px,calc(62/1340*100vw),62px)] mb-20 max-w-[1100px] mx-auto">
            Trusted by thousands of security professionals
          </motion.div>
          <div className="grid grid-cols-3 gap-[25px] lg:gap-14 lg:w-[100%] xl:w-[90%] w-full m-auto text-black  ">
            {data.map((item, index) => (
              <motion.div
                initial={_inital}
                viewport={_viewport}
                whileInView={{
                  y: 1,
                  opacity: 1,
                  scale: 1,
                  transition: { duration: 0.8, delay: 0.1 * index },
                }}
                className="bg-white px-8 lg:col-span-1 col-span-3 py-10 h-fit rounded-lg my-auto gap-5 text-center "
                key={index}>
                <img
                  src={item.image}
                  alt="profile"
                  height={90}
                  width={90}
                  className="mx-auto mb-5"
                />
                <p className="">{item.paragraph}</p>
                <div className=" mt-5">
                  <span className=" text-lg font-semibold">{item.name}</span>
                </div>
                <div className="flex justify-center gap-2 mt-2">
                  <PiGraduationCap className=" my-auto" />
                  <span className="text-[13px] ">{item.college}</span>
                </div>
                <div className="flex justify-center gap-2 mt-2">
                  <BsBag className=" my-auto" />
                  <span className="text-[13px] ">{item.work}</span>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentsReview;
