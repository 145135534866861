import { all, fork, call, takeEvery, put, select } from "redux-saga/effects";
import {
  GET_DASHBOARD,
  GET_ADMIN_JOBS,
  GET_DASHBOARD_CHARTS,
  GET_TRADE_MEMBERS,
  GET_USERS,
  GET_REVIEWS,
  PATCH_TRADE_MEMBERS,
  DELETE_USERS,
  PATCH_JOBS,
  PATCH_LICENSE,
  PATCH_CLIENT,
  PATCH_REVIEWS,
  GET_TRADE_MEMBERS_BETWEEN_DATES,
  GET_USERS_BETWEEN_DATES,
  GET_USERS_BETWEEN_DATES_SUCCESS,
  GET_USERS_BETWEEN_DATES_ERROR,
  GET_JOBS_BETWEEN_DATES,
  GET_FILTERED_TRADE_MEMBERS,
  UPDATE_ADMIN_PASSWORD,
  UPDATE_PASSOWRD,
} from "../actions";
import {
  getDashboardSuccess,
  getDashboardError,
  getDashboardChartsSuccess,
  getDashboardChartsError,
  getTradeMembersSuccess,
  getTradeMembersError,
  getUsersSuccess,
  getUsersError,
  getJobsSuccess,
  getJobsError,
  getReviewsSuccess,
  getReviewsError,
  patchTradeMembersSuccess,
  patchTradeMembersError,
  patchJobSuccess,
  patchJobError,
  deleteUserSuccess,
  deleteUserError,
  patchLicenseSuccess,
  patchLicenseError,
  patchClientSuccess,
  patchClientError,
  patchReviewSuccess,
  patchReviewError,
  getTradeMembersBetweenDatesSuccess,
  getTradeMembersBetweenDatesError,
  getJobsBetweenDatesSuccess,
  getJobsBetweenDatesError,
  getUsersBetweenDatesSuccess,
  getUsersBetweenDatesError,
  getFilteredTradeMembersSuccess,
  getFilteredTradeMembersError,
  updateAdminPasswordSuccess,
  updateAdminPasswordError,
  updatePasswordSuccess,
  updatePasswordError,
} from "./action";

const ApiLinks = process.env.REACT_APP_BASE_URL;

function* patchReviewCall(payload) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);

    // Make the API call with the token
    const getApiRes = yield call(patchReviewAsync, payload, token);

    yield put(patchReviewSuccess(getApiRes));
  } catch (error) {
    yield put(patchReviewError());
  }
}

const patchReviewAsync = (payload, token) =>
  fetch(`${ApiLinks}/admin/reviews/${payload.payload.id}`, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchPatchReviewData() {
  yield takeEvery(PATCH_REVIEWS, patchReviewCall);
}

function* patchCLientCall(payload) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);

    // Make the API call with the token
    const getApiRes = yield call(patchCLientAsync, payload, token);

    yield put(patchClientSuccess(getApiRes));
  } catch (error) {
    yield put(patchClientError());
  }
}

const patchCLientAsync = (payload, token) =>
  fetch(`${ApiLinks}/auth/user/${payload.payload.id}/update`, {
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload.payload.data),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchPatchClientData() {
  yield takeEvery(PATCH_CLIENT, patchCLientCall);
}

function* getDashboardCall(payload) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);

    // Make the API call with the token
    const getApiRes = yield call(getDashboardAsync, payload, token);

    yield put(getDashboardSuccess(getApiRes));
  } catch (error) {
    yield put(getDashboardError());
  }
}

const getDashboardAsync = (payload, token) =>
  fetch(`${ApiLinks}/admin/dashboard`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((data) => data?.data)
    .catch((err) => err);

export function* watchgetDashboardData() {
  yield takeEvery(GET_DASHBOARD, getDashboardCall);
}

//get ratings
function* getDashboardChartsCall(paylaod) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);

    // Make the API call with the token
    const getApiRes = yield call(getDashboardChartsAsync, paylaod, token);

    yield put(getDashboardChartsSuccess(getApiRes));
  } catch (error) {
    yield put(getDashboardChartsError());
  }
}

const getDashboardChartsAsync = (payload, token) =>
  fetch(`${ApiLinks}/admin/dashboard/charts`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchGetDashboardChartsData() {
  yield takeEvery(GET_DASHBOARD_CHARTS, getDashboardChartsCall);
}
//get Jobs
function* getJobsCall(payload) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);
    // Make the API call with the token
    const getApiRes = yield call(getJobsAsync, payload, token);

    yield put(getJobsSuccess(getApiRes));
  } catch (error) {
    yield put(getJobsError());
  }
}

const getJobsAsync = (payload, token) =>
  fetch(
    `${ApiLinks}/admin/jobs?limit=${payload?.payload?.limit}&page=${payload?.payload?.skip}&keywords=${payload?.payload?.keywords}&status=${payload?.payload?.status}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchGetJobsData() {
  yield takeEvery(GET_ADMIN_JOBS, getJobsCall);
}
//get Job List
function* getTradeMembersCall(payload) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);

    // Make the API call with the token
    const getApiRes = yield call(getTradeMembersAsync, payload, token);

    yield put(getTradeMembersSuccess(getApiRes));
  } catch (error) {
    yield put(getTradeMembersError());
  }
}

const getTradeMembersAsync = (payload, token) => {
  const filter = payload?.payload?.action
    ? `${payload?.payload?.action}=${payload?.payload?.value}`
    : "";

  return fetch(
    `${ApiLinks}/admin/guards?limit=${payload?.payload?.limit}&page=${payload?.payload?.skip}&keywords=${payload?.payload?.keywords}&${filter}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);
};

export function* watchTradeMembersData() {
  yield takeEvery(GET_TRADE_MEMBERS, getTradeMembersCall);
}

function* getUsersCall(payload) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);

    // Make the API call with the token
    const getApiRes = yield call(getUsersAsync, payload, token);

    yield put(getUsersSuccess(getApiRes));
  } catch (error) {
    yield put(getUsersError());
  }
}

const getUsersAsync = async (payload, token) => {
  try {
    const filter = payload?.payload?.action
      ? `${payload?.payload?.action}=${payload?.payload?.value}`
      : "";
    const res = await fetch(
      `${ApiLinks}/admin/clients?limit=${payload?.payload?.limit}&page=${payload?.payload?.skip}&keywords=${payload?.payload?.keywords}&${filter}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();
    return data;
  } catch (err) {
    return err;
  }
};

export function* watchUsersData() {
  yield takeEvery(GET_USERS, getUsersCall);
}

function* getReviewsCall(paylaod) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);

    // Make the API call with the token
    const getApiRes = yield call(getReviewsAsync, paylaod, token);

    yield put(getReviewsSuccess(getApiRes));
  } catch (error) {
    yield put(getReviewsError());
  }
}

const getReviewsAsync = (payload, token) =>
  fetch(`${ApiLinks}/admin/reviews/`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((data) => data?.data)
    .catch((err) => err);

export function* watchReviewsData() {
  yield takeEvery(GET_REVIEWS, getReviewsCall);
}
//post job apply
function* patchTradeMembersCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(patchTradeMembersAsync, paylaod, token);

    yield put(patchTradeMembersSuccess(getApiRes));
  } catch (error) {
    yield put(patchTradeMembersError());
  }
}
const patchTradeMembersAsync = (payload, token) =>
  fetch(`${ApiLinks}/auth/user/${payload.payload.guard_id}/update`, {
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload.payload.data),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchpatchTradeMembersCallData() {
  yield takeEvery(PATCH_TRADE_MEMBERS, patchTradeMembersCall);
}
//create license
function* deleteUserCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(deleteUserAsync, paylaod, token);

    yield put(deleteUserSuccess(getApiRes));
  } catch (error) {
    yield put(deleteUserError());
  }
}
const deleteUserAsync = (payload, token) =>
  fetch(`${ApiLinks}/user/`, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchDeleteUserData() {
  yield takeEvery(DELETE_USERS, deleteUserCall);
}
//upadte job status
function* patchJobStatusCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(patchJobStatusAsync, paylaod, token);

    yield put(patchJobSuccess(getApiRes));
  } catch (error) {
    yield put(patchJobError());
  }
}
const patchJobStatusAsync = (payload, token) =>
  fetch(`${ApiLinks}/admin/jobs/${payload.payload.id}/update`, {
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload.payload.data),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchpatchJobStatusData() {
  yield takeEvery(PATCH_JOBS, patchJobStatusCall);
}

//Patch license status
function* patchLicenseStatusCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(patchLicenseStatusAsync, paylaod, token);

    yield put(patchLicenseSuccess(getApiRes));
  } catch (error) {
    yield put(patchLicenseError());
  }
}
const patchLicenseStatusAsync = (payload, token) =>
  fetch(`${ApiLinks}/admin/guards/${payload.payload.id}/license-update`, {
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ status: payload.payload.status }),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchPatchLicenseStatusData() {
  yield takeEvery(PATCH_LICENSE, patchLicenseStatusCall);
}

function* getTradeMembersBetweenDatesCall(payload) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);

    // Make the API call with the token
    const getApiRes = yield call(
      getTradeMembersBetweenDatesAsync,
      payload,
      token
    );

    yield put(getTradeMembersBetweenDatesSuccess(getApiRes));
  } catch (error) {
    yield put(getTradeMembersBetweenDatesError());
  }
}

const getTradeMembersBetweenDatesAsync = (payload, token) => {
  return fetch(`${ApiLinks}/admin/guards/export`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);
};

export function* watchTradeMembersBetweenDatesData() {
  yield takeEvery(
    GET_TRADE_MEMBERS_BETWEEN_DATES,
    getTradeMembersBetweenDatesCall
  );
}

function* getUsersBetweenDatesCall(payload) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);

    // Make the API call with the token
    const getApiRes = yield call(getUsersBetweenDatesAsync, payload, token);

    yield put(getUsersBetweenDatesSuccess(getApiRes));
  } catch (error) {
    yield put(getUsersBetweenDatesError());
  }
}

const getUsersBetweenDatesAsync = (payload, token) => {
  return fetch(`${ApiLinks}/admin/clients/export`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);
};

export function* watchUsersBetweenDatesData() {
  yield takeEvery(GET_USERS_BETWEEN_DATES, getUsersBetweenDatesCall);
}

function* getJobsBetweenDatesCall(payload) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);

    // Make the API call with the token
    const getApiRes = yield call(getJobsBetweenDatesAsync, payload, token);

    yield put(getJobsBetweenDatesSuccess(getApiRes));
  } catch (error) {
    yield put(getJobsBetweenDatesError());
  }
}

const getJobsBetweenDatesAsync = (payload, token) => {
  return fetch(`${ApiLinks}/admin/jobs/export`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);
};

export function* watchJobsBetweenDatesData() {
  yield takeEvery(GET_JOBS_BETWEEN_DATES, getJobsBetweenDatesCall);
}

function* getFilteredTradeMembersCall(payload) {
  try {
    // Get the token from the Redux state using the selector
    const { token } = yield select((state) => state.Authsection);

    // Make the API call with the token
    const getApiRes = yield call(getFilteredTradeMembersAsync, payload, token);

    yield put(getFilteredTradeMembersSuccess(getApiRes));
  } catch (error) {
    yield put(getFilteredTradeMembersError());
  }
}

const getFilteredTradeMembersAsync = (payload, token) => {
  return fetch(`${ApiLinks}/admin/trade`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);
};

export function* watchFilteredTradeMembersData() {
  yield takeEvery(GET_FILTERED_TRADE_MEMBERS, getFilteredTradeMembersCall);
}

function* updateAdminPasswordCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(updateAdminPasswordAsync, paylaod, token);

    yield put(updateAdminPasswordSuccess(getApiRes));
  } catch (error) {
    yield put(updateAdminPasswordError());
  }
}

const updateAdminPasswordAsync = async (payload, token) => {
  let res = await fetch(`${ApiLinks}/admin/profile/update-password`, {
    method: "PATCH",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload.payload),
  });

  const data = await res.json();
  return data;
};

export function* watchUpdateAdminPassword() {
  yield takeEvery(UPDATE_ADMIN_PASSWORD, updateAdminPasswordCall);
}

function* updatePasswordCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    const getApiRes = yield call(updatePasswordAsync, paylaod, token);

    yield put(updatePasswordSuccess(getApiRes));
  } catch (error) {
    yield put(updatePasswordError());
  }
}

const updatePasswordAsync = async (payload, token) => {
  let res;
  if (payload.payload.type === "member") {
    res = await fetch(`${ApiLinks}/admin/guards/update-password`, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload.payload.passwordDetails),
    });
  } else {
    res = await fetch(`${ApiLinks}/admin/clients/update-password`, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload.payload.passwordDetails),
    });
  }

  const data = await res.json();
  return data;
};

export function* watchUpdatePassword() {
  yield takeEvery(UPDATE_PASSOWRD, updatePasswordCall);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetJobsData),
    fork(watchGetDashboardChartsData),
    fork(watchGetJobsData),
    fork(watchgetDashboardData),
    fork(watchpatchJobStatusData),
    fork(watchpatchTradeMembersCallData),
    fork(watchpatchJobStatusData),
    fork(watchDeleteUserData),
    fork(watchReviewsData),
    fork(watchUsersData),
    fork(watchTradeMembersData),
    fork(watchPatchLicenseStatusData),
    fork(watchPatchClientData),
    fork(watchPatchReviewData),
    fork(watchTradeMembersBetweenDatesData),
    fork(watchUsersBetweenDatesData),
    fork(watchJobsBetweenDatesData),
    fork(watchFilteredTradeMembersData),
    fork(watchUpdateAdminPassword),
    fork(watchUpdatePassword),
  ]);
}
