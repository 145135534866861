import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { updatePasswordAction } from "../../../../redux/admin/action";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

const UpdateUserPassword = ({ currUser }) => {
  const dispatch = useDispatch();
  const { updatePassword, updatePasswordLoading } = useSelector(
    (state) => state.Adminsection
  );
  const [updatePressed, setUpdatePressed] = useState(false);

  const initialPassValues = {
    new_password: "",
    confirm_password: "",
  };

  const validationSchema = Yup.object({
    new_password: Yup.string().required("Password is required"),
    confirm_password: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
  });

  useEffect(() => {
    if (updatePassword?.success && updatePressed) {
      toast.success(updatePassword?.message);
      //   window.location.reload();
      setUpdatePressed(false);
    } else if (!updatePassword?.success && updatePressed) {
      toast.error(updatePassword?.message);
      setUpdatePressed(false);
    }
  }, [updatePassword]);

  const onSubmitPass = (value, { resetForm }) => {
    const payload = {
      passwordDetails: {
        client_id: currUser?.id,
        password: value.new_password ? value.new_password : "",
      },
      type: "user",
    };

    console.log(payload);

    dispatch(updatePasswordAction(payload));
    setUpdatePressed(true);

    resetForm();
  };

  return (
    <div className="border-b py-10 w-full">
      <p className="text-black font-semibold mb-4">Update Password</p>

      <div className="w-full">
        <Formik
          initialValues={initialPassValues}
          validationSchema={validationSchema}
          onSubmit={onSubmitPass}
        >
          {({ resetForm }) => (
            <Form>
              <div className="flex flex-col gap-4 w-full pt-[30px] pb-[5px] h-full poppins md:flex-row items-center">
                <div className="flex flex-col w-full">
                  <div className="flex flex-col">
                    <label
                      htmlFor="new-password"
                      className="text-[#999999] text-xs pl-1"
                    >
                      New Password
                    </label>
                    <Field
                      type="password"
                      name="new_password"
                      className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
                    />
                  </div>

                  <div className="text-sm text-red-500 mt-2">
                    <ErrorMessage name="new_password" />
                  </div>
                </div>

                <div className="flex flex-col w-full">
                  <div className="flex flex-col">
                    <label
                      htmlFor="confirm-password"
                      className="text-[#999999] text-xs pl-1"
                    >
                      Confirm New Password
                    </label>
                    <Field
                      type="password"
                      id="confirm-password"
                      name="confirm_password"
                      className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
                    />
                  </div>

                  <div className="text-sm text-red-500 mt-2">
                    <ErrorMessage
                      name="confirm_password"
                      className="error-message"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:col-start-2 md:col-span-1 col-span-2 mt-5">
                <button
                  type="submit"
                  className="bg-primary px-7 py-2.5 text-sm text-white justify-center flex items-center md:w-fit w-full rounded-md ml-auto"
                >
                  {updatePasswordLoading ? (
                    <img
                      src={"/loading.svg"}
                      alt="loading"
                      className="w-6 h-6"
                    />
                  ) : (
                    <span>Update</span>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpdateUserPassword;
