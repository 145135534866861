export const formatText = (input) => {
  const lines = input.split("\n");
  let output = "";

  lines.forEach((line) => {
    if (line.startsWith("**") && line.endsWith("**:")) {
      const mainHeading = line.slice(2, -2);
      output += `<b>${mainHeading}</b>\n\n`;
    } else if (line.startsWith("*")) {
      if (line.includes("**")) {
        const parts = line.split("**");
        const subHeading = parts[1];
        const content = parts.slice(2).join("**").trim();
        output += `${subHeading}: ${content}\n\n`;
      } else {
        output += `* ${line.slice(1).trim()}\n`;
      }
    } else {
      output += `${line.trim()}\n`;
    }
  });

  return output;
};
