import {
  CLEAR_ROLE, CLEAR_TOKEN, GET_LOCATION,
  GET_LOCATION_ERROR, GET_LOCATION_SUCCESS, GET_ROLE,
  GET_TOKEN, PATCH_RESET_PASSWORD, PATCH_RESET_PASSWORD_ERROR,
  PATCH_RESET_PASSWORD_SUCCESS, POST_FORGET_PASSWORD, POST_FORGET_PASSWORD_ERROR,
  POST_FORGET_PASSWORD_SUCCESS, POST_LOGIN, POST_LOGIN_ERROR, POST_LOGIN_SUCCESS,
  POST_REGISTER, POST_REGISTER_ERROR, POST_REGISTER_SUCCESS, SET_ROLE, SET_TOKEN,
  LOGOUT,
  VERIFY_ACCOUNT,
  VERIFY_ACCOUNT_SUCCESS,
  VERIFY_ACCOUNT_ERROR,
} from "../actions";


export const verifyAccountAction = (info) => ({
  type: VERIFY_ACCOUNT,
  payload: info,
});
export const verifyAccountSuccess = (info) => ({
  type: VERIFY_ACCOUNT_SUCCESS,
  payload: info,
});
export const verifyAccountError = (error) => ({
  type: VERIFY_ACCOUNT_ERROR,
  payload: error,
});

export const postLoginAction = (info) => ({
  type: POST_LOGIN,
  payload: info,
});
export const postLoginSuccess = (info) => ({
  type: POST_LOGIN_SUCCESS,
  payload: info,
});
export const postLoginError = (error) => ({
  type: POST_LOGIN_ERROR,
  payload: error,
});
export const postRegisterAction = (info) => ({
  type: POST_REGISTER,
  payload: info,
});
export const postRegisterSuccess = (info) => ({
  type: POST_REGISTER_SUCCESS,
  payload: info,
});
export const postRegisterError = (error) => ({
  type: POST_REGISTER_ERROR,
  payload: error,
});
export const postForgetPasswordAction = (info) => ({
  type: POST_FORGET_PASSWORD,
  payload: info,
});
export const postForgetPasswordSuccess = (info) => ({
  type: POST_FORGET_PASSWORD_SUCCESS,
  payload: info,
});
export const postForgetPasswordError = (error) => ({
  type: POST_FORGET_PASSWORD_ERROR,
  payload: error,
});
export const patchResetPasswordAction = (info) => ({
  type: PATCH_RESET_PASSWORD,
  payload: info,
});
export const patchResetPasswordSuccess = (info) => ({
  type: PATCH_RESET_PASSWORD_SUCCESS,
  payload: info,
});
export const patchResetPasswordError = (error) => ({
  type: PATCH_RESET_PASSWORD_ERROR,
  payload: error,
});
export const getLocationAction = (info) => ({
  type: GET_LOCATION,
  payload: info,
});
export const getLocationSuccess = (info) => ({
  type: GET_LOCATION_SUCCESS,
  payload: info,
});
export const getLocationError = (error) => ({
  type: GET_LOCATION_ERROR,
  payload: error,
});

export const setToken = (data) => ({
  type: SET_TOKEN,
  payload: data,
});
export const getToken = (data) => ({
  type: GET_TOKEN,
  payload: data,
});
export const clearToken = (data) => ({
  type: CLEAR_TOKEN,
  payload: data,
});
export const setRole = (data) => ({
  type: SET_ROLE,
  payload: data,
});
export const getRole = (data) => ({
  type: GET_ROLE,
  payload: data,
});
export const clearRole = (data) => ({
  type: CLEAR_ROLE,
  payload: data,
});

export const logout = (data) => ({
  type: LOGOUT,
  payload: data,
});