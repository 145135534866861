import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getClientReviewAction,
  postReviewAction,
} from "../../../../redux/client/action";
import * as yup from "yup";
import toast from "react-hot-toast";
import { Backdrop, CircularProgress, Rating } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { IoCalendarOutline } from "react-icons/io5";
import DatePicker from "../../../formikComponent/datepicker";

function AddReview() {
  const { guard_id, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { postReview } = useSelector((state) => state.ClientSection);
  const { postReviewLoading } = useSelector((state) => state.ClientSection);
  const [initial, setInitial] = useState(true);

  useEffect(() => {
    dispatch(getClientReviewAction());
  }, []);

  useEffect(() => {
    if (postReview?.success && initial === true) {
      notify(postReview.message);
      setInitial(false);
      navigate("/client/reviews");
    } else if (postReview?.success === false && initial === true) {
      notifyfail(postReview?.message);
      setInitial(false);
    }
  }, [postReview]);
  const initialValues = {
    guard_id: guard_id,
    job_id: id,
    rating: 0,
    title: "",
    description: "",
    work_completed_at: null,
  };
  const validationSchema = yup.object().shape({
    title: yup.string("").required("This field is required"),
    rating: yup.number("").required("Rating is required"),
    description: yup.string("").required(""),
    // work_completed_date: null,
  });

  const onSubmit = (values) => {
    // const payload = {
    //   guard_id: params[0],
    //   job_id: params[1],
    //   rating: values.rating,
    //   description: values.description,
    //   work_completed_date: values.work_completed_date,
    // };
    dispatch(postReviewAction(values));
    setInitial(true);
  };
  const notify = (value) => {
    toast.success(value);
  };
  const notifyfail = (value) => {
    toast.error(value);
  };
  return (
    <>
      <div className="bg-[#F1F2F6] w-full p-10 min-h-screen">
        <div className=" w-full">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ values, setFieldValue, errors }) => (
              <Form className="grid grid-cols-5 gap-10">
                <div className=" bg-white flex flex-col gap-7 py-9 px-10 rounded-2xl  md:col-span-1 col-span-5 h-fit">
                  <div className="mx-auto text-[#366CFE] text-5xl">
                    {values.rating > 0 ? values.rating : "00"}
                  </div>

                  <center>
                    <Rating
                      precision={0.5}
                      value={values.rating}
                      onChange={(event, newValue) =>
                        setFieldValue("rating", newValue)
                      }
                      size="large"
                    />
                    <div className="text-sm text-red-600">
                      <ErrorMessage name="rating" />
                    </div>
                  </center>
                  <center className="text-base font-semibold">
                    Select reviews
                  </center>
                </div>
                <div className="w-full h-fit bg-white  rounded-2xl  md:col-span-4 col-span-5">
                  <div className="border-b border-b-[#D8D8D8] w-full">
                    <div className="text-black text-s font-small font-['Inter']  border-b-4 border-[#145EEE] md:mx-12 mx-4 py-5 w-fit">
                      Reviews Details
                    </div>
                  </div>
                  <div className="md:px-16 px-4 py-8 grid grid-cols-2 gap-12">
                    <div className="w-full flex flex-col gap-2.5 md:col-span-1 col-span-2">
                      <label className="text-xs text-[#999999]">Title</label>
                      <Field
                        name="title"
                        className="border-b border-b-[#D8D8D8] w-full outline-none py-2"
                        placeholder="Title"
                      />
                      <div className="text-sm text-red-600">
                        <ErrorMessage name="title" />
                      </div>
                    </div>
                    <div className="w-full flex flex-col gap-2.5 md:col-span-1 col-span-2">
                      <label className="text-xs text-[#999999]">
                        Date work completed
                      </label>
                      <div className="relative w-full">
                        <label className=" absolute left-3 top-2 z-20">
                          <IoCalendarOutline className="text-xl text-[#366CFE]" />
                        </label>
                        <div className=" z-10 right-0 w-full">
                          <DatePicker
                            placeholder="MM / DD / YYYY"
                            name="work_completed_at"
                            classname="w-full h-5 py-5 px-10 border-b-[#D8D8D8] border-b outline-none"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-full flex flex-col gap-2.5 col-span-2">
                      <label className="text-xs text-[#999999]">
                        Description
                      </label>
                      <Field
                        as="textarea"
                        name="description"
                        className="border border-[#D8D8D8] w-full outline-none py-2 rounded-lg p-2.5"
                        placeholder="Add your massage"
                        cols="10"
                        rows="5"
                      />

                      <div className="text-sm text-red-600">
                        <ErrorMessage name="#999999" />
                      </div>
                    </div>
                    <div className="md:col-start-2 md:col-span-1 col-span-2 ml-auto md:w-fit w-full">
                      <button
                        type="submit"
                        className="bg-[#366CFE] px-4 py-2.5 text-white text-sm font-semibold rounded-md md:w-fit w-full"
                      >
                        Submit review
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {postReviewLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={postReviewLoading}
          // onClick={handleClose}
        >
          <CircularProgress style={{ color: "#083558" }} />
        </Backdrop>
      ) : null}
    </>
  );
}

export default AddReview;
