import React, { useEffect, useState } from "react";
import ProfileDetailsForm from "./detailsForm";
import ProfessionalDetailsForm from "./professionalDetailsForm";
import WorkingAreaForm from "./workingAreaForm";
import GuardDocumentForm from "./guardDocumentForm";
import toast from "react-hot-toast";
import PasswordChangeForm from "./changePassword";

function TabProfile(props) {
  const [value, setValue] = useState(0);
  const [confirmIdValid, setConfirmIdValid] = useState(true);

  useEffect(() => {
    if (!confirmIdValid) {
      toast.error("You need to confirm that provided documents are valid");
    }
  });

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleConfirm = (value) => {
    setConfirmIdValid(value);
    props.setIsChecked(value);
  };

  return (
    <>
      <div className="flex flex-wrap gap-2 items-center justify-around border-b-[2px] border-[#F4F4F4]  ">
        {tabsData &&
          tabsData.map((tab, index) => (
            <button
              type="button"
              onClick={() => handleChange(index)}
              className={` pb-2 ${
                value === index
                  ? "border-b-[2px] border-[#305BF1] text-black"
                  : "text-[#999999]"
              } md:text-sm text-[10px] md:leading-[30px] p `}
              key={index}
            >
              {tab.label}
            </button>
          ))}
      </div>
      {value === 0 && (
        <ProfileDetailsForm
          data={props.data}
          errors={props.errors}
          touched={props.touched}
        />
      )}
      {value === 1 && <ProfessionalDetailsForm values={props.values} />}
      {value === 2 && (
        <WorkingAreaForm
          setFieldValue={props.setFieldValue}
          values={props.values}
        />
      )}
      {value === 3 && (
        <GuardDocumentForm
          setFieldValue={props.setFieldValue}
          values={props.values}
          data={props.data}
          confirmIdValid={confirmIdValid}
          setConfirmIdValid={(data) => {
            handleConfirm(data);
          }}
        />
      )}
      {value === 4 && (
        <PasswordChangeForm errors={props.errors} touched={props.touched} />
      )}{" "}
    </>
  );
}

export default TabProfile;

const tabsData = [
  { label: "Personal Details", id: 0 },
  { label: "Professional details", id: 1 },
  { label: "Working area", id: 2 },
  { label: "Documents", id: 3 },
  { label: "Change Password", id: 4 },
];
