import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTradeMembersAction,
  patchTradeMembersAction,
} from "../../../../redux/admin/action";
import toast from "react-hot-toast";
import { Box, MenuItem, Modal, Select, Typography } from "@mui/material";
import person from "../../../../assets/images/person.png";
import moment from "moment";

function Top({ currMember }) {
  const [account, setAccount] = useState(currMember?.status ?? "active");
  const [openModal, setOpenModal] = useState(false);
  const [statusStyles, setStatusStyles] = useState({
    height: "40px",
    width: "100%",
    borderRadius: "9999px",
    color: "#067647",
    backgroundColor: "#ECFDF3",
    border: "1px solid #ABEFC6",
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "20%",
    borderRadius: "20px",
    bgcolor: "white",
    boxShadow: 24,
    p: 4,
  };

  const dispatch = useDispatch();
  const guard_id = currMember?.guard_id;

  const { patchTradeMembers, patchTradeMembersLoading } = useSelector(
    (state) => state.Adminsection
  );
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (account === "active") {
      setStatusStyles({
        height: "40px",
        minWidth: "150px",
        borderRadius: "9999px",
        color: "#067647",
        backgroundColor: "#ECFDF3",
        border: "1px solid #ABEFC6",
      });
    } else if (account === "unverified") {
      setStatusStyles({
        height: "40px",
        minWidth: "150px",
        borderRadius: "9999px",
        color: "#6F6F6F",
        backgroundColor: "#6F6F6F1A",
        border: "1px solid #6F6F6F",
      });
    } else if (account === "suspended") {
      setStatusStyles({
        height: "40px",
        minWidth: "150px",
        borderRadius: "9999px",
        color: "#F2A50F",
        backgroundColor: "#F2A50F1A",
        border: "1px solid #F2A50F",
      });
    } else if (account === "closed") {
      setStatusStyles({
        height: "40px",
        minWidth: "150px",
        borderRadius: "9999px",
        color: "#D92D20",
        backgroundColor: "#D92D201A",
        border: "1px solid #D92D20",
      });
    }
  }, [account]);

  useEffect(() => {
    if (patchTradeMembers.success && isSubmitted) {
      toast.success(patchTradeMembers.message);
      setOpenModal(false);
      dispatch(
        getTradeMembersAction({
          action: "status",
          value: "all",
          limit: 8,
          skip: 1,
          keywords: "",
        })
      );
      setIsSubmitted(false);
    } else if (patchTradeMembers.success === false) {
      toast.error(patchTradeMembers.message);
      setOpenModal(false);
      setIsSubmitted(false);
    }
  }, [patchTradeMembers, isSubmitted]);

  const callDispatch = () => {
    console.log("callDispatch");

    dispatch(
      patchTradeMembersAction({
        guard_id,
        data: {
          status: account,
        },
      })
    );
    setIsSubmitted(true);
  };

  const handleSelect = (value) => {
    setAccount(value);
    setOpenModal(true);
  };

  const statusTrn = {
    active: {
      button: "Activate",
      title: "Activate account",
      description: `Are you sure you want to update this user's Account status to ${account} ?`,
      bg: "bg-[#3F88251A]",
      color: "#3F8825",
      bt: "bg-[#3F8825] text-white",
    },
    unverified: {
      button: "Unverify",
      title: "Un-verify account",
      description: `Are you sure you want to update this user's Account status to ${account} ?`,
      bg: "bg-[#6F6F6F1A]",
      color: "#6F6F6F",
      bt: "bg-[#6F6F6F1A] text-white",
    },
    suspended: {
      button: "Suspend",
      title: "Suspend account",
      description: `Are you sure you want to update this user's Account status to ${account} ?`,
      bg: "bg-[#F2A50F1A]",
      color: "#F2A50F",
      bt: "bg-[#F2A50F] text-white",
    },
    closed: {
      button: "Close",
      title: "Close account",
      description: `Are you sure you want to update this user's Account status to ${account} ?`,
      bg: "bg-[#FEE4E2]",
      color: "#D92D20",
      bt: "bg-[#D92D20] text-white",
    },
  };

  const infoIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 8V12M12 16H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke={statusTrn[account]?.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  return (
    <div className="grid grid-cols-3 mt-12 mb-6 items-center">
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth="xs"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <div
            className={`${statusTrn[account]?.bg} h-[40px] w-[40px] rounded-full flex justify-center items-center mb-4`}
          >
            {infoIcon}
          </div>
          <Typography
            // id="v-title"
            variant="h6"
            component="h2"
            className="font-black"
          >
            {statusTrn[account]?.title}
          </Typography>
          <Typography sx={{ my: 2 }}>
            {statusTrn[account]?.description}
          </Typography>
          <div className="flex justify-between">
            <button
              onClick={() => setOpenModal(false)}
              className="bg-[#fff] w-[120px] px-4 py-3 rounded-[10px] text-sm  border "
            >
              Close
            </button>
            <button
              onClick={() => callDispatch()}
              className={`${statusTrn[account]?.bt} rounded-[10px]  w-[120px]`}
            >
              {patchTradeMembersLoading
                ? "loading..."
                : statusTrn[account]?.button}
            </button>
          </div>
        </Box>
      </Modal>
      <div className="flex col-span-2 w-full items-center gap-2">
        <img
          width={500}
          height={500}
          src={currMember?.profile_image ? currMember?.profile_image : person}
          alt=""
          className="w-14 h-14 rounded-full object-cover"
        />

        <div>
          <h2 className="md:text-lg text-sm text-black font-semibold">
            {currMember?.guard_first_name} {currMember?.guard_last_name}
          </h2>
          <p className="font-normal text-[#366CFE] md:text-xs text-[10px]">
            {currMember?.trading_name}
          </p>
          <p className="text-gray-400 md:text-xs text-[10px]">
            {moment(currMember?.guard_created_on).format("DD MMM, YYYY")}
          </p>
          <p className="flex items-center space-x-1">
            <svg
              width="9"
              height="9"
              viewBox="0 0 9 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.5 0C2.84583 0 1.49999 1.34583 1.49999 3.00001C1.49999 3.49659 1.62415 3.98895 1.86017 4.42566L4.33594 8.90332C4.3689 8.96302 4.43171 9 4.5 9C4.56829 9 4.6311 8.96302 4.66405 8.90332L7.14074 4.42419C7.37585 3.98895 7.5 3.49657 7.5 2.99999C7.5 1.34583 6.15417 0 4.5 0ZM4.5 4.5C3.67291 4.5 3 3.82709 3 3.00001C3 2.17292 3.67291 1.50001 4.5 1.50001C5.32708 1.50001 5.99999 2.17292 5.99999 3.00001C5.99999 3.82709 5.32708 4.5 4.5 4.5Z"
                fill="black"
              />
            </svg>
            <span className="text-[#141522] text-[8px] font-medium">
              {currMember?.location_address}
            </span>{" "}
          </p>
        </div>
      </div>

      <div className="flex items-center gap-3">
        <div>
          <div className="text-[8px] font-medium mb-4">ACCOUNT STATUS</div>

          <Select
            sx={statusStyles}
            value={account}
            className="md:text-base text-xs"
            onChange={(e) => {
              handleSelect(e.target.value);
            }}
          >
            <MenuItem value={"unverified"}>Un-verify</MenuItem>
            <MenuItem value={"active"}>Active</MenuItem>
            <MenuItem value={"suspended"}>Suspend</MenuItem>
            <MenuItem value={"closed"}>Close Account</MenuItem>
          </Select>
        </div>
      </div>
    </div>
  );
}

export default Top;
