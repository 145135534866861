"use client";
export const setCookie = (name, value) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(name, value);
  }
};

export const getCookie = (name) => {
  if (typeof window !== "undefined") return localStorage.getItem(name) || null;
};

export const deleteCookie = (name) => {
  if (typeof window !== "undefined") localStorage.removeItem(name);
};
