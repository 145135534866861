import { IconButton, InputAdornment, TextField } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import * as yup from "yup";
import { patchResetPasswordAction } from "../../../redux/auth/action";
import toast from "react-hot-toast";

function ResetPassword() {
  const { email } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const { patchResetPassword, patchResetPasswordLoading } = useSelector(
    (state) => state.Authsection
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [initial, setInitial] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleTogglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  useEffect(() => {
    console.log(patchResetPassword, "patchResetPassword");
    if (patchResetPassword?.status === true && initial === true) {
      notify("Password Change successfully. Please login in new password");
      navigate("/sign-in");
      setInitial(false);
    } else if (patchResetPassword?.success === false && initial === true) {
      notify(patchResetPassword?.error);
      setInitial(false);
    }
  }, [patchResetPassword]);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required("This field is required")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirmPassword: yup
      .string()
      .required("This field is required")
      .oneOf(
        [yup.ref("password")],
        "New Password and Confirm Password must be same"
      ),
  });
  const onSubmit = (values) => {
    const payload = {
      data: { password: values.confirmPassword },
      token: searchParams.get("token"),
    };
    dispatch(patchResetPasswordAction(payload));
    setInitial(true);
  };
  const notify = (value) => {
    toast.success(value);
  };
  const notifyfail = (value) => {
    toast.error(value);
  };
  return (
    <div className="h-screen w-full flex flex-col justify-center items-center">
      {" "}
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, errors, touched, setTouched }) => (
          <Form>
            <p className=" text-center  font-normal mt-[100px] text-[25px] md:text-[30px] lg:text-[40px] leading-[45px]  text-[#29273E]">
              Set new Password
            </p>
            <div className="mt-10 w-full md:w-[411px] flex-col gap-8 flex">
              <Field
                name="password"
                type={showPassword ? "text" : "password"}
                className={`h-14 border focus:border-[#305CF1] focus:outline-none ${
                  errors.password && touched.password
                    ? "border-red-500"
                    : "border-[#C4C4C4]"
                } rounded px-4  `}
                placeholder="Password"
              />
              <div className="text-xs text-red-500">
                <ErrorMessage name="password" />
              </div>
              <Field
                name="confirmPassword"
                type={showPassword2 ? "text" : "password"}
                className={`h-14 border focus:border-[#305CF1] focus:outline-none ${
                  errors.confirmPassword && touched.confirmPassword
                    ? "border-red-500"
                    : "border-[#C4C4C4]"
                } rounded px-4  `}
                placeholder="Confirm Password"
              />
              <div className="text-xs text-red-500">
                {touched.confirmPassword && (
                  <ErrorMessage name="confirmPassword" />
                )}
              </div>
            </div>
            <button
              type="submit"
              className="w-full md:w-[411px] h-[50px] md:h-[61px] flex  justify-center text-md md:text-md text-white items-center p-3 mt-[40px] rounded-md bg-[#305CF1] hover:border-[2px] border-[#305CF1] hover:bg-white hover:text-[#305CF1] duration-300"
            >
              Reset Password
            </button>
            <Link to="/sign-in">
              <p className=" text-left mt-[40px] text-[18px]  text-[#B9B8C0] leading-[27px]  font-medium ">
                Back to? <span className="text-[#305CF1]">Sign in </span>
              </p>
            </Link>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ResetPassword;
