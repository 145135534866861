import React, { useState } from "react";
import moment from "moment";
import image from "../../assets/images/info.svg";
import { Box, Modal } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import toast from "react-hot-toast";

const JobListCard = ({ item, viewJob, jobDetails, getFindTradersLoading }) => {
  const [open, setOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [emailInput, setEmailInput] = useState("");
  const [emails, setEmails] = useState([]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
    marginHorizontal: 10,
  };

  const handleInvite = (item) => {
    setOpen(true);
    setSelectedJob(item);
  };

  const handleEmailInputChange = (e) => {
    setEmailInput(e.target.value);
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const newEmail = e.target.value.trim();
      if (newEmail && !emails.includes(newEmail)) {
        if (!validateEmail(newEmail)) {
          toast.error("Invalid email ID");
        } else {
          setEmails([...emails, newEmail]);
          setEmailInput("");
        }
      } else {
        toast.error("Email already added");
      }
    }
  };

  const removeEmail = (emailToRemove) => {
    setEmails(emails.filter((email) => email !== emailToRemove));
  };

  const handleOpen = (item) => {
    setOpen(true);
    setSelectedJob(item);
  };

  const handleClose = () => {
    setOpen(false);
    setEmails([]);
    setEmailInput("");
  };

  const handleInviteForJob = (job) => {
    console.log(emails, job);
  };

  const tagsStyle =
    "bg-[#F6F5F5] text-[#5B5B5B] rounded-[10px] px-[30px] py-[5px] mr-4 lg:text-base text-xs font-medium";

  return (
    <div className="p-5 min-h-[180px] h-[220px] max-lg:h-full  rounded-[5px] poppins shadow-md hover:bg-[#145EEE05] border border-[#F1F5FE]">
      <div className="flex flex-row gap-4 justify-between">
        <div className="flex-row">
          <p className="lg:text-[20px] text-sm font-bold  leading-[24px] text-black mr-2">
            {item.title}
          </p>
          <div className="flex flex-row items-center">
            <p className="md:text-[14px] text-xs font-medium	">{item.address}</p>
          </div>
        </div>
        <div>
          <p className=" text-[12px] leading-[18px] text-[#565656] justify-end flex lg:pr-4 text-right ">
            Posted: {moment(item?.meta?.created_on).fromNow()}
          </p>
        </div>
      </div>
      <div className="my-4 flex flex-wrap gap-4  lg:text-base text-xs">
        <span className={tagsStyle}>£{item?.budget ?? 0} x hr</span>
        {item?.startDateTime[0]?.date && (
          <span className={tagsStyle}>
            {moment(item?.startDateTime[0]?.date ?? "").format("DD/MM/YYYY")}
          </span>
        )}

        {item?.startDateTime?.length > 0 ? (
          <span className={tagsStyle}>{item?.startDateTime?.length} days</span>
        ) : null}
        <span className={tagsStyle}>AM - PM</span>
      </div>
      <div className="flex lg:flex-row flex-col justify-between">
        <div className="md:text-[14px] text-xs     px-2 text-left  leading-[21px] text-[#242424] mt-[20px] md:w-[70%] w-[99%]">
          <p className="line-clamp-2">{item?.description}</p>
          <span
            onClick={() => viewJob(item)}
            className="text-[#145EEE] cursor-pointer md:text-[14px] text-xs "
          >
            Read More
          </span>
        </div>
        <div className="flex flex-row pr-12 max-lg:pr-4 items-center mt-3">
          {/* <button
            onClick={() => handleInvite(item)}
            className={`bg-primary w-[134px] h-[36px] rounded-[10px] md:text-sm text-xs text-white justify-center flex items-center mr-3 `}
          >
            {"Invite Co-workers"}
          </button> */}
          <button
            onClick={() => jobDetails(item)}
            className={`bg-primary w-[104px] h-[36px] rounded-[10px] md:text-sm text-xs text-white justify-center flex items-center mr-3 `}
          >
            {getFindTradersLoading ? "Finding..." : "Find traders"}
          </button>
          <div
            onClick={() => viewJob(item)}
            className="flex justify-center cursor-pointer items-center rounded-[10px] h-[36px] w-[36px] bg-[#E4E2E0] mr-3 md:text-sm text-xs"
          >
            <img
              width={500}
              height={500}
              src={image}
              className="w-4 h-4"
              alt="img1"
            />
          </div>

          {/* <div className="flex cursor-pointer justify-center items-center rounded-[10px] h-[36px] w-[36px] bg-[#FF4C00] mr-3">
            <Image
              width={500}
              height={500}
              src="/jobs/reject-white.svg"
              className="w-4 h-4"
              alt="img1"
            />
          </div> */}
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex justify-between items-center">
            <h3 className="md:text-xl text-xs font-bold text-black">
              {selectedJob?.title}
            </h3>
            <button onClick={handleClose}>
              <IoMdClose size={20} color="black" />
            </button>
          </div>
          <div className="my-5">
            <input
              type="email"
              value={emailInput}
              onChange={handleEmailInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Enter email and press enter"
              className="fieldCss border p-2 rounded"
            />

            <div className="mt-4">
              <div className="flex gap-5 items-center my-2">
                {emails.map((email, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center p-2 rounded-[5px] w-max gap-5 bg-[#0070de] text-white"
                  >
                    <span>{email}</span>
                    <button onClick={() => removeEmail(email)}>
                      <IoMdClose size={20} color="white" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {emails.length > 0 && (
            <button
              className="bg-[#0070de] text-white p-2 rounded-[5px]"
              onClick={() => handleInviteForJob(selectedJob)}
            >
              Send Invite
            </button>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default JobListCard;
