import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/auth/action";


const Initializers = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const dispatch = useDispatch();
  const router = useNavigate();
  const handleLogout = () => {
    dispatch(logout());
    setTimeout(() => {
      router("/sign-in");
    }, 1000);
  };

  const { fetch: originalFetch } = window; // Store original function

  window.fetch = async (...args) => {
    let [resource, config] = args;
    // Your interception logic here (e.g., logging requests, modifying headers)
    const response = await originalFetch(resource, config);
    if (response?.status === 401) {
      handleLogout();
    }

    return response;
  };
};

export default Initializers;
