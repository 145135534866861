const licenseTypes = [
  {
    name: "Front line",
    value: "front_line",
    sectors: [
      {
        name: "Cash and valuables in transit",
        trades: [
          "Armored Car Guard",
          "Cash Transport Guard",
          "Valuable Goods Transporter",
        ],
      },
      {
        name: "Close protection",
        trades: [
          "Bodyguard",
          "Executive Protection",
          "Personal Security",
          "VIP Protection",
          "Apartment Building Security",
          "Church Security",
          "Construction Site Watch",
          "Hospital Security",
          "Mall Security",
          "Office Building Security",
          "School Security",
          "Shop Security",
          "Warehouse Watch",
          "Club Bouncer",
          "Concert Security",
          "Event Door Staff",
          "Festival Security Staff",
          "Pub Security",
        ],
      },
      {
        name: "Event/Door security",
        trades: [
          "Club Bouncer",
          "Concert Security",
          "Event Door Staff",
          "Festival Security Staff",
          "Pub Security",
        ],
      },
      {
        name: "Key holding",
        trades: ["Alarm Response", "Key holder"],
      },
      {
        name: "Premises protection",
        trades: [
          "Apartment Building Security",
          "Church Security",
          "Construction Site Watch",
          "Hospital Security",
          "Mall Security",
          "Office Building Security",
          "School Security",
          "Shop Security",
          "Warehouse Watch",
        ],
      },
      {
        name: "Public space surveillance",
        trades: [
          "CCTV operator",
          "Monitoring center operator",
          "Surveillance Officer",
        ],
      },
      {
        name: "Vehicle immobilization",
        trades: [
          "Parking Enforcement",
          "Vehicle Clamper",
          "Vehicle immobilizer",
        ],
      },
    ],
  },
  {
    name: "Non-front line",
    value: "non_front_line",
    sectors: [
      {
        name: "/",
        trades: [
          "Director of Security",
          "Key Holding Services Manager",
          "Partner in Security Firm",
          "Security Administrator",
          "Security Consultant",
          "Security Manager",
          "Security Operations Manager",
          "Security Staffing Coordinator",
          "Security Supervisor",
        ],
      },
    ],
  },
];

export default licenseTypes;
