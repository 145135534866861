import React from "react";
import { BsShieldX } from "react-icons/bs";
import { HiOutlineBriefcase } from "react-icons/hi";
import { LiaHandshake, LiaTrophySolid } from "react-icons/lia";
import { PiHandWavingLight, PiListChecks } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

function GuardDashboardMyJobs({ data }) {
  const navigate = useNavigate();
  return (
    <div className=" bg-white p-6 md:col-span-2 col-span-3 rounded-2xl">
      <h2>My Jobs</h2>
      <div className="grid lg:grid-cols-2  sm:grid-cols-2 grid-cols-1 gap-4 poppins">
        <button onClick={() => navigate("/guard/job-search?tab=applied")}>
          <div className="border-[#E3EDFF] md:gap-6 gap-2  border flex border-solid rounded-lg  bg-[#366CFE]  items-center  md:px-6 px-3 md:py-4 py-1 ">
            <div className="p-2 bg-white rounded-full flex">
              <HiOutlineBriefcase className="text-[#366CFE] m-auto md:text-2xl text-lg" />
            </div>
            <div>
              <div className="text-white font-semibold md:text-base text-sm ">
                {data?.applied ?? 0}
              </div>
              <div className="text-white whitespace-nowrap md:text-xs text-[10px]">
                Total Applied
              </div>
            </div>
          </div>
        </button>
        <button onClick={() => navigate("/guard/job-search?tab=hire_request")}>
          <div className="border-[#E3EDFF] gap-6  border flex border-solid rounded-lg  bg-white  items-center md:px-6 px-3 md:py-4 py-1 ">
            <div className="p-2 bg-[#E1E9FF] rounded-full flex">
              <PiHandWavingLight className="text-[#366CFE] m-auto md:text-2xl text-lg" />
            </div>
            <div>
              <div className="text-black font-semibold text-base ">
                {data?.hireRequest ?? 0}
              </div>
              <div className="text-[#818186] md:whitespace-nowrap text-xs">
                Hire Request
              </div>
            </div>
          </div>
        </button>
        <button onClick={() => navigate("/guard/job-search?tab=shortlisted")}>
          <div className="border-[#E3EDFF] gap-6  border flex border-solid rounded-lg  bg-white  items-center md:px-6 px-3 md:py-4 py-1 ">
            <div className="p-2 bg-[#E1E9FF] rounded-full flex">
              <PiListChecks className="text-[#366CFE] m-auto md:text-2xl text-lg" />
            </div>
            <div>
              <div className="text-black font-semibold text-base ">
                {data?.shortlisted ?? 0}
              </div>
              <div className="text-[#818186] whitespace-nowrap text-xs">
                Shortlisted
              </div>
            </div>
          </div>
        </button>
        <button onClick={() => navigate("/guard/job-search?tab=hired")}>
          <div className="border-[#E3EDFF] gap-6  border flex border-solid rounded-lg  bg-white  items-center md:px-6 px-3 md:py-4 py-1 ">
            <div className="p-2 bg-[#E1E9FF] rounded-full flex">
              <LiaHandshake className="text-[#366CFE] m-auto md:text-2xl text-lg" />
            </div>
            <div>
              <div className="text-black font-semibold text-base ">
                {data?.hired ?? 0}
              </div>
              <div className="text-[#818186] whitespace-nowrap text-xs">
                Hired
              </div>
            </div>
          </div>
        </button>
        <button onClick={() => navigate("/guard/job-search?tab=declined")}>
          <div className="border-[#E3EDFF] gap-6  border flex border-solid rounded-lg  bg-white  items-center md:px-6 px-3 md:py-4 py-1 ">
            <div className="p-2 bg-[#E1E9FF] rounded-full flex">
              <BsShieldX className="text-[#366CFE] m-auto md:text-2xl text-lg" />
            </div>
            <div>
              <div className="text-black font-semibold text-base ">
                {data?.declined ?? 0}
              </div>
              <div className="text-[#818186] whitespace-nowrap text-xs">
                Declined
              </div>
            </div>
          </div>
        </button>
        <button onClick={() => navigate("/guard/reviews")}>
          <div className="border-[#E3EDFF] gap-6  border flex border-solid rounded-lg  bg-white  items-center md:px-6 px-3 md:py-4 py-1 ">
            <div className="p-2 bg-[#E1E9FF] rounded-full flex">
              <LiaTrophySolid className="text-[#366CFE] m-auto md:text-2xl text-lg" />
            </div>
            <div>
              <div className="text-black font-semibold text-base ">
                {data?.reviews ?? 0}
              </div>
              <div className="text-[#818186] whitespace-nowrap text-xs">
                Review
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
}

export default GuardDashboardMyJobs;
