import React, { useEffect, useState } from "react";
import work from "../../assets/images/client/work.png";
import document from "../../assets/images/client/document.png";
import bookmark from "../../assets/images/client/bookmark.png";
import folderopen from "../../assets/images/client/folderopen.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getClientDashboardAction,
  getClientDashboardChartAction,
  getClientPostedJobsAction,
} from "../../redux/client/action";
import formatNumber from "../../utils/formatNumbers";
import Center from "./dashboard/Center";
import { useNavigate } from "react-router-dom";

const ClientDashboard = () => {
  const navigate = useNavigate();
  const [selectedIs, setSelectedIs] = useState("Posted Job");
  const [dashboardData, setDashboardData] = useState({});
  const [chartData, setChartData] = useState({ allUsers: [], jobChart: [] });

  const dispatch = useDispatch();
  const {
    getDashboardData,
    getDashboardLoading,
    getDashboardChartLoading,
    getDashboardChartData,
  } = useSelector((state) => state.ClientSection);

  useEffect(() => {
    dispatch(getClientDashboardAction(""));
    dispatch(getClientDashboardChartAction(""));
    dispatch(getClientPostedJobsAction());
  }, [dispatch]);

  useEffect(() => {
    if (getDashboardData?.success) {
      setDashboardData(getDashboardData?.data);
    }
  }, [getDashboardData.success, getDashboardData.data]);

  useEffect(() => {
    if (getDashboardChartData?.success) {
      setChartData(getDashboardChartData.data);
    }
  }, [getDashboardChartData.data, getDashboardChartData?.success]);

  return (
    <>
      <div className="md:px-14 px-7 md:py-12 py-6">
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 ">
          <button onClick={() => navigate("/client/my-jobs")}>
            <div
              className={`${
                selectedIs === "Posted Job"
                  ? "bg-[#366CFE]"
                  : "bg-white border border-[#E3EDFF]"
              } rounded-xl px-6 py-4 flex gap-5 cursor-pointer`}
              onClick={() => setSelectedIs("Posted Job")}
            >
              <div
                className={`${
                  selectedIs === "Posted Job" ? "bg-white" : "bg-[#E1E9FF]"
                }  p-3 rounded-full`}
              >
                <img alt="" src={work} height={20} width={20} />
              </div>

              <div
                className={`${selectedIs === "Posted Job" ? "text-white" : ""}`}
              >
                <div className=" font-semibold ">
                  {getDashboardLoading
                    ? "..."
                    : formatNumber(dashboardData?.total_jobs ?? 0)}
                </div>
                <div className=" text-xs  ">Posted Job</div>
              </div>
            </div>
          </button>
          <button onClick={() => navigate("/client/my-jobs?status=open")}>
            <div
              className={`${
                selectedIs === "Open"
                  ? "bg-[#366CFE]"
                  : "bg-white border border-[#E3EDFF]"
              }  rounded-xl  px-6 py-4 flex gap-5 cursor-pointer`}
              onClick={() => setSelectedIs("Open")}
            >
              <div
                className={`${
                  selectedIs === "Open" ? "bg-white" : "bg-[#E1E9FF]"
                }  p-3 rounded-full`}
              >
                <img alt="" src={folderopen} height={20} width={20} />
              </div>

              <div className={`${selectedIs === "Open" ? "text-white" : ""}`}>
                <div className=" font-semibold ">
                  {getDashboardLoading
                    ? "..."
                    : formatNumber(dashboardData?.open_jobs ?? 0)}
                </div>
                <div className="text-xs  ">Open</div>
              </div>
            </div>
          </button>
          <button onClick={() => navigate("/client/my-jobs")}>
            <div
              className={`${
                selectedIs === "Application"
                  ? "bg-[#366CFE]"
                  : "bg-white border border-[#E3EDFF]"
              }  rounded-xl px-6 py-4 flex gap-5 cursor-pointer`}
              onClick={() => setSelectedIs("Application")}
            >
              <div
                className={`${
                  selectedIs === "Application" ? "bg-white" : "bg-[#E1E9FF]"
                }  p-3 rounded-full`}
              >
                <img alt="" src={document} height={20} width={20} />
              </div>

              <div
                className={`${
                  selectedIs === "Application" ? "text-white" : ""
                }`}
              >
                <div className=" font-semibold ">
                  {getDashboardLoading
                    ? "..."
                    : formatNumber(
                        Number(dashboardData?.total_applications ?? 0)
                      )}
                </div>
                <div className="text-xs  ">Application</div>
              </div>
            </div>
          </button>
          <button onClick={() => navigate("/client/my-jobs?status=suspended")}>
            <div
              className={`${
                selectedIs === "Save Candidate"
                  ? "bg-[#366CFE]"
                  : "bg-white border border-[#E3EDFF]"
              }  rounded-xl  px-6 py-4 flex gap-5 cursor-pointer`}
              onClick={() => setSelectedIs("Save Candidate")}
            >
              <div
                className={`${
                  selectedIs === "Save Candidate" ? "bg-white" : "bg-[#E1E9FF]"
                }  p-3 rounded-full`}
              >
                <img alt="" src={bookmark} height={20} width={20} />
              </div>

              <div
                className={`${
                  selectedIs === "Save Candidate" ? "text-white" : ""
                }`}
              >
                <div className=" font-semibold ">
                  {getDashboardLoading
                    ? "..."
                    : formatNumber(Number(dashboardData?.suspended_jobs ?? 0))}
                </div>
                <div className="text-xs  ">Suspended Jobs</div>
              </div>
            </div>
          </button>
        </div>
      </div>
      <div className="w-100 py-6  mx-6 flex flex-wrap justify-between">
        <Center loading={getDashboardChartLoading} data={chartData} />
      </div>
    </>
  );
};

export default ClientDashboard;
