import React from "react";
import { ErrorMessage, Field } from "formik";

function PasswordChangeForm({ errors, touched }) {
  return (
    <div className="flex flex-col gap-4 w-full py-[30px] h-full poppins">
      <div className="flex flex-col md:col-span-1 col-span-2">
        <label htmlFor="old-password" className="text-[#999999] text-xs pl-1">
          Old Password
        </label>
        <Field
          type="password"
          id="old-password"
          name="old_password"
          className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
        />
        <div className="text-sm text-red-500">
          <ErrorMessage name="old_password" />
        </div>
      </div>

      <div className="flex flex-col md:col-span-1 col-span-2">
        <label htmlFor="new-password" className="text-[#999999] text-xs pl-1">
          New Password
        </label>
        <Field
          type="password"
          name="new_password"
          className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
        />
        <div className="text-sm text-red-500">
          <ErrorMessage name="new_password" />
        </div>
      </div>

      <div className="flex flex-col md:col-span-1 col-span-2">
        <label
          htmlFor="confirm-password"
          className="text-[#999999] text-xs pl-1"
        >
          Confirm New Password
        </label>
        <Field
          type="password"
          id="confirm-password"
          name="confirm_password"
          className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
        />
        <div className="text-sm text-red-500">
          <ErrorMessage name="confirm_password" />
        </div>
      </div>
    </div>
  );
}

export default PasswordChangeForm;
