import React from "react";
import JobStats from "../../../clientSection/dashboard/jobStats";
import UsersChart from "./UsersChart";
const Center = ({ data, loading }) => {
  // console.log(data?.allUsers, "user stats");
  return (
    <div className="w-full py-6 grid grid-cols-6 md:gap-8 gap-12 ">
      <JobStats jobChart={data?.jobChart} loading={loading} />
      <UsersChart allUsers={data?.allUsers} loading={loading} />
    </div>
  );
};

export default Center;
