import React from "react";
import { SlShield } from "react-icons/sl";
import { GoShieldCheck } from "react-icons/go";
import { FaRegStar } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { RiUserForbidLine } from "react-icons/ri";


const Status = ({ status }) => {
  let style,
    circle = "",
    icon,
    text,
    center;

  switch (status?.toLowerCase()) {
    case "unverified":
      style = "text-[#ED452E]";
      circle = "bg-[#ed452e36]";
      icon = <SlShield />;
      text = "Unverified";
      break;

    case "verified":
      style = "text-[#3F8825]";
      circle = "bg-[#F1FCED]";
      icon = <GoShieldCheck />;
      text = "Verified";
      break;

    case "suspended":
      style = "text-[#F2A50F]";
      circle = "bg-[#F2A50F26]";
      icon = <RiUserForbidLine />;
      text = "Suspended";
      break;
      
      case "recommended":
        style = "text-[#800080]";
        circle = "bg-[#80008026]";
        icon = <FaRegStar />;
      text = "Recommended";
      break;

    case "add-trades":
      style = "text-[#145DEB]";
      circle = "bg-[#145DEB26]";
      icon = <IoMdAdd />;
      text = "Add trades";
      center = "ml-[8px]";
      break;
  }

  return (
    <div>
      <div
        className={`rounded-full ${circle} h-[30px] w-[30px] flex justify-center items-center ${center} ${style} text-base`}
      >
        {icon}
      </div>
      <p className={`${style} text-[10px]`}>{text}</p>
    </div>
  );
};

export default Status;
