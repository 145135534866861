import { Field } from "formik";
import React, { useState } from "react";

const SelectBudget = () => {

  return (
    <div className="max-w-5xl mx-auto py-20">
      <h2 className="text-center font-semibold text-2xl">
        What is the hourly budget?
      </h2>

      <div className="flex w-max mx-auto mt-10 items-end gap-3">
        {/* <div>
          <p className="font-medium text-sm pl-3 mb-1">Minimum</p>
          <div className="w-max rounded-2xl py-1 px-2 pl-5 border flex items-center justify-between border-primary">
            <Field
              onKeyDown={(e) => {
                if (e.key === "e" || e.key === "+" || e.key === "-") {
                  e.preventDefault();
                }
              }}
              
              name="minBudget"
              step={0.01}
              type="number"
              className="focus:outline-none placeholder:text-sm border-none"
              placeholder="Enter min amount"
            />

            <div className="w-10 text-primary p-2 text-center h-10 rounded-full bg-[#366CFE17]">
              £
            </div>
          </div>
        </div> */}

        {/* <p className="text-center font-medium w-10 h-10">to</p> */}
        <div>
          {/* <p className="font-medium text-sm pl-3 mb-1">Maximum</p> */}
          <div
            className={
              "w-max rounded-2xl py-1 px-2 pl-5 border flex items-center justify-between border-primary"
            }
          >
            <Field
              onKeyDown={(e) => {
                if (e.key === "e" || e.key === "+" || e.key === "-") {
                  e.preventDefault();
                }
              }}
              name="maxBudget"
              // step={0.01}
              min="11"
              type="number"
              className="focus:outline-none placeholder:text-sm  border-none"
              placeholder="Enter max amount"
            />

            <div className="w-10 text-primary p-2 text-center h-10 rounded-full bg-[#366CFE17]">
              £
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectBudget;
