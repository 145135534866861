import { logout, getRole, getToken } from "../../redux/auth/action";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import User from "../../assets/images/User.png";
import logoblue from "../../assets/images/iguardublue.svg";
import logo from "../../assets/images/iguarduLogo.svg";
import { HiMenuAlt3 } from "react-icons/hi";
import { Drawer } from "@mui/material";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FaPowerOff } from "react-icons/fa6";
import clientdashboard from "../../assets/images/sidebar/dashboard.svg";
import clientJobs from "../../assets/images/sidebar/jobs.svg";
import clientProfile from "../../assets/images/sidebar/profile.svg";
import staricon from "../../assets/images/sidebar/StarIcon.svg";
import trades from "../../assets/images/sidebar/trades.svg";
import license from "../../assets/images/sidebar/license.svg";
import reviews from "../../assets/images/sidebar/reviews.svg";
import category from "../../assets/images/sidebar/Category.svg";
import UserTrade from "../../assets/images/sidebar/3-User-trade.svg";
import work from "../../assets/images/sidebar/Work.svg";

const Layout = ({ children }) => {
  const { role } = useSelector((state) => state.Authsection);
  const { name } = useSelector((state) => state.ProfileSection);
  const { getProfile } = useSelector((state) => state.ProfileSection);
  const [data, setData] = useState([]);

  const dispatch = useDispatch();
  const router = useNavigate();

  const [rolevalue, setRoleValue] = useState([]);
  const [sideBar, setSideBar] = useState(false);

  useEffect(() => {
    if (getProfile?.success) {
      console.log(getProfile?.data);
      setData(getProfile?.data);
    }
  }, [getProfile]);

  useEffect(() => {
    dispatch(getToken());
    dispatch(getRole());
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(getProfileAction())
  // }, [dispatch])

  useEffect(() => {
    setRoleValue(menu[role]);
  }, [role]);

  const handleLogout = () => {
    dispatch(logout());
    router("/sign-in");
  };

  return (
    <div className="h-screen">
      <header className="w-full">
        <div className="flex flex-row px-10 py-4 ">
          <Link to="/">
            <img
              width={130}
              height={100}
              src={logoblue}
              alt="logo"
              className=" object-contain  "
            />
          </Link>
          <div className=" ml-auto items-center justify-center gap-2 lg:gap-5 flex">
            <div className="lg:block hidden">
              <p className=" text-[12px] lg:text-[14px] text-black">
                {data?.first_name
                  ? data?.first_name + " " + data?.last_name
                  : name}
              </p>
              <p className=" text-[10px] lg:text-[13px] text-gray-500">
                {moment(new Date()).format("DD MMMM YYYY")}
              </p>
            </div>
            <div className="lg:block hidden">
              <img
                width={500}
                height={500}
                alt="profile image"
                src={
                  data?.profile_image
                    ? data?.profile_image
                    : "/profile-image.webp"
                }
                className="w-[46px] h-[46px] rounded-full"
              />
              <p className="text-[10px] text-center">{role}</p>
            </div>
            <div
              className="flex lg:hidden"
              onClick={() => setSideBar(!sideBar)}
            >
              <HiMenuAlt3 className="text-[#145EEE] text-2xl" />
            </div>
          </div>
        </div>
      </header>
      <Drawer
        anchor={"right"}
        open={sideBar}
        sx={{
          width: "70%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "70%",
            boxSizing: "border-box",
          },
        }}
        onClose={() => setSideBar(false)}
        className="w-64"
      >
        <section className=" bg-primary h-screen flex flex-col ">
          <Link to="/">
            <img
              width={1000}
              height={1000}
              src={logo}
              alt="logo"
              className="w-32 object-contain mx-auto my-10"
            />
          </Link>
          {rolevalue.length > 0 &&
            rolevalue.map((v, i) => (
              <NavLink
                key={v.name}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "bg-[#4585FF]" : ""
                }
                to={v.href}
                onClick={() => setSideBar(!sideBar)}
              >
                {({ isActive, isPending, isTransitioning }) => (
                  <div
                    className={
                      isPending
                        ? "pending"
                        : isActive
                        ? "flex gap-2 text-white w-10/12 mx-auto rounded-lg hover:bg-[#4585FF] my-6 p-3 px-5 bg-[#4585FF] opacity-100"
                        : "flex gap-2 text-white w-10/12 mx-auto rounded-lg hover:bg-[#4585FF] my-6 p-3 px-5 opacity-75"
                    }
                  >
                    <img
                      width={1000}
                      height={1000}
                      src={v.img}
                      alt="logo"
                      className="w-6 h-6 object-contain mr-5"
                    />{" "}
                    {v.name}
                  </div>
                )}
              </NavLink>
            ))}
          <div
            className=" flex gap-2 text-white  w-full rounded-lg hover:bg-[#4585FF] mt-auto mb-[10%] p-3 px-14"
            onClick={() => handleLogout()}
          >
            <FaPowerOff />

            <p>Log out</p>
          </div>
        </section>
      </Drawer>
      <div className="flex bg-[#f4f5f7] h-full">
        <section className="w-64 bg-primary h-screen fixed top-0 lg:flex hidden justify-between flex-col ">
          <div>
            <Link to={"/"}>
              <img
                width={1000}
                height={1000}
                src={logo}
                alt="logo"
                className="w-32 object-contain mx-auto my-10"
              />
            </Link>
            {rolevalue.length > 0 &&
              rolevalue.map((v, i) => (
                <NavLink
                  key={v.name}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "bg-[#4585FF]" : ""
                  }
                  to={v.href}
                >
                  {({ isActive, isPending, isTransitioning }) => (
                    <div
                      className={
                        isPending
                          ? "pending"
                          : isActive
                          ? "flex gap-2 text-white w-10/12 mx-auto rounded-lg hover:bg-[#4585FF] my-6 p-3 px-5 bg-[#4585FF] opacity-100"
                          : "flex gap-2 text-white w-10/12 mx-auto rounded-lg hover:bg-[#4585FF] my-6 p-3 px-5 opacity-75"
                      }
                      style={{}}
                    >
                      <img
                        width={1000}
                        height={1000}
                        src={v.img}
                        alt="logo"
                        className="w-6 h-6 object-contain mr-5"
                      />{" "}
                      {v.name}
                    </div>
                  )}
                </NavLink>
              ))}
          </div>
          <button
            className=" flex gap-2 text-white w-10/12 mx-auto rounded-lg hover:bg-[#4585FF] my-6 p-3 px-5 "
            onClick={() => handleLogout()}
          >
            <FaPowerOff className="my-auto mr-5" />
            <p>Log out</p>
          </button>
        </section>
        <div className="lg:ml-64 w-full h-full">{children}</div>
      </div>
    </div>
  );
};

export default Layout;

const client = [
  {
    name: "Dashboard",
    img: clientdashboard,
    href: "/client/dashboard",
  },
  {
    name: "My job ",
    img: clientJobs,
    href: "/client/my-jobs",
  },
  {
    name: "My profile",
    img: clientProfile,
    href: "/client/my-profile",
  },
  // {
  //   name: "My transactions",
  //   img: clientProfile,
  //   href: "/client/my-transactions",
  // },
  {
    name: "My reviews",
    img: staricon,
    href: "/client/reviews",
  },
];

const guard = [
  {
    name: "Dashboard",
    img: clientdashboard,
    href: "/guard/dashboard",
  },

  {
    name: "Job search",
    img: clientJobs,
    href: "/guard/job-search",
  },

  {
    name: "My profile",
    img: clientProfile,
    href: "/guard/my-profile",
  },

  {
    name: "My trades",
    img: trades,
    href: "/guard/my-trades",
  },
  {
    name: "My license",
    img: license,
    href: "/guard/my-licenses",
  },

  {
    name: "Reviews",
    img: reviews,
    href: "/guard/reviews",
  },
];

const admin = [
  {
    name: "Dashboard",
    img: category,
    href: "/dashboard",
  },
  {
    name: "My profile",
    img: clientProfile,
    href: "/profile",
  },
  {
    name: "Trade Members",
    img: UserTrade,
    href: "/trade-members",
  },
  {
    name: "User",
    img: UserTrade,
    href: "/users",
  },
  {
    name: "Jobs",
    img: work,
    href: "/jobs",
  },
  {
    name: "Reviews",
    img: staricon,
    href: "/reviews",
  },
];

const menu = {
  client,
  guard,
  admin,
};
