import React, { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa6";

const Dropdown = ({ name, options, background }) => {
  const [isShowing, setIsShowing] = useState(false);
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button
        onMouseEnter={() => setIsShowing(true)}
        onMouseLeave={() => setIsShowing(false)}
        className={`${
          background ? "border-white bg-white" : "border-gray-300"
        } hover:border-primary hover:bg-primary hover:text-white border-2 gap-5 flex justify-between rounded-full px-4 py-2 font-medium text-sm" ${
          isShowing && "bg-primary text-white"
        }`}
      >
        {name} <FaAngleDown className="my-auto" />
      </Menu.Button>
      <Transition
        as={Fragment}
        show={isShowing}
        onMouseEnter={() => setIsShowing(true)}
        onMouseLeave={() => setIsShowing(false)}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
          {options?.map((item, index) => (
            <Menu.Item key={index} className="">
              {({ active }) => (
                <Link
                  className="flex items-center rounded-lg px-5 mx-2 my-2 py-2 cursor-pointer hover:text-primary hover:bg-[#E6EEFF] gap-5"
                  to={item.link}
                >
                  {item.icon}
                  <span className="whitespace-nowrap">{item.name}??</span>
                </Link>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Dropdown;
