import React from 'react'
import PostedJobsSection from './PostedJobsSection'
import JobStats from './jobStats'

const Center = ({ data, loading }) => {
    return (
        <div className="w-full py-6 grid grid-cols-5 md:gap-8 gap-12 ">
            <JobStats jobChart={data?.jobs} loading={loading} />
            <PostedJobsSection allUsers={data?.allUsers} loading={loading} />
        </div>
    )
}

export default Center