"use client"
import React, { useEffect, useState } from 'react'
import ChartComponent from '../../chartComponent/ChartComponent';

const JobStats = ({ jobChart, loading }) => {
    return (
        <div className="h-96 bg-white rounded-xl p-6 pb-32 md:col-span-3 col-span-5">
            <div className="flex w-full justify-between items-center">
                <h2 className="text-lg font-semibold text-black">
                    Job Stats
                </h2>
                {/* <div className="flex items-center text-sm gap-2">
                    <span className="text-gray-400">show</span>
                    <Select
                        variant='standard'
                        value={sortVal}
                        onChange={e => setSortVal(e.target.value)}
                    >
                        <MenuItem value="by month">
                            by month
                        </MenuItem>
                        <MenuItem value={"by year"}>by year</MenuItem>
                        <MenuItem value={"by week"}>by week</MenuItem>
                    </Select>
                </div> */}
            </div>

            {/* <div className="w-full text-sm flex my-4 items-center justify-between flex-wrap">
                <div className="flex items-center gap-3">
                    <span className="text-gray-400 md:text-base text-xs">
                        Total Jobs
                    </span>
                    <Switch defaultChecked />
                </div>
                <div className="flex items-center gap-3">
                    <span className="text-gray-400 md:text-base text-xs">
                        Application
                    </span>
                    <Switch defaultChecked />
                </div>
                <div className="flex items-center gap-3">
                    <span className="text-gray-400 md:text-base text-xs">
                        Total Hired
                    </span>
                    <Switch defaultChecked />
                </div>
            </div> */}

            {/* <ChartComponent /> */}
            <ChartComponent jobChart={jobChart} loading={loading}  />
        </div>
    )
}

export default JobStats