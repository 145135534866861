import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  getClientJobsAction,
  getClientJobsAnalysisAction,
  getClientJobsListAction,
  getFindTradersAction,
  patchClientJobStatusAction,
} from "../../../../redux/client/action";
import toast from "react-hot-toast";
import {
  Backdrop,
  Box,
  CircularProgress,
  Drawer,
  Modal,
  Typography,
} from "@mui/material";
import JobFilter from "../../../jobComponents/jobFilter";
import ListLoader from "../../../loader/listLoader";
import JobListCard from "./JobDetailsList";
import SingleJobPage from "../components/sideJobPage";

function ClientJobDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "white",
    boxShadow: 24,
    p: 4,
  };

  const {
    getFindTraders,
    getFindTradersLoading,
    getClientJobsList,
    getClientJobsListLoading,
  } = useSelector((state) => state.ClientSection);
  const { patchClientJobStatus } = useSelector((state) => state.ClientSection);
  const { patchClientJobStatusLoading } = useSelector(
    (state) => state.ClientSection
  );
  const { getClientJobsAnalysis } = useSelector((state) => state.ClientSection);

  useEffect(() => {
    dispatch(getClientJobsAnalysisAction(id));
    dispatch(getFindTradersAction(id));
  }, [dispatch, id]);

  const [initial, setInitial] = useState(true);
  const [data, setData] = useState([]);
  const [dataJobs, setDataJobs] = useState([]);
  const [activeTab, setActiveTab] = useState("people");
  const [currJob, setCurrJob] = useState(null);
  const [declinedValue, setdeclinedValue] = useState();
  const [showModal, setShowModal] = useState(false);
  const [analysis, setanalysis] = useState({});

  useEffect(() => {
    if (searchParams.get("tab") === null) {
      // tabHandler("people");
      // setActiveTab("people");
    } else if (initial === true && searchParams.get("tab") !== null) {
      tabHandler(searchParams.get("tab"));
      setActiveTab(searchParams.get("tab"));
    }
  }, [searchParams]);

  useEffect(() => {
    if (patchClientJobStatus?.success) {
      notify(patchClientJobStatus.message);
      if (showModal) {
        setShowModal(false);
      }
      if (activeTab?.toString() === "jobs") {
        dispatch(getClientJobsAction(""));
      } else {
        apiCallHandler(activeTab);
      }
      setCurrJob(null);
    } else if (patchClientJobStatus?.success === false) {
      notifyfail(patchClientJobStatus.message);
    }
  }, [patchClientJobStatus]);

  useEffect(() => {
    if (getFindTraders && getFindTraders?.success) {
      setData(getFindTraders.data);
      // notify(getFindTraders.message);
    }
  }, [getFindTraders]);

  useEffect(() => {
    if (getClientJobsList?.success) {
      console.log(getClientJobsList.data, "client jobs list");
      setDataJobs(getClientJobsList.data);
    }
  }, [getClientJobsList]);

  useEffect(() => {
    console.log("in client job analysis");
    if (getClientJobsAnalysis?.success === true) {
      setanalysis(getClientJobsAnalysis.data);
    }
  }, [getClientJobsAnalysis]);

  const buttons = [
    { id: 1, name: "Invite trades people", value: "people" },
    {
      id: 2,
      name: `Invited (${analysis?.requested ?? 0})`,
      value: "requested",
    },
    {
      id: 3,
      name: `Applied (${analysis?.applied ?? 0})`,
      value: "applied",
    },
    {
      id: 4,
      name: `Shortlisted (${analysis?.shortlisted ?? 0})`,
      value: "shortlisted",
    },
    {
      id: 5,
      name: `Hire Request (${analysis?.hire_request ?? 0})`,
      value: "hire_request",
    },
    {
      id: 6,
      name: `Hired (${analysis?.hired ?? 0})`,
      value: "hired",
    },
    {
      id: 7,
      name: `Refused (${analysis?.refused ?? 0})`,
      value: "refused",
    },
    {
      id: 8,
      name: `Declined (${analysis?.declined ?? 0})`,
      value: "declined",
    },
  ];
  const tabHandler = (value) => {
    setActiveTab(value);
    setInitial(false);
    if (value === undefined) {
    } else {
      // router.push(`/client/my-jobs/details/${id}?tab=${value}`);
      searchParams.set("tab", value);
      setSearchParams(searchParams);
    }
    if (value?.toString() !== "people") {
      apiCallHandler(value);
    }
  };

  const apiCallHandler = (value) => {
    const payload = {
      id: id,
      status: value,
    };
    dispatch(getClientJobsListAction(payload));
  };

  const declinedHandler = (value) => {
    if (activeTab === "people") {
      const payload = {
        data: {
          status: "declined",
          guard_id: value.guard_id,
        },
        id: value?.job_id,
      };
      setdeclinedValue(payload);
    } else {
      const payload = {
        data: {
          status: "declined",
          guard_id: value.guard_id,
        },
        id: value?.job_id,
      };
      setdeclinedValue(payload);
    }
    setShowModal(true);
    setanalysis({});
  };
  const confirmDeclinehandler = () => {
    dispatch(patchClientJobStatusAction(declinedValue));
    dispatch(getClientJobsAnalysisAction(id));
    dispatch(getFindTradersAction(id));
  };
  const closeModalHandler = () => {
    setShowModal(false);
    setdeclinedValue(null);
  };

  const tabSequence = [
    {
      tab: "applied",
      nextTab: "shortlisted",
    },
    // {
    //   tab: "requested",
    //   nextTab: "applied"
    // },
    {
      tab: "shortlisted",
      nextTab: "hire_request",
    },
    {
      tab: "hire_request",
      nextTab: "declined",
    },
    {
      tab: "declined",
      nextTab: "applied",
    },
  ];

  useEffect(() => {
    if (patchClientJobStatus.success) {
      const tab = tabSequence.find((t) => t.tab === activeTab);
      tabHandler(tab?.nextTab);
      apiCallHandler(tab?.nextTab);
    }
  }, [patchClientJobStatus.success]);

  const handler = (name, value) => {
    if (name === "people") {
      const payload = {
        data: {
          guard_id: value.guard_id,
          status: "requested",
          job_id: id,
        },
      };
      dispatch(patchClientJobStatusAction(payload));
      dispatch(getClientJobsAnalysisAction(id));
      dispatch(getFindTradersAction(id));
    }
    if (name === "applied") {
      const payload = {
        data: {
          status: "shortlisted",
          guard_id: value.guard_id,
        },
        id: value.id,
      };
      dispatch(patchClientJobStatusAction(payload));
      dispatch(getClientJobsAnalysisAction(id));
      dispatch(getFindTradersAction(id));
    }
    if (name === "shortlisted") {
      const payload = {
        data: {
          status: "hire_request",
          guard_id: value.guard_id,
        },
        id: value.id,
      };
      dispatch(patchClientJobStatusAction(payload));
      dispatch(getClientJobsAnalysisAction(id));
      dispatch(getFindTradersAction(id));
    }
    if (name === "hired") {
      navigate(`/client/reviews/add/${value.guard_id}/${id}`);
    }
    if (name === "declined") {
      const payload = {
        data: {
          status: "undo",
          guard_id: value.guard_id,
        },
        id: value.id,
      };
      dispatch(patchClientJobStatusAction(payload));
      dispatch(getClientJobsAnalysisAction(id));
      dispatch(getFindTradersAction(id));
    }
    setanalysis({});
  };

  const filterAction = (action, filter) => {
    dispatch(getClientJobsAction({ action, value: filter }));
  };

  const notify = (value) => {
    toast.success(value);
  };
  const notifyfail = (value) => {
    toast.error(value);
  };

  return (
    <div>
      <Modal
        open={showModal}
        onClose={() => closeModalHandler()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="font-black"
          >
            Confiramtion
          </Typography>
          <Typography id="modal-modal-description" sx={{ my: 2 }}>
            Are you sure you want to decline this job post
          </Typography>
          <div className="flex gap-4 justify-end">
            <button
              onClick={() => confirmDeclinehandler()}
              className="bg-[#18B234] px-4 py-3 rounded-[10px] text-sm text-white "
            >
              Yes I want to decline
            </button>
            <button
              onClick={() => closeModalHandler()}
              className=" rounded-[10px] px-4 py-3 text-white bg-[#FF4C00] text-sm"
            >
              No
            </button>
          </div>
        </Box>
      </Modal>

      <div className="bg-[#f4f5f7] w-full px-2 md:px-4 lg:px-4 pt-5 mt-10 poppins ">
        <div className="p-3 md:px-10">
          <Drawer
            anchor={"right"}
            open={currJob !== null}
            onClose={() => setCurrJob(null)}
            sx={{
              width: "70%",
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: "70%",
                boxSizing: "border-box",
              },
              "@media (max-width: 768px)": {
                width: "100%",
                "& .MuiDrawer-paper": {
                  width: "100%",
                },
              },
            }}
          >
            <SingleJobPage
              setCurrJob={setCurrJob}
              data={currJob}
              activeTab={activeTab}
              declinedHandler={declinedHandler}
              handler={handler}
            />
          </Drawer>

          <div className=" bg-[#EFF0F4] w-full px-2 md:px-4 lg:px-4 pt-5 poppins ">
            <div className="">
              <div className="w-full pb-4">
                <JobFilter disabled filterAction={filterAction} />
              </div>
            </div>
            <div className="pt-7 mb-3 md:px-4 flex flex-col gap-4 pb-2 bg-white rounded-[10px] poppins">
              <div className="flex flex-wrap gap-2 items-center justify-around border-b-[2px] border-[#F4F4F4]  ">
                {buttons?.map((item, i) => (
                  <button
                    onClick={() => tabHandler(item?.value)}
                    className={` pb-2 ${
                      activeTab === item?.value
                        ? "border-b-[2px] border-[#305BF1] text-black"
                        : "text-[#999999]"
                    } md:text-[16px] text-xs md:leading-[30px] p `}
                    key={i}
                  >
                    {item?.name}
                  </button>
                ))}
              </div>

              <div className="">
                <div className="pt-3 mb-3 px-4 flex  flex-col gap-4 pb-2 bg-white rounded-b-[10px]">
                  {activeTab === "people" ? (
                    getFindTradersLoading ? (
                      <div className="w-full flex justify-center">
                        <ListLoader />
                      </div>
                    ) : data?.length ? (
                      data?.map((item, i) => (
                        <JobListCard
                          key={i}
                          item={item}
                          viewJob={setCurrJob}
                          activeTab={activeTab}
                          declinedHandler={declinedHandler}
                          handler={handler}
                        />
                      ))
                    ) : (
                      <NothingHere />
                    )
                  ) : getClientJobsListLoading ? (
                    <div className="w-full flex justify-center">
                      {" "}
                      <ListLoader />{" "}
                    </div>
                  ) : dataJobs?.length ? (
                    dataJobs?.map((item, i) => (
                      <JobListCard
                        key={i}
                        item={item}
                        viewJob={setCurrJob}
                        activeTab={activeTab}
                        handler={handler}
                        declinedHandler={declinedHandler}
                      />
                    ))
                  ) : (
                    <NothingHere />
                  )}
                </div>
              </div>
              {/* <div className="flex items-end justify-end pb-4">
                <Pagination count={10} color="primary" />
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {patchClientJobStatusLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={patchClientJobStatusLoading}
          // onClick={handleClose}
        >
          <CircularProgress style={{ color: "#083558" }} />
        </Backdrop>
      ) : null}
    </div>
  );
}

export default ClientJobDetails;
const NothingHere = () => (
  <div className="text-[20px] font-bold  leading-[24px] text-[#AAAA] mr-2 text-center	">
    No data yet.
  </div>
);
