export default function formatNumber(n) {
  // Check for absolute value less than 1000
  
  if (Math.abs(n) < 1) {
    return 0;
  } else if (Math.abs(n) < 1000) {
    // Add leading zeros for single-digit numbers
    const formatted = n.toString().padStart(2, '0');
    // Add commas for thousands separators
    return formatted.toLocaleString();
  } else if (Math.abs(n) < 1e6) {
    // Divide by 1000, format to one decimal place, and add "k" suffix
    return (n / 1e3).toFixed(1) + 'k';
  } else {
    // Divide by 1 million, format to one decimal place, and add "M" suffix
    return (n / 1e6).toFixed(1) + 'M';
  }
}
