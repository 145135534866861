import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

export const options = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    stacked: false,
    plugins: {
        title: {
            display: false,
            text: 'Chart.js Line Chart - Multi Axis',
        },
    },
    pointStyle: 'circle',
    pointRadius: 10,
    pointHoverRadius: 15
};

function createChartData(dataSection) {
    const parsedData = {};
  
    dataSection?.forEach(entry => {
      const monthAbbr = moment(entry.month).format('M')
      parsedData[monthAbbr] = parsedData[monthAbbr] || {};
      parsedData[monthAbbr].guards = entry.guards;
      parsedData[monthAbbr].clients = entry.clients;
    });
  
    const labels =['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    const datasets = [
      {
        label: 'Guard',
        data: labels.map((month, i) => parsedData[i + 1]?.guards || 0),
        borderColor: 'rgba(153, 102, 255, 1)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        fill: true
      },
      {
        label: 'Client',
        data: labels.map((month, i) => parsedData[i + 1]?.clients || 0),
        borderColor: 'rgba(255, 159, 64, 1)',
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
        fill: true
      }
    ];
  
    return { labels, datasets };
  }

export default function ChartComponent({ allUsers, loading }) {
    const data = createChartData(allUsers)
    return (
    <div className="h-96 bg-white rounded-xl p-6 pb-32 md:col-span-3 col-span-5">
      <div className="flex w-full justify-between items-center">
        <h2 className="text-lg font-semibold text-black">
            Users Stats
        </h2>
      </div>
      <Line options={options} data={data} />
    </div>
  );
}
