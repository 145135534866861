import { Close } from "@mui/icons-material";
import React from "react";
import UserTop from "./UserTop";
import UserDetails from "./UserDetails";
import UserReviewSection from "./ReviewSection";
import UpdateUserPassword from "./updateUserPassword";

const SingleUserPage = ({ setCurrUser, currUser }) => {
  return (
    <div className="flex w-full h-screen">
      <div className="w-full overflow-y-auto">
        <div className="w-full min-h-screen bg-dull">
          <div className="bg-white rounded-xl w-full p-10">
            <div className="flex items-center justify-end w-full">
              <div
                onClick={() => setCurrUser(null)}
                className="w-12 h-12 rounded-full hover:bg-[#145DEB42] px-2 py-3 cursor-pointer font-semibold text-center bg-[#145DEB1A] text-primary"
              >
                <Close />
              </div>
            </div>
            <UserTop currUser={currUser} />
            <UserDetails currUser={currUser} />
            <UserReviewSection currUser={currUser} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleUserPage;
