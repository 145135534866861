import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verifyAccountAction } from "../../../redux/auth/action";

const VerifyLink = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { verifyAcc } = useSelector((state) => state.Authsection);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [rPath, setRPath] = useState("/sign-in");
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [secondsRemaining, setSecondsRemaining] = useState(5);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        navigate(rPath); // Redirect when timer reaches 0
        clearInterval(intervalId); // Stop the interval
      }
    }, 1000); // Update every second
  }, [navigate, rPath, secondsRemaining]);

  const token = searchParams.get("token");

  useEffect(() => {
    dispatch(verifyAccountAction({ token }));
  }, [dispatch, token]);

  useEffect(() => {
    if (verifyAcc?.success) {
      setSuccess(true);
    } else if (verifyAcc?.success === false) {
      setError(true);
      setRPath("/");
    }
  }, [verifyAcc]);

  return (
    <div className="h-screen w-full flex flex-col justify-center items-center">
      {success && <Success />}
      {error && <Error />}
    </div>
  );
};

const Success = ({ secondsRemaining }) => {
  return (
    <div>
      <p className="nekst text-center text-[green]  font-bold  text-[25px] md:text-[30px] lg:text-[20px] leading-[45px]  text-[#29273E]">
        Your account has been verified. Please login to continue!
      </p>
      Redirecting you to Login in {secondsRemaining} seconds...
    </div>
  );
};

const Error = ({ secondsRemaining }) => {
  return (
    <div>
      <p className="nekst text-center text-[red]  font-bold  text-[25px] md:text-[30px] lg:text-[20px] leading-[45px]  text-[#29273E]">
        You account could not be verified. Please contact support.?
      </p>
      Redirecting you in {secondsRemaining} seconds...
    </div>
  );
};

export default VerifyLink;
