import * as React from "react";
import Box from "@mui/material/Box";
import LeftArrow from "./LeftArrow";

const ProcessTracker = ({
  handleBack,
  handleNext,
  progress,
  errors,
  values,
  lastText,
  postJobTokenLoading,
}) => {
  return (
    <Box
      className="fixed left-0 bottom-0 items-center bg-white px-10 max-md:px-2 z-20"
      sx={{ width: "100%", py: "20px" }}
    >
      <div className={`flex w-full `} style={{ paddingLeft: `${progress}%` }}>
        <div>{parseFloat(progress).toFixed(0)}%</div>
      </div>
      <div className="shadow w-full bg-[#EAECF0] h-2 rounded-2xl">
        <div
          className="bg-blue  leading-none py-1 h-2 text-center text-white rounded-2xl bg-[#366CFE]"
          style={{ width: `${progress}%` }}
        ></div>
      </div>

      <React.Fragment>
        <Box className="w-full flex justify-end mt-10 gap-5">
          {parseFloat(progress).toFixed(0) > 0 && (
            <button
              type="button"
              className="normal-case group transition-all ease-in-out text-sm px-4 rounded-full text-secondary border-2 border-white disabled:cursor-not-allowed opacity-75 hover:opacity-100 flex items-center gap-2"
              color="inherit"
              onClick={handleBack}
            >
              <LeftArrow /> Back
            </button>
          )}
          {parseFloat(progress).toFixed(0) < 100 && (
            <button
              size="large"
              type="button"
              className="text-sm rounded-full font-medium p-3 px-10 max-md:px-2 normal-case text-white bg-primary border-2 border-transparent hover:border-[#155deeb4] hover:bg-[#155deeb4]"
              onClick={() => handleNext(values)}
            >
              Next
            </button>
          )}

          {parseFloat(progress).toFixed(0) > 99 && (
            <button
              size="large"
              type="submit"
              disabled={postJobTokenLoading}
              className="text-sm rounded-full font-medium p-3 px-10 max-md:px-2 normal-case text-white bg-primary border-2 border-transparent hover:border-[#155deeb4] hover:bg-[#155deeb4]"
              // onClick={handleNext}
            >
              {lastText}
            </button>
          )}
        </Box>
      </React.Fragment>
    </Box>
  );
};

export default ProcessTracker;
