/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getJobListAction,
  getJobsAction,
  getJobsAnalysisAction,
  patchJobStatusAction,
  postJobApplyAction,
} from "../../../redux/guard/action";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import {
  Backdrop,
  Box,
  CircularProgress,
  Drawer,
  Modal,
  Typography,
} from "@mui/material";
import ListLoader from "../../loader/listLoader";
import JobFilter from "../../jobComponents/jobFilter";
import JobList from "./jobList";
import JobDetails from "./jobDetails";

function GuardJobs() {
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const { getJobList } = useSelector((state) => state.GuardSection);
  const { getJobListLoading } = useSelector((state) => state.GuardSection);
  const { getJobs } = useSelector((state) => state.GuardSection);
  const { getJobsLoading } = useSelector((state) => state.GuardSection);
  const { getJobsAnalysis } = useSelector((state) => state.GuardSection);
  const { getJobsAnalysisLoading } = useSelector((state) => state.GuardSection);
  const { postJobApply } = useSelector((state) => state.GuardSection);
  const { postJobApplyLoading } = useSelector((state) => state.GuardSection);
  const { patchJobStatus } = useSelector((state) => state.GuardSection);
  const [activeTab, setActiveTab] = useState("jobs");
  const [currJob, setCurrJob] = useState(null);
  const [dataJobs, setDataJobs] = useState([]);
  const [filterHourly] = useState("");
  const [filterPostedJob] = useState("");
  const [data, setData] = useState([]);
  const [dataAnalysis, setDataAnalysis] = useState({
    jobsFound: 0,
    requested: 0,
    applied: 0,
    shortlisted: 0,
    hire_request: 0,
    hired: 0,
    declined: 0,
    lost: 0,
  });
  const [declinedValue, setdeclinedValue] = useState();
  const [initial, setInitial] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: "50%",
    bgcolor: "white",
    boxShadow: 24,
    borderRadius: "12px",
    p: 4,
  };

  const buttons = [
    {
      id: 1,
      name: `${dataAnalysis?.jobsFound ?? 0} Job(s) found`,
      value: "jobs",
    },
    {
      id: 2,
      name: `Requested (${dataAnalysis?.requested ?? 0})`,
      value: "requested",
    },
    {
      id: 3,
      name: `Applied (${dataAnalysis?.applied ?? 0})`,
      value: "applied",
    },
    {
      id: 4,
      name: `Shortlisted (${dataAnalysis?.shortlisted ?? 0})`,
      value: "shortlisted",
    },
    {
      id: 5,
      name: `Hire Request (${dataAnalysis?.hire_request ?? 0})`,
      value: "hire_request",
    },
    {
      id: 6,
      name: `Hired (${dataAnalysis?.hired ?? 0})`,
      value: "hired",
    },
    {
      id: 7,
      name: `Declined (${dataAnalysis?.declined ?? 0})`,
      value: "declined",
    },
    {
      id: 8,
      name: `Lost (${dataAnalysis?.lost ?? 0})`,
      value: "lost",
    },
  ];

  const tabSequence = [
    {
      tab: "jobs",
      nextTab: "applied",
    },
    {
      tab: "requested",
      nextTab: "applied",
    },
    {
      tab: "shortlisted",
      nextTab: "hire_request",
    },
    {
      tab: "hire_request",
      nextTab: "hired",
    },
    {
      tab: "declined",
      nextTab: "declined",
    },
  ];

  useEffect(() => {
    dispatch(getJobsAction(""));
    dispatch(getJobsAnalysisAction());
  }, [dispatch]);

  useEffect(() => {
    if (searchParams.get("tab") === null) {
      // tabHandler("jobs");
      // setActiveTab("jobs");
    } else if (initial === true && searchParams.get("tab") !== null) {
      tabHandler(searchParams.get("tab"));
      setActiveTab(searchParams.get("tab"));
    }
  }, [searchParams]);
  useEffect(() => {
    if (getJobsAnalysis?.success) {
      setDataAnalysis(getJobsAnalysis.data);
    }
  }, [getJobsAnalysis]);
  useEffect(() => {
    if (postJobApply.success) {
      dispatch(getJobsAnalysisAction());
    } else if (
      postJobApply.success === false ||
      postJobApply.message === "You have already applied for this job."
    ) {
      notifyfail(postJobApply.message);
    }
  }, [activeTab, dispatch, postJobApply]);

  useEffect(() => {
    if (postJobApply.success) {
      notify(postJobApply.message);
    }
  }, [postJobApply.message, postJobApply.success]);

  useEffect(() => {
    if (postJobApply.success) {
      const tab = tabSequence.find((t) => t.tab === activeTab);
      tabHandler(tab?.nextTab);
      apiCallHandler(tab?.nextTab);
    }
  }, [postJobApply.success]);

  useEffect(() => {
    if (patchJobStatus?.success) {
      dispatch(getJobsAnalysisAction());
      if (showModal) {
        setShowModal(false);
      }
      // if (activeTab?.toString() === "jobs") {
      //   dispatch(getJobsAction(""));
      // } else {
      //   apiCallHandler(activeTab);
      // }
      setCurrJob(null);
    } else if (patchJobStatus?.success === false) {
      notifyfail(patchJobStatus.message);
    }
  }, [activeTab, dispatch, patchJobStatus, showModal]);

  useEffect(() => {
    if (patchJobStatus?.success) {
      notify(patchJobStatus.message);
    }
  }, [patchJobStatus.message, patchJobStatus?.success]);

  useEffect(() => {
    if (patchJobStatus.success) {
      const tab = tabSequence.find((t) => t.tab === activeTab);
      tabHandler(tab?.nextTab);
      apiCallHandler(tab?.nextTab);
    }
  }, [patchJobStatus.success]);

  const tabHandler = (value) => {
    setActiveTab(value);
    setInitial(false);
    searchParams.set("tab", value);
    setSearchParams(searchParams);
    if (value?.toString() !== "jobs") {
      apiCallHandler(value);
    }
  };
  useEffect(() => {
    if (getJobs?.success) {
      setDataJobs(getJobs?.data);
    }
  }, [getJobs]);

  useEffect(() => {
    if (getJobList?.success) {
      setData(getJobList?.data);
    }
  }, [getJobList]);

  useEffect(() => {
    if (filterHourly !== "") {
      apiCallHandler(activeTab);
    }
  }, [activeTab, filterHourly]);

  useEffect(() => {
    if (filterPostedJob !== "") {
      apiCallHandler(activeTab);
    }
  }, [activeTab, filterPostedJob]);

  const apiCallHandler = (value) => {
    const payload = {
      status: value,
      hourlyRate: filterHourly,
      postedJob: filterPostedJob,
    };
    dispatch(getJobListAction(payload));
  };
  const handler = (name, value) => {
    if (name === "open") {
      const payload = {
        job_id: value.job_id,
      };
      dispatch(postJobApplyAction(payload));
    }
    if (name === "requested") {
      const payload = {
        data: {
          id: value.id,
        },
        status: "applied",
      };
      dispatch(patchJobStatusAction(payload));
    }
    if (name === "hire_request") {
      const payload = {
        data: {
          id: value.id,
        },
        status: "hired",
      };
      dispatch(patchJobStatusAction(payload));
    }
  };
  const declinedHandler = (value) => {
    const payload = {
      data: {
        id: value.id,
      },
      status: "declined",
    };
    setdeclinedValue(payload);
    setShowModal(true);
  };

  const confirmDeclinehandler = () => {
    dispatch(patchJobStatusAction(declinedValue));
    tabHandler("declined");
  };
  const closeModalHandler = () => {
    setShowModal(false);
    setdeclinedValue(null);
  };
  const notify = (value) => {
    toast.success(value);
  };
  const notifyfail = (value) => {
    toast.error(value);
  };

  const filterAction = (action, filter) => {
    dispatch(getJobsAction({ action, value: filter }));
  };

  return (
    <div>
      <Modal
        open={showModal}
        onClose={() => closeModalHandler()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="font-black"
          >
            Confiramtion
          </Typography>
          <Typography id="modal-modal-description" sx={{ my: 2 }}>
            Are you sure you want to decline this job post
          </Typography>
          <div className="flex gap-4 justify-end">
            <button
              onClick={() => confirmDeclinehandler()}
              className="bg-[#18B234] px-4 py-3 rounded-[10px] text-sm text-white "
            >
              Yes I want to decline
            </button>
            <button
              onClick={() => closeModalHandler()}
              className=" rounded-[10px] px-4 py-3 text-white bg-[#FF4C00] text-sm"
            >
              No
            </button>
          </div>
        </Box>
      </Modal>
      <div className="md:px-10 px-2 md:py-5 py-2 bg-[#F1F2F6] w-full flex gap-7">
        <Drawer
          anchor={"right"}
          open={currJob !== null}
          onClose={() => setCurrJob(null)}
          sx={{
            width: "70%",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: "70%",
              boxSizing: "border-box",
            },
            "@media (max-width: 768px)": {
              width: "70%",
              "& .MuiDrawer-paper": {
                width: "70%",
              },
            },
          }}
        >
          <JobDetails
            setCurrJob={setCurrJob}
            data={currJob}
            activeTab={activeTab}
            handler={handler}
            declinedHandler={declinedHandler}
          />
        </Drawer>

        <div className=" bg-[#EFF0F4] w-full px-2 md:px-4 lg:px-4 pt-5 poppins ">
          <div className="">
            <div className="w-full pb-4">
              <JobFilter filterAction={filterAction} />
            </div>
          </div>

          <div className="pt-7 mb-3 px-4 flex flex-col gap-4 pb-2 bg-white rounded-[10px] poppins">
            {/* button for switch */}

            <div className="flex flex-wrap gap-2 items-center justify-around border-b-[2px] border-[#F4F4F4]  ">
              {buttons?.map((item, i) => (
                <button
                  onClick={() => tabHandler(item?.value)}
                  className={` pb-2 ${
                    activeTab === item?.value
                      ? "border-b-[2px] border-[#305BF1] text-black"
                      : "text-[#999999]"
                  } md:text-[16px] text-xs md:leading-[30px] p `}
                  key={i}
                >
                  {item?.name}
                </button>
              ))}
            </div>

            {/* Result  */}

            <div className="">
              <div className="md:p-10 p-2 flex  flex-col gap-4 pb-2 bg-white rounded-b-[10px]">
                {activeTab?.toString() === "jobs" ? (
                  getJobsLoading ? (
                    <div className="w-full flex justify-center">
                      {" "}
                      <ListLoader />{" "}
                    </div>
                  ) : dataJobs?.length > 0 ? (
                    dataJobs?.map((item, i) => (
                      <JobList
                        key={i}
                        item={item}
                        viewJob={setCurrJob}
                        activeTab={activeTab}
                        handler={handler}
                        declinedHandler={declinedHandler}
                      />
                    ))
                  ) : (
                    <NothingHere />
                  )
                ) : getJobListLoading ? (
                  <div className="w-full flex justify-center">
                    {" "}
                    <ListLoader />{" "}
                  </div>
                ) : data?.length > 0 ? (
                  data?.map((item, i) => (
                    <JobList
                      key={i}
                      item={item}
                      viewJob={setCurrJob}
                      activeTab={activeTab}
                      handler={handler}
                      declinedHandler={declinedHandler}
                    />
                  ))
                ) : (
                  <NothingHere />
                )}
              </div>
            </div>
            {/* <div className="flex items-end justify-end pb-4">
            <Pagination count={10} color="primary" />
          </div> */}
          </div>
        </div>
      </div>

      {getJobsAnalysisLoading || postJobApplyLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={getJobsAnalysisLoading || postJobApplyLoading}
          // onClick={handleClose}
        >
          <CircularProgress style={{ color: "#083558" }} />
        </Backdrop>
      ) : null}
    </div>
  );
}

export default GuardJobs;

const NothingHere = () => (
  <div className="text-[20px] font-bold  leading-[24px] text-[#AAAA] mr-2 text-center	">
    Nothing here yet.
  </div>
);
