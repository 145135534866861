import React from "react";
import MapWithCircle from "../../../../pages/auth/trader-signup/components/MapWithCircle";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import GetLocationProfile from "./getLocationProfile";

function WorkingAreaForm({ setFieldValue, values }) {
  const [selectedDistance, setSelectedDistance] = React.useState(
    values.location.max_distance
  );
  const [address, setSelectedAddress] = React.useState(values.location.address);

  const handleChange = (event) => {
    setSelectedDistance(event.target.value);
    setFieldValue("location.max_distance", event.target.value);
  };

  const setAddrVal = (val) => {
    setSelectedAddress(val);
    // setFieldValue("location.name", val.place_name);
    // setFieldValue("location.coordinates", [val.latitude, val.longitude]);
  };

  return (
    <div className="w-full flex flex-col justify-start items-start poppins h-full">
      <div className="py-5 pb-[30px] w-full">
        <GetLocationProfile
          setAddress={setAddrVal}
          setFieldValue={setFieldValue}
          values={values}
        />
      </div>
      <div className="flex md:flex-row flex-col w-full min-h-[280px]">
        <div>
          <FormControl
            variant="standard"
            sx={{ m: 1, minWidth: "100px", border: "0px", outline: "0px" }}
          >
            <InputLabel
              id="select-distance-2"
              sx={{
                fontSize: "18px",
                color: "black",
                border: "0px",
                outline: "none",
              }}
            >
              Select Distance
            </InputLabel>
            <Select
              labelId="select-distance-2"
              id="dselect-distance-2"
              value={selectedDistance}
              onChange={handleChange}
              sx={{
                minWidth: "150px",
                height: "40px",
                border: "0px",
                outline: "0px",
                borderBottom: "1px solid #D8D8D8 !important", // Add !important to ensure precedence
                marginTop: "10px",
                borderColor: "!white",
                "& fieldset": {
                  border: "0px !important",
                  outline: "0px !important",
                },
              }}
            >
              {distanceValues.map((data) => {
                return (
                  <MenuItem
                    key={`distance + ${data.distanceValues}`}
                    value={data.distanceValues}
                  >
                    {data.distanceLable}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className=" md:mx-[10px] w-full h-80 my-[30px] md:ml-7">
          {
            <MapWithCircle
              address={address}
              circleRadius={parseInt(selectedDistance)}
              mapBorderRadius="28px"
              blackBorder="1px solid black"
              mapContainerClass="h-[250px] relative "
            />
          }
        </div>
      </div>
    </div>
  );
}

export default WorkingAreaForm;

const distanceValues = [
  {
    distanceLable: "5 Miles",
    distanceValues: "5",
  },
  {
    distanceLable: "10 Miles",
    distanceValues: "10",
  },
  {
    distanceLable: "20 Miles",
    distanceValues: "20",
  },
  {
    distanceLable: "30 Miles",
    distanceValues: "30",
  },
  {
    distanceLable: "40 Miles",
    distanceValues: "40",
  },
  {
    distanceLable: "50 Miles",
    distanceValues: "50",
  },
  {
    distanceLable: "60 Miles",
    distanceValues: "60",
  },
  {
    distanceLable: "70 Miles",
    distanceValues: "70",
  },
];
