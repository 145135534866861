import pdfIcon from "../../../../assets/images/pdfIcon.svg";
import { useEffect, useState } from "react";
import { Image, Upload } from "cloudinary-react";
import axios from "axios";
import toast from "react-hot-toast";
import moment from "moment";
import { formatFileSize } from "../../../../utils/formatFilleSize";

const UploadDOcumentCard = ({
  selectedSide,
  selectedDocument,
  onSuccess,
  src,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [image, setimage] = useState(null);
  const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME; // replace with your own cloud name
  const uploadPreset = process.env.REACT_APP_CLOUDINARY_PRESET_NAME; // replace with your own upload preset

  const maxFileSize = 3 * 1024 * 1024; // 3MB in bytes

  const uploadImage = (files) => {
    const selected = files[0];
    setSelectedFile(selected);
    if (selected && selected.size <= maxFileSize) {
      const formdata = new FormData();
      formdata.append("file", selected);
      formdata.append("upload_preset", uploadPreset);
      axios
        .post(`https://api.cloudinary.com/v1_1/${cloudName}/upload`, formdata)
        .then((res) => {
          console.log(res, "doc upload");
          setimage(res.data.secure_url);
          onSuccess(res?.data);
        })
        .catch((err) => console.log(err));
    } else {
      // Handle invalid file type or size
      notifyfail("File size must be less than 3 mb");
    }
  };
  useEffect(() => {
    if (src) {
      setimage(src);
    }
  }, [src]);

  const notify = (value) => {
    toast.success(value);
  };
  const notifyfail = (value) => {
    toast.error(value);
  };

  return (
    <div className="w-full poppins">
      <div className="border-dashed border-2 border-[#D8D8D8] h-[350px] max-w-[678px] my-8 flex flex-col items-center justify-center">
        {image ? (
          <div className="w-60 h-16 bg-[#EAF0FF] flex justify-evenly items-center rounded	">
            <div>
              <img src={pdfIcon} height={15} width={15} alt="pdf-icon" />
            </div>
            <div>
              <div className="text-sm">
                {selectedFile?.name ? selectedFile?.name : "File"}
              </div>
              <div className="flex">
                <div className="text-[8px] text-[#366CFE] py-1 pr-4">
                  {selectedFile?.lastModified
                    ? moment(selectedFile?.lastModified).format("DD/MM/YYYY")
                    : moment(new Date()).format("DD/MM/YYYY")}
                </div>
                <div className="text-[8px] text-[#999999] py-1 ">
                  {selectedFile?.size
                    ? formatFileSize(selectedFile?.size)
                    : "3mb"}
                </div>
              </div>
            </div>
            {/* <div onClick={deleteSelectedFile}>
                <Image
                  src={redDeleteIcon}
                  height={15}
                  width={15}
                  alt="delete icon"
                ></Image>
              </div> */}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center">
            {/* <img
              src={imageIcon}
              width={83}
              height={83}
              alt="upload-documents"
            /> */}
            <div className="py-8 px-1 text-[#999999] max-w-[254px] text-[10px] text-center">
              Please upload a copy of your valid identification in PNG, JPEG or
              PDF format, no larger than 3mb in size {selectedSide}
            </div>

            <input
              type="file"
              id="fileInput"
              onChange={(e) => uploadImage(e.currentTarget.files)}
              className="hidden"
            />
            <label
              htmlFor="fileInput"
              className="cursor-pointer bg-primary w-[135px] h-9 rounded-full text-white justify-center flex items-center"
            >
              Upload
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadDOcumentCard;
