import { all, fork, call, takeEvery, put, select } from "redux-saga/effects";
import { GET_PROFILE, POST_LOGIN, POST_REGISTER, POST_TRADER_REGISTER } from "../actions";
import { getProfileError, getProfileSuccess, postRegisterError, postRegisterSuccess, postTraderRegisterError, postTraderRegisterSuccess } from "./action";

const ApiLinks = process.env.REACT_APP_BASE_URL;

// Fetch user data API
function* getProfileCall(paylaod) {
  try {
    
    const getApiRes = yield call(getProfileAsync, paylaod);
    
    yield put(getProfileSuccess(getApiRes));
  } catch (error) {
    yield put(getProfileError());
  }
}

const getProfileAsync = (payload) =>
  fetch(`${ApiLinks}/auth/me`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${payload.payload}`, // Add the token to the headers
    }
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchGetProfileData() {
  yield takeEvery(GET_PROFILE, getProfileCall);
}
// post trader register
function* postTraderRegisterCall(paylaod) {
  try {
    const { token } = yield select((state) => state.Authsection);
    
    const getApiRes = yield call(postTraderRegisterAsync, paylaod, token);

    yield put(postTraderRegisterSuccess(getApiRes));
  } catch (error) {
    yield put(postTraderRegisterError());
  }
}

const postTraderRegisterAsync = (payload, token) =>
  fetch(`${ApiLinks}/guard/register`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`, // Add the token to the headers
    },
    body: JSON.stringify(payload.payload)
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchpostTraderRegisterData() {
  yield takeEvery(POST_TRADER_REGISTER, postTraderRegisterCall);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetProfileData),
    fork(watchpostTraderRegisterData),
  ]);
}