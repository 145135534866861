import cloud from "../../../../assets/images/cloud.svg";
import pdf from "../../../../assets/images/pdfIcon.svg";
import jpeg from "../../../../assets/images/jpegIcon.svg";
import React from "react";
import { BsEye } from "react-icons/bs";
import { Link } from "react-router-dom";
import moment from "moment";

const dummyFiles = [
  {
    type: "pdf",
    filename: "lic01.pdf",
    size: "2.5",
  },
  {
    type: "jpeg",
    filename: "lic01.jpeg",
    size: "1.2",
  },
];

const getFileExtension = (filename) => {
  return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
};

const LicenseDocuments = ({ currMember }) => {
  const bytesToMB = (bytes) => {
    const MB = 1024 * 1024;
    let mbValue = bytes / MB;
    return mbValue.toFixed(2);
  };

  return (
    <div className="border-b py-10 w-full">
      <h4 className="font-semibold mb-6 underline">Documents</h4>
      <div className="flex gap-5 flex-wrap items-center w-full">
        {/** File Card */}
        {currMember?.documents?.map((file, i) => (
          <div
            key={i}
            className="flex items-center gap-2 p-4 rounded-md bg-[#EAF0FF]"
          >
            <img
              src={getFileExtension(file.url) === "pdf" ? pdf : jpeg}
              width={500}
              height={500}
              alt=""
              className="w-10 h-10 object-contain"
            />
            <div>
              <p className="text-sm font-semibold">{file.filename}</p>
              <p className="text-xs">
                <span className="text-gray-400 mr-5">
                  {moment(file?.date).format("DD-MMM-YYYY")}
                </span>
                <span className="text-primary">
                  {file?.size ? bytesToMB(file?.size) : "0"} MB
                </span>
              </p>
            </div>

            <Link to={file.url} target="_blank">
              <img
                src={cloud}
                width={500}
                height={500}
                alt=""
                className="w-5 h-5 object-contain ml-10"
              />
            </Link>
            <Link to={file.url} target="_blank">
              <BsEye />
            </Link>
          </div>
        ))}
        {/** File Card ends */}
      </div>
    </div>
  );
};

export default LicenseDocuments;
