
import { deleteCookie, getCookie, setCookie } from "../../components/cookiesUtils";
import {
  CLEAR_ROLE,
  CLEAR_TOKEN,
  GET_LOCATION,
  GET_LOCATION_ERROR,
  GET_LOCATION_SUCCESS,
  GET_ROLE,
  GET_TOKEN,
  PATCH_RESET_PASSWORD,
  PATCH_RESET_PASSWORD_ERROR,
  PATCH_RESET_PASSWORD_SUCCESS,
  POST_FORGET_PASSWORD,
  POST_FORGET_PASSWORD_ERROR,
  POST_FORGET_PASSWORD_SUCCESS,
  POST_LOGIN,
  POST_LOGIN_ERROR,
  POST_LOGIN_SUCCESS,
  POST_REGISTER,
  POST_REGISTER_ERROR,
  POST_REGISTER_SUCCESS,
  SET_ROLE,
  SET_TOKEN,
  LOGOUT,
  VERIFY_ACCOUNT_SUCCESS,
  VERIFY_ACCOUNT_ERROR,
  VERIFY_ACCOUNT,
} from "../actions";
const INIT_STATE = {
  postLogin: {},
  postLoginLoading: false,
  postLoginError: "",
  getLocation: [],
  getLocationLoading: false,
  getLocationError: "",
  postRegister: [],
  postRegisterLoading: false,
  postRegisterError: "",
  postForgetPassword: [],
  postForgetPasswordLoading: false,
  postForgetPasswordError: "",
  patchResetPassword: [],
  patchResetPasswordLoading: false,
  patchResetPasswordError: "",
  token: localStorage.getItem("token") || undefined,
  role: localStorage.getItem("role") || undefined,
  verifyAcc: {},
  verifyAccLoading: false,
  verifyAccError: ""
};

const Authsection = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_LOGIN:
      return {
        ...state,
        postLogin: {},
        postLoginLoading: true,
      };
    case POST_LOGIN_SUCCESS:
      return {
        ...state,
        postLogin: action.payload,
        postLoginLoading: false,
        postLoginError: "",
      };

    case POST_LOGIN_ERROR:
      return {
        ...state,
        postLoginLoading: false,
        postLoginError: action.payload.message,
      };
    case POST_REGISTER:
      return {
        ...state,
        postRegister: [],
        postRegisterLoading: true,
      };
    case POST_REGISTER_SUCCESS:
      return {
        ...state,
        postRegister: action.payload,
        postRegisterLoading: false,
        postRegisterError: "",
      };

    case POST_REGISTER_ERROR:
      return {
        ...state,
        postRegisterLoading: false,
        postRegisterError: action.payload.message,
      };
    case VERIFY_ACCOUNT:
      return {
        ...state,
        verifyAcc: {},
        verifyAccLoading: true,
        verifyAccError: "",
      };

    case VERIFY_ACCOUNT_SUCCESS:
      return {
        ...state,
        verifyAcc: action.payload,
        verifyAccLoading: false,
        verifyAccError: "",
      };

    case VERIFY_ACCOUNT_ERROR:
      return {
        ...state,
        verifyAccLoading: false,
        verifyAccError: action.payload.message,
      };
    case POST_FORGET_PASSWORD:
      return {
        ...state,
        postForgetPassword: [],
        postForgetPasswordLoading: true,
      };
    case POST_FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        postForgetPassword: action.payload,
        postForgetPasswordLoading: false,
        postForgetPasswordError: "",
      };

    case POST_FORGET_PASSWORD_ERROR:
      return {
        ...state,
        postForgetPasswordLoading: false,
        postForgetPasswordError: action.payload.message,
      };
    case PATCH_RESET_PASSWORD:
      return {
        ...state,
        patchResetPassword: [],
        patchResetPasswordLoading: true,
      };
    case PATCH_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        patchResetPassword: action.payload,
        patchResetPasswordLoading: false,
        patchResetPasswordError: "",
      };

    case PATCH_RESET_PASSWORD_ERROR:
      return {
        ...state,
        patchResetPasswordLoading: false,
        patchResetPasswordError: action.payload.message,
      };
    case GET_LOCATION:
      return {
        ...state,
        getLocation: [],
        getLocationLoading: true,
      };
    case GET_LOCATION_SUCCESS:
      return {
        ...state,
        getLocation: action.payload,
        getLocationLoading: false,
        getLocationError: "",
      };

    case GET_LOCATION_ERROR:
      return {
        ...state,
        getLocationLoading: false,
        getLocationError: action.payload.message,
      };
    case SET_TOKEN:
      setCookie("token", action.payload);
      return {
        ...state,
        token: action.payload,
      };
    case GET_TOKEN:
      const tokenFromCookie = getCookie("token");
      return {
        ...state,
        token: tokenFromCookie,
      };
    case CLEAR_TOKEN:
      deleteCookie("token");
      return {
        ...state,
        token: null,
      };
    case SET_ROLE:
      setCookie("role", action.payload);
      return {
        ...state,
        role: action.payload,
      };
    case GET_ROLE:
      const roleFromCookie = getCookie("role");
      return {
        ...state,
        role: roleFromCookie,
      };
    case CLEAR_ROLE:
      deleteCookie("role");
      return {
        ...state,
        role: null,
      };

    case LOGOUT:
      localStorage.removeItem('role')
      localStorage.removeItem('name')
      localStorage.removeItem('token')
      return {
        ...state,
        postLogin: {},
        postLoginLoading: false,
      }

    default:
      return { ...state };
  }
};
export default Authsection;