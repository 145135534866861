import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLicenceAction,
  postLicenceAction,
} from "../../../../redux/guard/action";
import toast from "react-hot-toast";
import * as yup from "yup";
import licenseTypes from "../../trades/component/licenseData";
import { Backdrop, CircularProgress } from "@mui/material";
import { Field, Form, Formik } from "formik";
import ReactSelect from "../../../formikComponent/reactSelect";
import { useNavigate } from "react-router-dom";
import DatePicker from "../../../formikComponent/datepicker";

function GuardAddLicense() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { postLicence, postLicenceLoading } = useSelector(
    (state) => state.GuardSection
  );
  const [initial, setInitial] = useState(false);
  const [showConfirmPage, setShowConfirmPage] = useState(false);
  const [licenseFormData, setLicenseFormData] = useState({
    licenseType: "",
    sector: "",
    trades: "",
  });
  const [licenseType, setLicenseType] = useState(licenseTypes);
  const [sector, setSector] = useState([]);
  const [trades, setTrades] = useState([]);

  const initialvalue = {
    operatedAs: "",
    licenseSector: "",
    siaNumber: "",
    expiryDateFrom: "",
    expiryDateTo: "",
    trades: [""],
  };

  const validationSchema = yup.object().shape({
    operatedAs: yup.string().required(),
    licenseSector: yup.string().required(),
    siaNumber: yup.string().required(),
    expiryDateFrom: yup.string().required(),
    expiryDateTo: yup.string().required(),
    // trades: yup.array().required(),
  });

  const selectLicenseType = (e, formik) => {
    const value = e.target.value;
    formik.setFieldValue("operatedAs", value);
    setLicenseFormData({ ...licenseFormData, licenseType: value });
    setSector(
      licenseType.find((i) => {
        return i.value === value;
      })?.sectors || []
    );
    // if (value === 'non_front_line') {
    //   formik.setFieldValue("licenseSector", "/");
    // } else {
    formik.setFieldValue("licenseSector", "");
    // }
  };

  const selectSector = (value, formik) => {
    setTrades([]);
    formik.setFieldValue("licenseSector", value);
    setLicenseFormData({ ...licenseFormData, sector: value });
    formik.setFieldValue("trades", []);
    const trade = sector.find((i) => {
      return i.name === value;
    })?.trades;
    const data = trade.map((tab) => ({
      label: tab,
      value: tab,
    }));
    setTrades(data);
  };

  const onSubmit = async (values) => {
    try {
      const trades = values?.trade;
      const payload = {
        licenseSector: values?.licenseSector,
        siaNumber: values?.siaNumber,
        expiryDateFrom: values?.expiryDateFrom,
        expiryDateTo: values?.expiryDateTo,
        trades: trades?.length
          ? trades.map((item) => item.value)
          : [trades?.value],
        role: values?.operatedAs,
      };
      dispatch(postLicenceAction(payload));
      // notify("License Created Successfully");
      // handleNext();
      // dispatch(getLicenceAction());
      setInitial(true);
    } catch (error) {
      notifyfail("Failed to create license");
      // Handle the error, log it, or show a message to the user.
      console.error("Error creating license:", error);
    }
  };

  useEffect(() => {
    if (postLicence?.success === true && initial === true) {
      setInitial(false);
      notify(postLicence.message);
      handleNext();
      dispatch(getLicenceAction());
    } else if (postLicence?.success === false) {
      setInitial(false);
      notifyfail(postLicence.message);
    }
  }, [postLicence]);
  const handleNext = () => {
    setShowConfirmPage(true);
  };

  const notify = (value) => {
    toast.success(value);
  };
  const notifyfail = (value) => {
    toast.error(value);
  };
  return (
    <>
      {" "}
      {showConfirmPage ? (
        <div className="p-10 bg-[#F1F2F6] w-full flex gap-7">
          <div className="w-[100%] ">
            <div className=" mt-6 bg-white rounded-2xl  md:py-24 py-5 md:px-16 px-5">
              <div
                style={{ textAlign: "center", margin: "7% auto" }}
                className="md:w-1/3 w-full"
              >
                <div className="text-3xl font-semibold mb-4">Thank you!</div>
                Your new license is under review, We will notify you soon.
              </div>

              <div className="flex justify-center">
                <button
                  onClick={() => navigate("/guard/my-licenses")}
                  className="text-center md:w-fit w-full  px-3 py-2 text-sm"
                >
                  My Licenses
                </button>
                <button
                  className="bg-primary md:w-fit w-full px-3 py-2 rounded-[20px] text-sm text-white justify-center flex items-center"
                  onClick={() => navigate("/guard/dashboard")}
                >
                  Dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="md:p-10 p-3 bg-[#F1F2F6] w-full flex gap-7">
          <div className="w-full ">
            <Formik
              initialValues={initialvalue}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => (
                <Form>
                  <div className=" mt-6 bg-white rounded-2xl p-6 md:pt-24 pt-6 md:pl-20">
                    <div
                      className="text-[#242424] font-semibold	text-2xl mb-14"
                      //   style={{ fontFamily: poppins.style.fontFamily }}
                    >
                      <span>Add license</span>
                    </div>
                    <div className="border-b-2 border-[#145EEE] pb-2.5 w-40">
                      SIA license
                    </div>
                    <div className="mt-10">
                      <div className="grid grid-cols-2 gap-10 w-full h-full flex-col poppins">
                        <div className="flex flex-col md:col-span-1 col-span-2">
                          <label
                            htmlFor="first-name "
                            className="text-[#999999] text-xs pl-1"
                          >
                            License type
                          </label>
                          <select
                            name="operated-as"
                            id="operated-as"
                            value={formik?.values?.operatedAs}
                            onChange={(e) => selectLicenseType(e, formik)}
                            className={`w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none  
              ${
                formik.errors.operatedAs && formik.touched.operatedAs
                  ? "border-b-2 border-b-red-600"
                  : ""
              }
              `}
                          >
                            <option
                              value=""
                              className="focus:outline-none"
                            ></option>
                            {licenseType?.map((license, i) => (
                              <option
                                key={i}
                                value={license.value}
                                className="focus:outline-none"
                              >
                                {license.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="flex flex-col md:col-span-1 col-span-2">
                          <label
                            htmlFor="first-name "
                            className="text-[#999999]  text-xs pl-1"
                          >
                            SIA License number
                          </label>
                          <input
                            type="text"
                            id="first-name"
                            value={formik?.values?.siaNumber}
                            onChange={(e) =>
                              formik.setFieldValue("siaNumber", e.target.value)
                            }
                            className={`w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none ${
                              formik.errors.siaNumber &&
                              formik.touched.siaNumber
                                ? "border-b-2 border-b-red-600"
                                : ""
                            }`}
                          ></input>
                        </div>

                        <div className="flex flex-col md:col-span-1 col-span-2">
                          <label
                            htmlFor="first-name "
                            className="text-[#999999] text-xs pl-1"
                          >
                            Expire date from
                          </label>

                          <DatePicker
                            placeholder="MM / DD / YYYY"
                            name="expiryDateFrom"
                            classname="w-full h-5 py-5 px-2 text-xs border-b-[#D8D8D8] border-b-2 outline-none"
                          />
                        </div>
                        <div className="flex flex-col md:col-span-1 col-span-2">
                          <label
                            htmlFor="first-name "
                            className="text-[#999999]  text-xs pl-1"
                          >
                            Expire date to
                          </label>

                          <DatePicker
                            placeholder="MM / DD / YYYY"
                            name="expiryDateTo"
                            classname="w-full h-5 py-5 px-2 text-xs border-b-[#D8D8D8] border-b-2 outline-none"
                            setdate={formik?.values?.expiryDateFrom}
                          />
                        </div>

                        <div className="flex flex-col md:col-span-1 col-span-2 relative">
                          <label
                            htmlFor="first-name "
                            className="text-[#999999]  text-xs pl-1"
                          >
                            License sector
                          </label>
                          <select
                            name="operated-as"
                            onChange={(e) =>
                              selectSector(e.target.value, formik)
                            }
                            value={formik?.values?.licenseSector}
                            id="operated-as"
                            className={`w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none ${
                              formik.errors.licenseSector &&
                              formik.touched.licenseSector
                                ? "border-b-2 border-b-red-600"
                                : ""
                            }`}
                          >
                            <option
                              value=""
                              className="focus:outline-none"
                            ></option>
                            {sector?.map((sector, i) => (
                              <option key={i} value={sector.value}>
                                {sector.name}
                              </option>
                            ))}
                          </select>
                          <div className="absolute flex inset-y-0 right-0 h-full items-center">
                            {/* <Image src={inputIcon} width={15} height={15} alt="input"></Image> */}
                          </div>
                        </div>

                        <div className="flex flex-col md:col-span-1 col-span-2 ">
                          <label
                            htmlFor="operated-as"
                            className="text-[#999999] text-xs pl-1"
                          >
                            Select all relevant trades
                          </label>
                          <ReactSelect name="trade" options={trades} />
                        </div>
                      </div>
                    </div>
                    <div className="flex md:justify-end justify-between mt-10">
                      <button
                        type="button"
                        onClick={() => navigate("/guard/my-licenses")}
                        className=" text-center md:w-fit w-full  px-3 py-2 text-sm"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-primary md:w-fit w-full px-3 py-2 rounded-[20px] text-sm text-white justify-center flex items-center"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
      {postLicenceLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={postLicenceLoading}
          // onClick={handleClose}
        >
          <CircularProgress style={{ color: "#083558" }} />
        </Backdrop>
      ) : null}
    </>
  );
}

export default GuardAddLicense;
