import { Field } from "formik";
import React from "react";
import inputIcon from "../../../../assets/images/text-edit-input-icon.svg";

function ProfessionalDetailsForm({ values }) {
  const data = [
    "Self employed",
    "Limited Company",
    "Ordinary Partnership",
    "Limited Partnership",
  ];
  return (
    <div className="flex w-full h-full flex-col poppins">
      <div className="grid grid-cols-2 gap-10 w-full py-[30px] ">
        <div className="flex flex-col md:col-span-1 col-span-2  w-full pr-2 ">
          <label
            htmlFor="operation_type"
            className="text-[#999999] text-xs pl-1"
          >
            You operate as:
          </label>
          <Field
            as="select"
            name="professionalDetails.operation_type"
            id="operation_type"
            // onChange={(e)=>handleSelect(e.target.value)}
            className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none "
          >
            <option>-- select --</option>
            {data.map((d, i) => (
              <option key={i} value={d} className="focus:outline-none">
                {d}
              </option>
            ))}
          </Field>
        </div>
        {data.includes(values?.professionalDetails?.operation_type) && (
          <div className="flex flex-col md:col-span-1 col-span-2 w-full  relative">
            <label
              htmlFor="trading_name"
              className="text-[#999999]  text-xs pl-1"
            >
              Trading name
            </label>
            <Field
              type="text"
              id="trading_name"
              name="professionalDetails.trading_name"
              className=" border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
            />
            <div className="absolute flex inset-y-0 right-0 h-full items-center">
              <img src={inputIcon} width={15} height={15} alt="input" />
            </div>
          </div>
        )}

        {["Limited Company", "Limited Partnership"].includes(
          values?.professionalDetails?.operation_type
        ) && (
          <>
            <div className="flex flex-col md:col-span-1 col-span-2 w-full ">
              <label
                htmlFor="registered_company_name"
                className="text-[#999999]  text-xs pl-1"
              >
                Registered company name
              </label>
              <Field
                placeholder="NA"
                type="text"
                id="registered_company_name"
                name="professionalDetails.registered_company_name"
                className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none placeholder:text-[black]"
              />
            </div>

            <div className="flex flex-col md:col-span-1 col-span-2 w-full ">
              <label
                htmlFor="company_reg_no"
                className="text-[#999999]  text-xs pl-1"
              >
                Company registration number
              </label>
              <Field
                type="text"
                placeholder="NA"
                id="company_reg_no"
                name="professionalDetails.company_reg_no"
                className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none placeholder:text-[black]"
              />
            </div>
          </>
        )}

        {["Ordinary Partnership"].includes(
          values?.professionalDetails?.operation_type
        ) && (
          <div className="flex flex-col md:col-span-1 col-span-2 w-full  text-xs">
            <label
              htmlFor="professionalDetails.fullNames_of_partners"
              className="text-[#999999]   "
            >
              Full names of partners
            </label>
            <Field
              type="text"
              id="professionalDetails.fullNames_of_partners"
              placeholder="NA"
              name="professionalDetails.fullNames_of_partners"
              className=" border-b-2 border-[#D8D8D8] text-[black] py-2 focus:outline-none placeholder:text-[black]"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfessionalDetailsForm;
