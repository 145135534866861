import moment from "moment";
import React from "react";
import { IoEyeOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";

function GuardRecentJob({ data }) {
  const navigate = useNavigate();
  return (
    <div className=" h-full flex flex-col bg-white  rounded-2xl  py-6 md:col-span-1 col-span-3 ">
      <h2 className="pl-9 pb-[16px] poppins">Recent Job applications</h2>
      <div className="border-b brder-[#E9E9F3]"></div>
      <div className="">
        {data?.map((recentJob) => {
          return (
            <div key={recentJob.id} className="poppins">
              <div className="flex flex-wrap gap-2  py-4 min-h-[40px] justify-between  p-6 md:pl-9 pl-5 ">
                <div className="flex flex-wrap">
                  <div className="pr-5">
                    {/* <Image
                      src={recentJob.jobLogoImg}
                      width={34}
                      height={34}
                      alt={recentJob.jobHeadline}
                    ></Image> */}
                  </div>
                  <div className="">
                    <div className="text-sm">{recentJob?.job?.title}</div>
                    <div className="text-[10px]">
                      {moment(recentJob?.job?.meta?.created_on).fromNow()}
                    </div>
                  </div>
                </div>
                <div className="flex justify-end ">
                  <Link
                    className="flex gap-2 border border-[#366CFE] px-2 py-1 rounded h-fit my-auto text-[#366CFE]"
                    to="/guard/job-search"
                  >
                    <div className="text-xs ">View</div>
                    <div>
                      <IoEyeOutline className="my-auto " />
                    </div>
                  </Link>
                </div>
              </div>
              <div className="border-b brder-[#E9E9F3]"></div>
            </div>
          );
        })}
      </div>
      <button
        type="button"
        onClick={() => navigate("/guard/job-search")}
        className="text-center text-[#366CFE] p-3.5 text-sm inter font-normal"
      >
        See all Jobs
      </button>
    </div>
  );
}

export default GuardRecentJob;
