import {
  GET_CLIENT_JOBS,
  GET_CLIENT_JOBS_ANALYSIS,
  GET_CLIENT_JOBS_ANALYSIS_ERROR,
  GET_CLIENT_JOBS_ANALYSIS_SUCCESS,
  GET_CLIENT_JOBS_ERROR,
  GET_CLIENT_JOBS_LIST,
  GET_CLIENT_JOBS_LIST_ERROR,
  GET_CLIENT_JOBS_LIST_SUCCESS,
  GET_CLIENT_JOBS_SUCCESS,
  GET_CLIENT_REVIEW,
  GET_CLIENT_REVIEW_ERROR,
  GET_CLIENT_REVIEW_SUCCESS,
  GET_FIND_TRADERS,
  GET_FIND_TRADERS_ERROR,
  GET_FIND_TRADERS_SUCCESS,
  PATCH_CLIENT_JOB_STATUS,
  PATCH_CLIENT_JOB_STATUS_ERROR,
  PATCH_CLIENT_JOB_STATUS_SUCCESS,
  POST_JOB,
  POST_JOB_ERROR,
  POST_JOB_SUCCESS,
  POST_JOB_TOKEN,
  POST_JOB_TOKEN_ERROR,
  POST_JOB_TOKEN_SUCCESS,
  POST_REVIEW,
  POST_REVIEW_ERROR,
  POST_REVIEW_SUCCESS,
  GET_CLIENT_DASHBOARD,
  GET_CLIENT_DASHBOARD_ERROR,
  GET_CLIENT_DASHBOARD_SUCCESS,
  GET_CLIENT_DASHBOARD_CHART,
  GET_CLIENT_DASHBOARD_CHART_ERROR,
  GET_CLIENT_DASHBOARD_CHART_SUCCESS,
  GET_CLIENT_POSTED_JOBS,
  GET_CLIENT_POSTED_JOBS_SUCCESS,
  GET_CLIENT_POSTED_JOBS_ERROR,
  REFERED_GUARD_DETAILS,
  REFERED_GUARD_DETAILS_SUCCSESS,
  REFERED_GUARD_DETAILS_ERROR,
  UPDATE_CLIENT_PASSWORD,
  UPDATE_CLIENT_PASSWORD_SUCCESS,
  UPDATE_CLIENT_PASSWORD_ERROR,
  UPDATE_CLIENT_PROFILE,
  UPDATE_CLIENT_PROFILE_SUCCESS,
  UPDATE_CLIENT_PROFILE_ERROR,
  SUBSCRIPTION_PAYMENT,
  SUBSCRIPTION_PAYMENT_SUCCESS,
  SUBSCRIPTION_PAYMENT_ERROR,
  COUNT_JOBS,
  COUNT_JOBS_SUCCESS,
  COUNT_JOBS_ERROR,
  PAYMENT_DATA,
  PAYMENT_DATA_SUCCESS,
  PAYMENT_DATA_ERROR,
} from "../actions";

export const getClientDashboardAction = (info) => ({
  type: GET_CLIENT_DASHBOARD,
  payload: info,
});

export const getClientDashboardSuccessAction = (info) => ({
  type: GET_CLIENT_DASHBOARD_SUCCESS,
  payload: info,
});

export const getClientDashboardErrorAction = (info) => ({
  type: GET_CLIENT_DASHBOARD_ERROR,
  payload: info,
});
export const getClientPostedJobsAction = (info) => ({
  type: GET_CLIENT_POSTED_JOBS,
  payload: info,
});

export const getClientPostedJobsSuccess = (info) => ({
  type: GET_CLIENT_POSTED_JOBS_SUCCESS,
  payload: info,
});

export const getClientPostedJobsError = (info) => ({
  type: GET_CLIENT_POSTED_JOBS_ERROR,
  payload: info,
});

export const getClientDashboardChartAction = (info) => ({
  type: GET_CLIENT_DASHBOARD_CHART,
  payload: info,
});

export const getClientDashboardChartSuccessAction = (info) => ({
  type: GET_CLIENT_DASHBOARD_CHART_SUCCESS,
  payload: info,
});

export const getClientDashboardChartErrorAction = (info) => ({
  type: GET_CLIENT_DASHBOARD_CHART_ERROR,
  payload: info,
});

export const getClientJobsAction = (info) => ({
  type: GET_CLIENT_JOBS,
  payload: info,
});
export const getClientJobsSuccess = (info) => ({
  type: GET_CLIENT_JOBS_SUCCESS,
  payload: info,
});
export const getClientJobsError = (error) => ({
  type: GET_CLIENT_JOBS_ERROR,
  payload: error,
});
export const getFindTradersAction = (info) => ({
  type: GET_FIND_TRADERS,
  payload: info,
});
export const getFindTradersSuccess = (info) => ({
  type: GET_FIND_TRADERS_SUCCESS,
  payload: info,
});
export const getFindTradersError = (error) => ({
  type: GET_FIND_TRADERS_ERROR,
  payload: error,
});
export const getClientJobsListAction = (info) => ({
  type: GET_CLIENT_JOBS_LIST,
  payload: info,
});
export const getClientJobsListSuccess = (info) => ({
  type: GET_CLIENT_JOBS_LIST_SUCCESS,
  payload: info,
});
export const getClientJobsListError = (error) => ({
  type: GET_CLIENT_JOBS_LIST_ERROR,
  payload: error,
});
export const getClientJobsAnalysisAction = (info) => ({
  type: GET_CLIENT_JOBS_ANALYSIS,
  payload: info,
});
export const getClientJobsAnalysisSuccess = (info) => ({
  type: GET_CLIENT_JOBS_ANALYSIS_SUCCESS,
  payload: info,
});
export const getClientJobsAnalysisError = (error) => ({
  type: GET_CLIENT_JOBS_ANALYSIS_ERROR,
  payload: error,
});
export const getClientReviewAction = (info) => ({
  type: GET_CLIENT_REVIEW,
  payload: info,
});
export const getClientReviewSuccess = (info) => ({
  type: GET_CLIENT_REVIEW_SUCCESS,
  payload: info,
});
export const getClientReviewError = (error) => ({
  type: GET_CLIENT_REVIEW_ERROR,
  payload: error,
});
export const postJobAction = (info) => ({
  type: POST_JOB,
  payload: info,
});
export const postJobSuccess = (info) => ({
  type: POST_JOB_SUCCESS,
  payload: info,
});
export const postJobError = (error) => ({
  type: POST_JOB_ERROR,
  payload: error,
});
export const postJobTokenAction = (info) => ({
  type: POST_JOB_TOKEN,
  payload: info,
});
export const postJobTokenSuccess = (info) => ({
  type: POST_JOB_TOKEN_SUCCESS,
  payload: info,
});
export const postJobTokenError = (error) => ({
  type: POST_JOB_TOKEN_ERROR,
  payload: error,
});
export const postReviewAction = (info) => ({
  type: POST_REVIEW,
  payload: info,
});
export const postReviewSuccess = (info) => ({
  type: POST_REVIEW_SUCCESS,
  payload: info,
});
export const postReviewError = (error) => ({
  type: POST_REVIEW_ERROR,
  payload: error,
});
export const patchClientJobStatusAction = (info) => ({
  type: PATCH_CLIENT_JOB_STATUS,
  payload: info,
});
export const patchClientJobStatusSuccess = (info) => ({
  type: PATCH_CLIENT_JOB_STATUS_SUCCESS,
  payload: info,
});
export const patchClientJobStatusError = (error) => ({
  type: PATCH_CLIENT_JOB_STATUS_ERROR,
  payload: error,
});

export const referedGuardDetailsAction = (info) => ({
  type: REFERED_GUARD_DETAILS,
  payload: info,
});
export const referedGuardDetailsSuccess = (info) => ({
  type: REFERED_GUARD_DETAILS_SUCCSESS,
  payload: info,
});
export const referedGuardDetailsError = (error) => ({
  type: REFERED_GUARD_DETAILS_ERROR,
  payload: error,
});

export const updateClientPasswordAction = (info) => ({
  type: UPDATE_CLIENT_PASSWORD,
  payload: info,
});
export const updateClientPasswordSuccess = (info) => ({
  type: UPDATE_CLIENT_PASSWORD_SUCCESS,
  payload: info,
});
export const updateClientPasswordError = (error) => ({
  type: UPDATE_CLIENT_PASSWORD_ERROR,
  payload: error,
});

export const updateClientProfileAction = (info) => ({
  type: UPDATE_CLIENT_PROFILE,
  payload: info,
});
export const updateClientProfileSuccess = (info) => ({
  type: UPDATE_CLIENT_PROFILE_SUCCESS,
  payload: info,
});
export const updateClientProfileError = (error) => ({
  type: UPDATE_CLIENT_PROFILE_ERROR,
  payload: error,
});

export const subscriptionPaymentAction = (info) => ({
  type: SUBSCRIPTION_PAYMENT,
  payload: info,
});
export const subscriptionPaymentSuccess = (info) => ({
  type: SUBSCRIPTION_PAYMENT_SUCCESS,
  payload: info,
});
export const subscriptionPaymentError = (error) => ({
  type: SUBSCRIPTION_PAYMENT_ERROR,
  payload: error,
});

export const getPaymentsAction = (info) => ({
  type: PAYMENT_DATA,
  payload: info,
});
export const getPaymentsSuccess = (info) => ({
  type: PAYMENT_DATA_SUCCESS,
  payload: info,
});
export const getPaymentsError = (error) => ({
  type: PAYMENT_DATA_ERROR,
  payload: error,
});
