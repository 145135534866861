import React from "react";
import { Disclosure } from "@headlessui/react";
import { Link } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa6";

function Accordion({ name, options }) {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full justify-between">
            {name}
            <FaAngleDown
              className={`${
                open ? "rotate-180 transform" : ""
              } h-5 w-5 text-white`}
            />
          </Disclosure.Button>
          {options?.map((item, index) => (
            <Disclosure.Panel
              key={index}
              className="text-gray-500 flex gap-5 items-center"
            >
              {item.icon}
              <Link to={item.link} className=" text-sm">
                {item.name}
              </Link>
            </Disclosure.Panel>
          ))}
        </>
      )}
    </Disclosure>
  );
}

export default Accordion;
