import React from "react";
import { GoArrowLeft } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";


function NotFoundPage() {
  const router = useNavigate();
  return (
    <div className="flex items-center justify-center h-screen ">
      <div className="text-center fill-black">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="880"
          height="357"
          viewBox="0 0 880 357"
          fill="#F2F4F7"
          className="absolute md:h-auto md:w-auto w-[200px] h-96 md:-translate-x-1/4 md:-translate-y-1/3 translate-x-1/4 -translate-y-1/3 "
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M439.348 356.4C411.664 356.4 387.902 349.378 368.062 335.336C348.335 321.181 333.148 300.792 322.5 274.167C311.964 247.429 306.696 215.243 306.696 177.609C306.808 139.977 312.132 107.96 322.668 81.5591C333.316 55.0466 348.503 34.8248 368.23 20.8956C388.07 6.96457 411.775 0 439.348 0C466.921 0 490.628 6.96457 510.467 20.8956C530.306 34.8248 545.493 55.0466 556.03 81.5591C566.678 108.072 572.001 140.089 572.001 177.609C572.001 215.357 566.678 247.599 556.03 274.335C545.493 300.96 530.306 321.293 510.467 335.336C490.74 349.378 467.034 356.4 439.348 356.4ZM439.348 303.655C460.869 303.655 477.85 293.039 490.291 271.808C502.845 250.462 509.122 219.064 509.122 177.609C509.122 150.199 506.264 127.17 500.547 108.521C494.831 89.8714 486.761 75.8302 476.337 66.3923C465.913 56.8442 453.583 52.0692 439.348 52.0692C417.94 52.0692 401.016 62.7429 388.574 84.0865C376.132 105.318 369.855 136.494 369.744 177.609C369.632 205.133 372.378 228.275 377.982 247.037C383.698 265.797 391.769 279.952 402.192 289.502C412.616 298.938 425.001 303.655 439.348 303.655Z"
            fill="#F2F4F7"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 285.795V236.083L146.103 4.719H223.778V234.397H267.828V285.795H223.778V349.827H164.261V285.795H0ZM162.244 75.4923H164.933V234.397H63.8888V231.702L162.244 75.4923Z"
            fill="#F2F4F7"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M612.172 236.083V285.795H776.433V349.827H835.951V285.795H880V234.397H835.951V4.719H758.276L612.172 236.083ZM777.106 75.4923H774.416L676.061 231.702V234.397H777.106V75.4923Z"
            fill="#F2F4F7"
          />
        </svg>
        <div className="relative">

        <p className="text-[#101828] md:text-6xl text-xl font-semibold">We lost this page</p>
        <p className="text-[#475467] md:text-xl text-sm mt-6">The page you are looking for doesn't exist or has been moved.</p>
        <div className="mt-6  justify-center flex md:flex-row flex-col gap-4 px-4">
          <button className="flex gap-3 border justify-center border-[#D0D5DD] bg-white hover:bg-[#145DEB] text-[#344054] hover:text-white font-semibold px-6 py-4 rounded-lg" onClick={() => router(-1)}>
          <GoArrowLeft className="text-lg my-auto"/>

            Go Back
          </button>
          <Link to="/"className="flex gap-3 justify-center border border-[#D0D5DD] hover:bg-black bg-[#145DEB] text-white font-semibold px-6 py-4 rounded-lg md:w-fit w-full">Go Home
          </Link>
        </div>
      </div>
      </div>
    </div>
  );
}

export default NotFoundPage;
