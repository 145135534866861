import React from "react";

import {
  motion,
  useScroll,
  useTransform,
  useSpring,
  useMotionValueEvent,
} from "framer-motion";
import toggle_left from "../../assets/images/how-it-works/toggle_left.webp";
import toggle_right from "../../assets/images/how-it-works/toggle_right.webp";

const Toggle = ({ handleToggle, toggle }) => {
  return (
    <div className="relative inline-block w-[64px] h-[32px]  rounded-full">
      <input
        type="checkbox"
        id="toggle"
        className="opacity-0 peer absolute w-full h-full left-0 z-10 cursor-pointer"
        onClick={() => handleToggle()}
        checked={toggle ? true : false}
      />
      <div className="w-full h-full bg-gray-200/30 rounded-full peer-checked:bg-gray-200/30 transition duration-300"></div>
      <span className="transition-all duration-300 absolute top-[2px] left-[2px] bg-white rounded-full w-[28px] h-[28px] peer-checked:left-[34px] "></span>
    </div>
  );
};

function Header({ toggle, handleToggle }) {
  return (
    <div className=" sm:py-[50px] xl:py-[100px]">
      <motion.div
        initial={{ y: "20%", opacity: 0, scale: 0.8 }}
        viewport={{ once: true }}
        whileInView={{
          y: 1,
          opacity: 1,
          scale: 1,
          transition: { duration: 0.5 },
        }}>
        <h1 className="text-[#dbe1e8] font-bold text-[clamp(52px,calc(80/1340*100vw),80px)] leading-[0.95] text-center max-w-[850px] mx-auto">
          Cookies Policy
        </h1>
      </motion.div>
    </div>
  );
}

export default Header;
