import React from "react";
import Header from "./header";

import Navbar from "../../pages/main/homepage/components/navbar";

import Footer from "../../pages/main/homepage/components/footer";

import "./styles.css";

const TermsAndConditions = ({ toggle }) => {
  // const [toggle, setToggle] = useState(false);

  return (
    <>
      <div
        className={`w-full bg-gradient-to-r ${toggle ? "from-[#023BA9] to-[#0B0D1F]" : "from-[#145DEB] to-[#0B0D1F]"}  px-[20px] pt-[20px] relative overflow-x-hidden `}>
        {/* <div
          className="absolute top-0 left-0 h-full w-full z-[1]"
          style={{
            background:
              "url(http://www.script-tutorials.com/demos/360/images/stars.png) repeat top center",
          }}
        /> */}
        <Navbar transparent />

        <div className="max-w-[1340px] mx-auto relative z-[10] mt-[50px]">
          {/* <Nav toggle={toggle} /> */}
          <Header />
        </div>
      </div>
      <div className={`w-full  px-[20px] pt-[20px] trms_condition`}>
        <div className="max-w-[1340px] mx-auto py-[50px]">
          <h1>Terms and Conditions</h1>

          <h2>1. Introduction</h2>
          <p>
            Welcome to Igardu! These Terms and Conditions govern your use of the
            Igardu website and mobile application (collectively referred to as
            the "Platform"). The Platform is owned and operated by Igardu, Inc.
            ("Igardu", "we", "us", "our"). By accessing or using the Platform,
            you agree to comply with these Terms and Conditions.
          </p>

          <h2>2. Definitions</h2>
          <p>In these Terms and Conditions:</p>
          <ul>
            <li>
              "Client" refers to any individual or entity seeking to hire
              security personnel through the Platform.
            </li>
            <li>
              "Security Personnel" refers to individuals or entities offering
              security services through the Platform.
            </li>
            <li>
              "Job Posting" refers to a listing created by a Client detailing
              their requirements for security personnel.
            </li>
            <li>
              "Assignment" refers to a specific job opportunity posted by a
              Client and accepted by Security Personnel.
            </li>
          </ul>

          <h2>3. Registration and Accounts</h2>
          <p>
            In order to access certain features of the Platform, you may be
            required to register for an account. When registering, you agree to
            provide accurate and complete information. You are responsible for
            maintaining the confidentiality of your account credentials and for
            all activities that occur under your account.
          </p>

          <h2>4. Job Postings and Assignments</h2>
          <p>
            Clients may create Job Postings on the Platform detailing their
            requirements for security personnel. Security Personnel may apply to
            these Job Postings and, if selected by the Client, may accept
            Assignments through the Platform. Igardu does not guarantee the
            accuracy, completeness, or suitability of Job Postings, nor does it
            guarantee the performance or conduct of Security Personnel.
          </p>

          <h2>5. Privacy Policy</h2>
          <p>
            Your privacy is important to us. Please review our Privacy Policy,
            which explains how we collect, use, and disclose your personal
            information when you use the Platform.
          </p>

          <h2>6. Intellectual Property</h2>
          <p>
            The content and materials available on the Platform, including but
            not limited to text, graphics, logos, images, and software, are the
            property of Igardu or its licensors and are protected by copyright
            and other intellectual property laws. You may not reproduce, modify,
            distribute, or otherwise use any content from the Platform without
            our prior written consent.
          </p>

          <h2>7. Limitation of Liability</h2>
          <p>
            To the fullest extent permitted by applicable law, Igardu shall not
            be liable for any indirect, incidental, special, consequential, or
            punitive damages, or any loss of profits or revenues, whether
            incurred directly or indirectly, or any loss of data, use, goodwill,
            or other intangible losses, resulting from:
          </p>
          <ul>
            <li>
              Your access to or use of or inability to access or use the
              Platform.
            </li>
            <li>Any conduct or content of any third party on the Platform.</li>
            <li>Any content obtained from the Platform.</li>
          </ul>

          <h2>8. Governing Law</h2>
          <p>
            These Terms and Conditions shall be governed by and construed in
            accordance with the laws of [Your Country], without regard to its
            conflict of law provisions.
          </p>

          <h2>9. Contact Us</h2>
          <p>
            If you have any questions about these Terms and Conditions, please
            contact us at [Your Contact Information].
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default TermsAndConditions;
