import { all, fork, call, takeEvery, put } from "redux-saga/effects";
import {
  GET_LOCATION,
  PATCH_RESET_PASSWORD,
  POST_FORGET_PASSWORD,
  POST_LOGIN,
  POST_REGISTER,
  VERIFY_ACCOUNT,
} from "../actions";
import {
  getLocationError,
  getLocationSuccess,
  patchResetPasswordError,
  patchResetPasswordSuccess,
  postForgetPasswordError,
  postForgetPasswordSuccess,
  postLoginError,
  postLoginSuccess,
  postRegisterError,
  postRegisterSuccess,
  verifyAccountSuccess,
  verifyAccountError,
} from "./action";

// const ApiLinks = "https://igardu-server.onrender.com/api/v1"
const ApiLinks = process.env.REACT_APP_BASE_URL;

//login api
function* postLoginCall(paylaod) {
  try {
    const getApiRes = yield call(postLoginAsync, paylaod);
    yield put(postLoginSuccess(getApiRes));
  } catch (error) {
    yield put(postLoginError());
  }
}
const postLoginAsync = (payload) =>
  fetch(`${ApiLinks}/auth/login`, {
    method: "POST",
    // mode: "no-cors",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchPostLoginData() {
  yield takeEvery(POST_LOGIN, postLoginCall);
}

//register api
function* postRegisterCall(paylaod) {
  try {
    const getApiRes = yield call(postRegisterAsync, paylaod);

    yield put(postRegisterSuccess(getApiRes));
  } catch (error) {
    yield put(postRegisterError());
  }
}
const postRegisterAsync = (payload) =>
  fetch(`${ApiLinks}/user/register`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchPostRegisterData() {
  yield takeEvery(POST_REGISTER, postRegisterCall);
}

function* verifyAccountCall(paylaod) {
  try {
    const getApiRes = yield call(verifyAccountAsync, paylaod);

    yield put(verifyAccountSuccess(getApiRes));
  } catch (error) {
    yield put(verifyAccountError());
  }
}
const verifyAccountAsync = (payload) =>
  fetch(`${ApiLinks}/auth/verify`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${payload.payload.token}`,
    },
    body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchVerifyAccountData() {
  yield takeEvery(VERIFY_ACCOUNT, verifyAccountCall);
}

//forget password api
function* postForgetPasswordCall(paylaod) {
  try {
    const getApiRes = yield call(postForgetPasswordAsync, paylaod);

    yield put(postForgetPasswordSuccess(getApiRes));
  } catch (error) {
    yield put(postForgetPasswordError());
  }
}
const postForgetPasswordAsync = (payload) =>
  fetch(`${ApiLinks}/user/forget-password`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(payload.payload),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchPostForgetPasswordData() {
  yield takeEvery(POST_FORGET_PASSWORD, postForgetPasswordCall);
}
//forget password api
function* patchResetPasswordCall(paylaod) {
  try {
    const getApiRes = yield call(patchResetPasswordAsync, paylaod);

    yield put(patchResetPasswordSuccess(getApiRes));
  } catch (error) {
    yield put(patchResetPasswordError());
  }
}
const patchResetPasswordAsync = ({ payload }) =>
  fetch(`${ApiLinks}/user/reset-password`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${payload.token}`,
    },
    body: JSON.stringify(payload.data),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchPatchResetPasswordData() {
  yield takeEvery(PATCH_RESET_PASSWORD, patchResetPasswordCall);
}

//get location
function* getLocationCall(paylaod) {
  try {
    const getApiRes = yield call(getLocationAsync, paylaod);

    yield put(getLocationSuccess(getApiRes));
  } catch (error) {
    yield put(getLocationError(error));
  }
}
const getLocationAsync = (payload) =>
  fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${22.7195687},${75.8577258}&key=AIzaSyDv8pOMxP-jYLK9F8KWZd4HLC4Dw-xLWXg`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    }
  )
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => err);

export function* watchgetLocationData() {
  yield takeEvery(GET_LOCATION, getLocationCall);
}

export default function* rootSaga() {
  yield all([
    fork(watchPostLoginData),
    fork(watchPostRegisterData),
    fork(watchPostForgetPasswordData),
    fork(watchPatchResetPasswordData),
    fork(watchgetLocationData),
    fork(watchVerifyAccountData),
  ]);
}
