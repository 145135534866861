import {all} from 'redux-saga/effects';
import authSaga from './auth/saga'
import profileSaga from "./profile/saga"
import licenseSaga from "./guard/saga"
import clientSaga from "./client/saga"

import adminSaga from "./admin/saga"

 export default function* rootSaga(){
     yield all([
        authSaga(),
        profileSaga(),
        licenseSaga(),
        clientSaga(),
        adminSaga()
     ])
 }