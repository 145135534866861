import React, { useState } from "react";
import "./ChatComponent.css";
import ChatButton from "../chat-button/ChatButton";
import ChatScreen from "../chat-screen/ChatScreen";

const ChatComponent = () => {
  const [isChatOpen, setChatOpen] = useState(false);

  const handleChatButtonClick = () => {
    setChatOpen(!isChatOpen);
  };

  return (
    <div className="chat-app">
      <ChatButton onClick={handleChatButtonClick} />
      <ChatScreen isOpen={isChatOpen} onClose={handleChatButtonClick} />
    </div>
  );
};

export default ChatComponent;
