import React, { useEffect, useState } from "react";
import { Field } from "formik";
const data = [
  "Self employed",
  "Limited Company",
  "Ordinary Partnership",
  "Limited Partnership",
];

const ProgressOne = ({ setFieldValue, values, errors }) => {
  // const operatorAs = useSelector((s) => s.traderSignUp.data.operatorAs);

  // useEffect(() => {
  //   setIsOperateAsSelected(operatorAs)
  // }, [operatorAs])

  // useEffect(() => {
  //   setFormInputErrorStatus(isAboutTraderStepOneValidate)
  // }, [isAboutTraderStepOneValidate])

  const onSelectHandler = (item) => {
    // setCurr(item);
    setFieldValue("professionalDetails.operation_type", item);
    // dispatch(traderSignUpActions.setOperatorAs(item))
    // setIsOperateAsSelected(true);
  };

  // const onInputChageHandler = (e, dataItem) => {
  //   setFormInputValidation(prevValue => ({
  //     ...prevValue,
  //     ["form-input-validation-" + dataItem.dataId]: (e.target.value) ? true : false
  //   }));
  //   setFormInputErrorStatus(prevValue => ({
  //     ...prevValue,
  //     ["form-input-validation-" + dataItem.dataId]: (e.target.value) ? true : false
  //   }));
  // };

  return (
    <div
      className={`w-full flex justify-center min-h-screen ${
        values?.professionalDetails?.operation_type ? " mb-[70px] " : ""
      } items-center `}
    >
      <div className="w-full p-4 md:w-1/3 pb-16">
        <h1 className="nekst text-5xl text-secondary font-semibold mt-14">
          About you
        </h1>
        <p className="text-gray-400 mt-6">You operate as a:</p>
        {data.map((item, index) => (
          <div
            onClick={() => onSelectHandler(item)}
            key={index}
            className={
              "w-full text-sm font-semibold cursor-pointer hover:border-primary hover:text-[#366CFE] border-2 my-2 rounded-lg flex items-center gap-4 px-3 py-4 " +
              (values?.professionalDetails?.operation_type === item
                ? "border-primary text-secondary"
                : "border-gray-300 text-gray-500")
            }
          >
            <div className="w-5 h-5 flex items-center justify-center rounded-full border border-gray-400 ">
              {values?.professionalDetails?.operation_type === item ? (
                <div className="w-3 h-3 bg-primary rounded-full "></div>
              ) : (
                <></>
              )}
            </div>
            <p
              className={
                values?.professionalDetails?.operation_type === item
                  ? "text-[#366CFE]"
                  : ""
              }
            >
              {item}
            </p>
          </div>
        ))}

        <InputsForSelectedOperator
          operatedAsType={values?.professionalDetails?.operation_type}
        ></InputsForSelectedOperator>
      </div>
    </div>
  );
};

export const InputsForSelectedOperator = ({ operatedAsType }) => {
  return (
    <div className="my-8 ">
      {inputDatAcordingToOperator &&
        inputDatAcordingToOperator.map((data, index) => {
          if (data.operateAs === operatedAsType) {
            return (
              <div key={index} className="mt-5 ">
                <p className="text-secondary my-1">{data.label}</p>
                <p className="text-gray-400 mb-1">
                  {data.actionInfoText && data.actionInfoText}
                </p>
                <Field
                  name={`professionalDetails.${data.name}`}
                  type={data.inputType}
                  className="w-full  border rounded-[30px] bg-[#E9E7F4] h-10 px-4 outline-none "
                  variant="outlined"
                />
                <div></div>
              </div>
            );
          }
        })}
    </div>
  );
};

export const inputDatAcordingToOperator = [
  {
    label: "Trading name",
    name: "trading_name",
    dataId: 1,
    operateAs: "Self employed",
    actionInfoText: "This can be just your name if you don’t have one ",
    inputType: "text",
  },
  {
    dataId: 2,
    name: "trading_name",
    label: "Trading name",
    operateAs: "Limited Company",
    inputType: "text",
  },
  {
    dataId: 3,
    name: "company_reg_no",
    label: "Registered company name",
    name: "registered_company_name",
    operateAs: "Limited Company",
    inputType: "text",
  },
  {
    dataId: 4,
    name: "company_reg_no",
    label: "Company registration number",
    operateAs: "Limited Company",
    inputType: "number",
  },
  {
    dataId: 5,
    label: "Trading name",
    name: "trading_name",
    operateAs: "Limited Partnership",
    inputType: "text",
  },
  {
    dataId: 6,
    label: "Registered company name",
    name: "registered_company_name",
    operateAs: "Limited Partnership",
    inputType: "text",
  },
  {
    dataId: 7,
    label: "Company registration number",
    name: "company_reg_no",
    operateAs: "Limited Partnership",
    inputType: "number",
  },
  {
    dataId: 8,
    label: "Trading name",
    operateAs: "Ordinary Partnership",
    name: "trading_name",
    inputType: "text",
  },
  {
    dataId: 9,
    label: "Full name of partners",
    name: "fullNames_of_partners",
    operateAs: "Ordinary Partnership",
    inputType: "text",
    actionInfoText:
      "Comma-separated list of all names on the partnership agreement, including yourself (e.g. John Smith, Jane Doe)",
  },
];
export default ProgressOne;
