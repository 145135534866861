import { Backdrop, CircularProgress } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfileAction } from "../../../redux/profile/action";
import { getToken } from "../../../redux/auth/action";
import * as Yup from "yup";
import {
  updateClientPasswordAction,
  updateClientProfileAction,
} from "../../../redux/client/action";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function ClientProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, role } = useSelector((state) => state.Authsection);
  const { getProfile } = useSelector((state) => state.ProfileSection);
  const { getProfileLoading } = useSelector((state) => state.ProfileSection);
  const [data, setData] = useState([]);
  const [initial, setInitial] = useState(true);
  const { updateClientPassword, updateClientPasswordLoading } = useSelector(
    (state) => state.ClientSection
  );
  const { updateClientProfile, updateClientProfileLoading } = useSelector(
    (state) => state.ClientSection
  );
  const [updatePressed, setUpdatePressed] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    dispatch(getToken());
  }, []);

  useEffect(() => {
    if (getProfile?.success && initial === true) {
      console.log(getProfile);

      setData(getProfile?.data);
      setInitial(false);
    }
  }, [getProfile]);

  useEffect(() => {
    if (token !== undefined) {
      dispatch(getProfileAction(token));
    }
  }, [token]);

  const initialValues = {
    first_name: data?.first_name || "",
    last_name: data?.last_name || "",
    email: data?.email || "",
    countryCode: "",
    phone: data?.phone || "",
  };

  const initialPassValues = {
    old_password: "",
    new_password: "",
    confirm_password: "",
  };

  const validationSchema = Yup.object({
    old_password: Yup.string().required("Old password is required"),
    new_password: Yup.string().required("Password is required"),
    confirm_password: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
  });

  useEffect(() => {
    console.log(updateClientProfile, "updateClientProfile");
    if (updateClientProfile?.success && isSubmitted) {
      toast.success(updateClientProfile?.message);
      setIsSubmitted(false);
      window.location.reload();
    }
  }, [updateClientProfile]);

  const onSubmit = (value) => {
    const payload = {
      first_name: value.first_name,
      last_name: value.last_name,
      country_code: value.countryCode ? value.countryCode : "+44",
      phone: value.phone,
    };

    dispatch(updateClientProfileAction(payload));
    setIsSubmitted(true);
  };

  useEffect(() => {
    if (updateClientPassword?.success && updatePressed) {
      toast.success(updateClientPassword?.message);
      navigate(`/${role}/dashboard`);
    } else if (!updateClientPassword?.success && updatePressed) {
      toast.error(updateClientPassword?.message);
    }
  }, [updateClientPassword]);

  const onSubmitPass = (value) => {
    const passwordDetails = {
      old_password: value.old_password ? value.old_password : "",
      new_password: value.new_password ? value.new_password : "",
      confirm_password: value.confirm_password ? value.confirm_password : "",
    };

    console.log(passwordDetails);

    dispatch(updateClientPasswordAction(passwordDetails));
    setUpdatePressed(true);
  };

  return (
    <>
      <div className="mt-10 flex w-full  bg-[#F1F2F6] px-10 py-10">
        <div className="w-full flex flex-col  bg-white rounded-xl h-fit">
          <div className="md:px-12 px-6 py-6 border-b-2 border-b-[#D8D8D8]">
            Personal details
          </div>
          <div>
            <div className="w-full md:px-16 px-6">
              <Formik
                initialValues={initialValues}
                enableReinitialize
                // validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ values, setFieldValue, errors }) => (
                  <Form>
                    <div className="grid grid-cols-2 w-full gap-12 py-[30px]  h-full  poppins">
                      <div className="flex flex-col md:col-span-1 col-span-2">
                        <label
                          htmlFor="first-name "
                          className="text-[#999999] text-xs pl-1"
                        >
                          First Name
                        </label>
                        <Field
                          type="text"
                          id="first-name"
                          name="first_name"
                          className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none "
                        />
                      </div>
                      <div className="flex flex-col md:col-span-1 col-span-2">
                        <label
                          htmlFor="first-name "
                          className="text-[#999999]  text-xs pl-1"
                        >
                          Last Name
                        </label>
                        <Field
                          type="text"
                          name="last_name"
                          className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
                        />
                      </div>
                      <div className="flex flex-col md:col-span-1 col-span-2">
                        <label
                          htmlFor="email-address"
                          className="text-[#999999]  text-xs pl-1"
                        >
                          Email Address
                        </label>
                        <Field
                          type="email"
                          id="email-address"
                          disabled={data.email === "" ? false : true}
                          name="email"
                          className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
                        />
                      </div>
                      <div className="flex flex-col md:col-span-1 col-span-2">
                        <label
                          htmlFor="phone-no"
                          className="text-[#999999] text-[10px] pl-1"
                        >
                          Mobile phone
                        </label>
                        <div className="flex">
                          <Field
                            as="select"
                            name="countryCode"
                            className=" mr-4 focus:outline-none w-[80px] text-xs border-b-2 border-[#D8D8D8]"
                          >
                            <option>
                              {data?.country_code ? data?.country_code : "+44"}
                            </option>
                            <option>+44</option>
                            <option>+144</option>
                            <option>+91</option>
                            <option>+34</option>
                          </Field>
                          <Field
                            type="text"
                            id="phone-no"
                            name="phone"
                            className="w-full border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col md:col-start-2 md:col-span-1 col-span-2">
                        <button
                          type="submit"
                          className="bg-primary px-7 py-2.5 text-sm text-white justify-center flex items-center md:w-fit w-full rounded-md ml-auto"
                        >
                          {updateClientProfileLoading ? (
                            <img
                              src={"/loading.svg"}
                              alt="loading"
                              className="w-6 h-6"
                            />
                          ) : (
                            <p>Update</p>
                          )}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full min-h-screen bg-[#F1F2F6] px-10 py-10">
        <div className="w-full flex flex-col  bg-white rounded-xl h-fit">
          <div className="md:px-12 px-6 py-6 border-b-2 border-b-[#D8D8D8]">
            Update password
          </div>
          <div>
            <div className="w-full md:px-16 px-6">
              <Formik
                initialValues={initialPassValues}
                validationSchema={validationSchema}
                onSubmit={onSubmitPass}
              >
                {({}) => (
                  <Form>
                    <div className="flex flex-col gap-4 w-full py-[30px]  h-full poppins">
                      <div className="flex flex-col md:col-span-1 col-span-2">
                        <label
                          htmlFor="old-password"
                          className="text-[#999999] text-xs pl-1"
                        >
                          Old Password
                        </label>
                        <Field
                          type="password"
                          id="old-password"
                          name="old_password"
                          className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none "
                        />
                      </div>

                      <div className="text-sm text-red-500">
                        <ErrorMessage name="old_password" />
                      </div>

                      <div className="flex flex-col md:col-span-1 col-span-2">
                        <label
                          htmlFor="new-password"
                          className="text-[#999999] text-xs pl-1"
                        >
                          New Password
                        </label>
                        <Field
                          type="password"
                          name="new_password"
                          className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
                        />
                      </div>

                      <div className="text-sm text-red-500">
                        <ErrorMessage name="new_password" />
                      </div>

                      <div className="flex flex-col md:col-span-1 col-span-2">
                        <label
                          htmlFor="confirm-password"
                          className="text-[#999999] text-xs pl-1"
                        >
                          Confirm New Password
                        </label>
                        <Field
                          type="password"
                          id="confirm-password"
                          name="confirm_password"
                          className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
                        />
                      </div>

                      <div className="text-sm text-red-500">
                        <ErrorMessage
                          name="confirm_password"
                          className="error-message"
                        />
                      </div>

                      <div className="flex flex-col md:col-start-2 md:col-span-1 col-span-2 mt-5">
                        <button
                          type="submit"
                          className="bg-primary px-7 py-2.5 text-sm text-white justify-center flex items-center md:w-fit w-full rounded-md ml-auto"
                        >
                          {updateClientPasswordLoading ? (
                            <img
                              src={"/loading.svg"}
                              alt="loading"
                              className="w-6 h-6"
                            />
                          ) : (
                            <span>Update</span>
                          )}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {getProfileLoading || updateClientProfileLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={getProfileLoading}
          // onClick={handleClose}
        >
          <CircularProgress style={{ color: "#083558" }} />
        </Backdrop>
      ) : null}
      s
    </>
  );
}

export default ClientProfile;
