import React, { useEffect, useState } from "react";
import TabProfile from "./component/tabProfile";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../../redux/auth/action";
import { getProfileAction } from "../../../redux/profile/action";
import {
  getGuardProfileAction,
  putProfileAction,
} from "../../../redux/guard/action";
import toast from "react-hot-toast";
import PhotoImage from "./component/profileImage";

function GuardProfile() {
  const dispatch = useDispatch();
  const { token, role } = useSelector((state) => state.Authsection);
  const { getProfile, getProfileLoading } = useSelector(
    (state) => state.ProfileSection
  );
  const {
    getGuardProfile,
    getGuardProfileLoading,
    putProfile,
    putProfileLoading,
  } = useSelector((state) => state.GuardSection);
  const [data, setData] = useState([]);
  const [dataOthers, setDataOthers] = useState([]);
  const [initial, setInitial] = useState(true);
  const [confirmIdValid, setConfirmIdValid] = useState(true);
  const [isSaved, setIsSaved] = useState(false);
  const [imageUpdateLoaidng, setUpdateImageLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    console.log(imageUpdateLoaidng);
  }, [imageUpdateLoaidng]);

  const mailToLink = `mailto:info@igardu.com`;

  useEffect(() => {
    dispatch(getToken());
  }, []);

  useEffect(() => {
    if (token !== undefined) {
      dispatch(getProfileAction(token));
      dispatch(getGuardProfileAction());
    }
  }, [token]);
  useEffect(() => {
    if (
      putProfile?.success &&
      putProfile?.message &&
      initial === true &&
      isSaved
    ) {
      console.log(putProfile, "put profile");

      notify(putProfile.message);
      dispatch(getProfileAction(token));
      dispatch(getGuardProfileAction());
      navigate(`/${role}/dashboard`);
      setInitial(false);
    } else if (
      !putProfile?.success &&
      putProfile?.message &&
      initial === true &&
      isSaved
    ) {
      notifyfail(putProfile.message);
    }
  }, [putProfile]);
  useEffect(() => {
    if (getProfile?.success) {
      console.log(getProfile?.data);
      setData(getProfile?.data);
    }
  }, [getProfile]);
  useEffect(() => {
    if (getGuardProfile?.success) {
      setDataOthers(getGuardProfile?.data);
    }
  }, [getGuardProfile]);

  const initialValues = {
    first_name: data?.first_name || "",
    last_name: data?.last_name || "",
    email: data?.email || "",
    countryCode: data?.country_code || "",
    phone: data?.phone || "",
    description: dataOthers?.description || "",
    professionalDetails: {
      operation_type: dataOthers?.profession_details?.operation_type || "",
      fullNames_of_partners:
        dataOthers?.profession_details?.fullNames_of_partners || "",
      company_reg_no: dataOthers?.profession_details?.company_reg_no || "",
      registered_company_name:
        dataOthers?.profession_details?.registered_company_name || "",
      trading_name: dataOthers?.profession_details?.trading_name || "",
    },
    location: {
      max_distance: dataOthers?.location?.max_distance || "",
      address: dataOthers?.location?.address || "",
      lat: dataOthers?.location?.lat,
      lng: dataOthers?.location?.lng,
    },
    documents: dataOthers?.documents || [],
    password: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
  };
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    country_code: Yup.string().required("Country code is required"),
    phone: Yup.string().required("Phone number is required"),
    description: Yup.string().required("Description is required"),
    // location: Yup.object().shape({
    //   max_distance: Yup.string().required("Max distance is required"),
    //   address: Yup.string().required("Address is required"),
    // }),
  });

  const onsubmit = (values) => {
    if (values.new_password !== values.confirm_password) {
      notifyfail("New password does not match with confirm password");
      return;
    }

    const personalDetails = {
      first_name: values.first_name,
      last_name: values.last_name,
      description: values.description,
      phone: values.phone,
      country_code: values.country_code,
    };

    const passwordDetails = {
      old_password: values.old_password ? values.old_password : "",
      new_password: values.new_password ? values.new_password : "",
      confirm_password: values.confirm_password ? values.confirm_password : "",
    };

    const payload = {
      personalDetails: personalDetails,
      professionalDetails: values.professionalDetails,
      location: values.location,
      documents: values.documents,
      passwordDetails: passwordDetails,
    };

    setInitial(true);
    setIsSaved(true);
    dispatch(putProfileAction(payload));
  };

  const notify = (value) => {
    toast.success(value);
  };
  const notifyfail = (value) => {
    toast.error(value);
  };
  return (
    <>
      {" "}
      <div className="md:p-10 p-3 bg-[#F1F2F6] w-full flex md:flex-row flex-col gap-7">
        <PhotoImage
          data={data}
          setUploadImageLoading={(data) => {
            setUpdateImageLoading(data);
          }}
        />
        <div className="md:w-[70%] ">
          {/* <div className="h-60 bg-white rounded-2xl p-7">
          <div>Your account is being virified</div>
          <div className="flex justify-between">
          <div className="text-[10px] text-[#545454] w-[50%] my-3">
            If you need to update your information your will be able to
            request a change after current verification is completed
          </div>
          <div className="text-[10px] text-[#545454]  ">
          badge can be under processing  
          </div>
          </div>
          <div className=" flex py-7">
            <HorizontalLinearStepper
              activeStep={activeStep}
              steps={steps}
            ></HorizontalLinearStepper>
          </div>
        </div> */}
          <div className="h-fit bg-white rounded-2xl md:p-6 p-3 ">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              // validationSchema={validationSchema}
              onSubmit={onsubmit}
            >
              {({ values, setFieldValue, errors, touched }) => (
                <Form>
                  <TabProfile
                    data={data}
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    setIsChecked={(data) => {
                      setConfirmIdValid(data);
                    }}
                  />
                  {confirmIdValid && (
                    <div className="flex md:justify-end justify-between">
                      <button
                        className=" md:w-fit w-full py-2 px-3 text-sm"
                        onClick={() => navigate(`/${role}/dashboard`)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-primary md:w-fit w-full py-2 md:px-6 px-3  rounded-[20px] text-sm text-white justify-center flex items-center"
                      >
                        Save
                      </button>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className="h-[350px] bg-white rounded-2xl items-center flex-col justify-center px-10 md:hidden flex">
          <div>
            {/* <img
          width={53}
          height={53}
          src={"/chat.svg"}
          alt=""
          className="w-full h-full"
        /> */}
          </div>
          <div className="pt-6 text-base	">Need Help?</div>
          <div className="text-[10px] py-6 font-normal leading-4	text-[#242424]	">
            Have questions or concerns regarding your account? Our experts are
            here to help!
          </div>

          <div className="pt-12">
            <Link
              to={mailToLink}
              className="px-3 md:px-8 lg:px-10 py-3 
 bg-[#145EEE]  rounded-[5px] text-white text-[14px] font-semibold  duration-300  "
            >
              Send email us
            </Link>
          </div>
        </div>
      </div>
      {getProfileLoading ||
      putProfileLoading ||
      getGuardProfileLoading ||
      imageUpdateLoaidng ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={
            getProfileLoading ||
            putProfileLoading ||
            getGuardProfileLoading ||
            imageUpdateLoaidng
          }
          // onClick={handleClose}
        >
          <CircularProgress style={{ color: "#083558" }} />
        </Backdrop>
      ) : null}
    </>
  );
}

export default GuardProfile;
