import React from 'react'
import ReviewCard from '../../trade-members/components/ReviewCard'

const UserReviewSection = ({currUser}) => {
    return (
    <div className='border-b py-10 w-full'>
        <p className='text-lg underline font-medium mb-8'>Reviews</p>
        <div className="flex w-full justify-between flex-wrap">
            {currUser?.client_reviews?.map((v, i) => (
                <ReviewCard {...v} key={i} />
            ))}
        </div>
    </div>
    )
}

export default UserReviewSection