import React from "react";

const PaymentListCard = () => {
  return (
    <div className="p-5 min-h-[180px] h-[220px] max-lg:h-full  rounded-[5px] poppins shadow-md hover:bg-[#145EEE05] border border-[#F1F5FE]">
      <div className="flex flex-row gap-4 justify-between">
        <div className="flex-row">
          <p className="lg:text-[20px] text-sm font-bold  leading-[24px] text-black mr-2">
            Title
          </p>
          <div className="flex flex-row items-center">
            <p className="md:text-[14px] text-xs font-medium	">Status</p>
          </div>
        </div>
        <div>
          <p className=" text-[12px] leading-[18px] text-[#565656] justify-end flex lg:pr-4 text-right ">
            Date:
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentListCard;
