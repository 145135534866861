import React from "react";
import ecoonomic from "../../assets/images/about-us/ecoonomic.svg";
import intergrity from "../../assets/images/about-us/intergrity.svg";
import workforce from "../../assets/images/about-us/workforce.svg";
import diversity from "../../assets/images/about-us/diversity.svg";
import discover_2 from "../../assets/images/about-us/discover_2.svg";
import enviroment from "../../assets/images/about-us/enviroment.svg";

import { motion } from "framer-motion";

const _initialLeft = { x: "-40%", opacity: 0 };
const _whileInViewLeft = {
  x: 0,
  opacity: 1,
  transition: {
    delay: 0.2,
    duration: 0.8,
  },
};

const _viewport = { once: true };

const _inital = {
  y: "20%",
  opacity: 0,
  scale: 0.8,
};

const _whileInView = {
  y: 1,
  opacity: 1,
  scale: 1,
  transition: { duration: 0.8 },
};

const focus = [
  {
    id: "1",
    icon: ecoonomic,
    title: "Trusted Professionals",
    description:
      "Gain access to certified security experts who prioritize your safety through rigorous screening processes.",
  },

  {
    id: "2",
    icon: intergrity,
    title: "Business integrity",
    description:
      "Integrity drives us. We uphold the highest ethical standards, fostering trust and transparency in all our relationships.",
  },
  {
    id: "3",
    icon: diversity,
    title: "Diverity, Inclusion & Belonging",
    description:
      "We embrace diversity, fostering innovation and positive change in our inclusive environment.",
  },

  {
    id: "54",
    icon: workforce,
    title: "Workforce Innovation and Wellbeing",
    description:
      "We empower economic opportunities by connecting job seekers with employers and facilitating trade, fostering financial stability and business growth.",
  },

  {
    id: "6",
    icon: enviroment,
    title: "Environmental Sustainability",
    description:
      "We prioritize environmental sustainability, minimizing our footprint and building a greener future through sustainable practices.",
  },

  {
    id: "26",
    icon: discover_2,
    title: "Supplier Engagement",
    description:
      "We prioritize collaborative relationships with suppliers based on trust and mutual respect, ensuring quality and integrity in our offerings by engaging with like-minded partners.",
  },
];

function FocusingOn() {
  return (
    <div className={`w-full  px-[20px]  my-[0px] xl:mt-[100px] pb-[50px]`}>
      <div className="max-w-[1340px] mx-auto">
        <div className="flex flex-col md:flex-row gap-[20px] sm:gap-[32px] md:gap-[86px] items-start md:items-center">
          <motion.div
            className=""
            initial={_initialLeft}
            whileInView={{
              ..._whileInViewLeft,
              transition: {
                duration: 0.8,
              },
            }}
            viewport={_viewport}>
            <h2 className="text-[#1A202C] font-[600]  text-[28px] leading-[36px] sm:text-[36px]sm:leading-[46px] mt-[16px]">
              What we’re focusing on
            </h2>
          </motion.div>
        </div>

        <div className=" grid grid-cols-1 sm:grid-cols-2 xl:flex gap-[24px] mt-[32px] md:mt-[55px] flex-wrap justify-center ">
          {focus.map((item, index) => {
            return (
              <motion.div
                initial={_inital}
                whileInView={{
                  ..._whileInView,
                  transition: {
                    duration: 0.8,
                    delay: 0.2 * index,
                  },
                }}
                viewport={_viewport}
                key={item?.id}
                className="bg-[#F5FAFF] flex-1 py-[32px] md:py-[20px] xl:py-[32px] px-[20px] xl:px-[32px] grow-0 shrink-0
                 min-w-[clamp(200px,calc(430/1440*100vw),430px)] h-[300px] md:h-[300px] lg:h-[clamp(200px,calc(296/1440*100vw),296px)] rounded-[10px] flex flex-col items-center justify-center">
                <img
                  src={item?.icon}
                  alt="icon_desc"
                  className="h-[clamp(40px,calc(60/1440*100vw),60px)] aspect-square mb-[16px] xl:mb-[24px]"
                />

                <h3 className="px-[10px] xl:px-[20px] text-[20px] xl:text-[22px] text-center leading-[27px] font-[600] text-[#1E293B] mb-[8px]">
                  {item?.title}
                </h3>

                <p className=" mx-auto max-w-[320px]  text-center   text-[14px] md:text-[13px] xl:text-[14px]  leading-[19px] xl:leading-[22px] font-[400] text-[#374151]">
                  {item?.description}
                </p>
              </motion.div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FocusingOn;
