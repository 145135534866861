import { DELETE_NAME, GET_NAME, GET_PROFILE, GET_PROFILE_ERROR, GET_PROFILE_SUCCESS, POST_TRADER_REGISTER, POST_TRADER_REGISTER_ERROR, POST_TRADER_REGISTER_SUCCESS, SET_NAME } from "../actions";

export const getProfileAction = (info) => ({
  type: GET_PROFILE,
  payload: info,
});
export const getProfileSuccess = (info) => ({
  type: GET_PROFILE_SUCCESS,
  payload: info,
});
export const getProfileError = (error) => ({
  type: GET_PROFILE_ERROR,
  payload: error,
});
export const postTraderRegisterAction = (info) => ({
  type: POST_TRADER_REGISTER,
  payload: info,
});
export const postTraderRegisterSuccess = (info) => ({
  type: POST_TRADER_REGISTER_SUCCESS,
  payload: info,
});
export const postTraderRegisterError = (error) => ({
  type: POST_TRADER_REGISTER_ERROR,
  payload: error,
});
export const setName = (info) => ({
  type: SET_NAME,
  payload: info,
});
export const getName = (info) => ({
  type: GET_NAME,
  payload: info,
});
export const deleteName = (info) => ({
  type: DELETE_NAME,
  payload: info,
});