import React, { useState, useEffect } from "react";
import { FrontlineSubRoles } from "../data";
import { FaSearch } from "react-icons/fa";

const FrontlineJobSelectProgress = ({ values, setFieldValue }) => {
  const [selectedRoles, setSelectedRoles] = useState(values?.jobs || []);
  console.log(values, "job values");

  useEffect(() => {
    setSelectedRoles(values?.jobs || []);
  }, [values.jobs]);

  const onCheckedHandler = (e, sr) => {
    let newArray = [...selectedRoles];
    const removeRole = newArray.indexOf(sr);

    if (removeRole !== -1) {
      newArray.splice(removeRole, 1);
    } else {
      newArray.push(sr);
    }
    setSelectedRoles(newArray);
    setFieldValue("jobs", newArray);
  };

  return (
    <div className="w-full flex justify-center flex-wrap pt-8">
      <div className="text-center w-7/12">
        <h1 className="text-4xl font-medium">
          Select the license that applies to you
        </h1>
        <p className="w-7/12 mx-auto my-2 text-center">
          Select the positions to suit your business. Once your application has
          been approved you can go for more.
        </p>

        <div className="relative w-1/2 mx-auto mt-10">
          <input
            type="text"
            className="w-full mx-auto p-2 pl-10 rounded-xl border border-black focus:outline-primary"
            placeholder="Search here"
          />
          <FaSearch className="w-4 h-4 object-contain absolute left-3 top-0 bottom-0 m-auto" />
        </div>
      </div>
      <div className="w-10/12 mt-5 mx-auto flex flex-wrap gap-2 items-center">
        {values?.licenceType &&
          values?.licenceType === "front_line" &&
          Object.entries(FrontlineSubRoles).map(([key, subRoles]) => {
            if (key === values?.licenseSector) {
              return subRoles.map((sr) => {
                console.log(selectedRoles.includes(sr));
                return (
                  <div
                    key={sr}
                    className={
                      "px-3 py-2 cursor-pointer flex items-center gap-2 rounded-md border-2 text-center text-sm font-medium hover:border-primary hover:text-primary " +
                      (selectedRoles.includes(sr)
                        ? "border-primary text-primary"
                        : "border-gray-300 text-secondary")
                    }
                  >
                    <input
                      id={sr}
                      readOnly
                      type="checkbox"
                      checked={selectedRoles.includes(sr)}
                      className="cursor-pointer"
                      onClick={(e) => onCheckedHandler(e, sr)}
                    />{" "}
                    <label htmlFor={sr}>{sr}</label>
                  </div>
                );
              });
            }
            return null;
          })}
      </div>
    </div>
  );
};

export default FrontlineJobSelectProgress;
