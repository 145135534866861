import { ErrorMessage, Field } from "formik";
import React from "react";

function ProfileDetailsForm({ data, errors, touched }) {
  return (
    <div className="grid grid-cols-2 w-full gap-12 py-[30px]  h-full  poppins">
      <div className="flex flex-col md:col-span-1 col-span-2">
        <label htmlFor="first-name " className="text-[#999999] text-xs pl-1">
          First Name
        </label>
        <Field
          type="text"
          id="first-name"
          name="first_name"
          className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none "
        />
        <ErrorMessage name="first_name" component="div" className="error" />
      </div>
      <div className="flex flex-col md:col-span-1 col-span-2">
        <label htmlFor="first-name " className="text-[#999999]  text-xs pl-1">
          Last Name
        </label>
        <Field
          type="text"
          name="last_name"
          className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
        />
        <ErrorMessage name="last_name" component="div" className="error" />
      </div>
      <div className="flex flex-col md:col-span-1 col-span-2">
        <label htmlFor="email-address" className="text-[#999999]  text-xs pl-1">
          Email Address
        </label>
        <Field
          type="email"
          id="email-address"
          disabled={data.email == "" ? false : true}
          name="email"
          className="w-40% border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
        />
        <ErrorMessage name="email" component="div" className="error" />
      </div>
      <div className="flex flex-col md:col-span-1 col-span-2 ">
        <label htmlFor="phone-no" className="text-[#999999] text-[10px] pl-1">
          Mobile phone
        </label>
        <div className="flex">
          <Field
            as="select"
            id="country_code"
            name="country_code"
            className=" mr-4 focus:outline-none w-[80px] text-xs border-b-2 border-[#D8D8D8]"
            // disabled={true}
          >
            <option>{data?.country_code ? data?.country_code : "+44"}</option>
            <option>+144</option>
            <option>+91</option>
            <option>+34</option>
          </Field>
          <Field
            type="text"
            id="phone-no"
            name="phone"
            // disabled={data.phone === "" ? false : true}
            className="w-full border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 pl-1 focus:outline-none"
          />
          <ErrorMessage name="phone" component="div" className="error" />
        </div>
      </div>
      <div className="flex flex-col col-span-2">
        <label htmlFor="description" className="text-[#999999] text-[10px]  ">
          Description
        </label>
        <Field
          as="textarea"
          type="text"
          id="description"
          name="description"
          className=" border-b-2 border-[#D8D8D8] text-xs text-[black] py-2 focus:outline-none"
        ></Field>
        <ErrorMessage name="description" component="div" className="error" />
      </div>
    </div>
  );
}

export default ProfileDetailsForm;
