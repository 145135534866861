import React from "react";
import Status from "./status";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Card = ({i, trade, add }) => {
  const router = useNavigate();


  return (
    <div className="rounded-xl border border-[#C4C4C4] font-encode bg-white overflow-hidden  py-6 px-5" key={i}>
      <div
        className="text-center md:text-base text-xs mb-6"
        // style={{ fontFamily: poppins.style.fontFamily }}
      >
        {trade?.name}
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div
        //   style={{ fontFamily: poppins.style.fontFamily }}
          className="md:text-xs text-[8px] text-[#8E92BC]"
        >
          <span>SIA License number</span>
          <p className="text-black text-[10px]" style={{overflowWrap: "break-word"}}>{trade?.sia_number}</p>
        </div>
        <div
        //   style={{ fontFamily: poppins.style.fontFamily }}
          className="text-xs text-[#8E92BC]"
        >
          <span>License expiry date</span>
          <p className="text-black text-[12px]">
           {moment(trade?.expiry_date_to).format("DD.MM.YYYY")}
          </p>
        </div>

        <div
        //   style={{ fontFamily: poppins.style.fontFamily }}
          className="text-xs text-[#8E92BC]"
        >
          <span>Role</span>
          <p className='text-black text-[12px]'>{trade.role}</p>
        </div>
        <div
        //   style={{ fontFamily: poppins.style.fontFamily }}
          className="text-xs text-[#8E92BC]"
        >
          <span>Sector</span>
          {/* {trade?.trades?.map((item, index)=>  */}
          <p className="text-black text-[12px]">{trade?.sector}</p>
          {/* )} */}
        </div>

        <div className="">
          <Status status={trade?.status} />
        </div>

        {!add ? (
          <div
            onClick={() => router(`/guard/my-trades/add-trades/${trade.id}`)}
            className="float-right  cursor-pointer"
          >
            <Status status="add-trades" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Card;
