import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRatingAction } from "../../../redux/guard/action";
import { Backdrop, CircularProgress, Rating } from "@mui/material";
import moment from "moment";

function GuardReviews() {
  const dispatch = useDispatch();
  const { getRating } = useSelector((state) => state.GuardSection);
  const { getRatingLoading } = useSelector((state) => state.GuardSection);

  const [data, setData] = useState([]);
  const [initial, setInitial] = useState(true);
  const [ratingDetails, setRatingDetails] = useState([]);

  useEffect(() => {
    dispatch(getRatingAction());
  }, [dispatch]);

  useEffect(() => {
    if (getRating?.success && initial === true) {
      setData(getRating?.data);
      setInitial(false);
      setRatingDetails(ratingDetailFn(getRating?.data?.star_average));
    }
  }, [getRating, initial]);

  const ratingDetailFn = (data) => {
    const totalRatings = parseInt(data?.number_of_ratings);
    const fiveStar = parseInt(data?.five_star);
    const fourStar = parseInt(data?.four_star);
    const threeStar = parseInt(data?.three_star);
    const twoStar = parseInt(data?.two_star);
    const oneStar = parseInt(data?.one_star);

    const maxMeterValue = 100;

    const ratingDetails = [
      {
        star: 5,
        RatingPercentage: fiveStar, //Math.round(((fiveStar / totalRatings) * 100).toFixed(2)) || 0 ,
        ratingMeterValue:
          Math.round(
            Math.min(maxMeterValue, fiveStar * (maxMeterValue / totalRatings))
          ) || 0,
      },
      {
        star: 4,
        RatingPercentage: fourStar, // Math.round(((fourStar / totalRatings) * 100).toFixed(2) ) || 0,
        ratingMeterValue:
          Math.round(
            Math.min(maxMeterValue, fourStar * (maxMeterValue / totalRatings))
          ) || 0,
      },
      {
        star: 3,
        RatingPercentage: threeStar, // (Math.round(((threeStar / totalRatings) * 100).toFixed(2) ) || 0 ),
        ratingMeterValue:
          Math.round(
            Math.min(maxMeterValue, threeStar * (maxMeterValue / totalRatings))
          ) || 0,
      },
      {
        star: 2,
        RatingPercentage: twoStar, //Math.round(((twoStar / totalRatings) * 100).toFixed(2) ) || 0 ,
        ratingMeterValue:
          Math.round(
            Math.min(maxMeterValue, twoStar * (maxMeterValue / totalRatings))
          ) || 0,
      },
      {
        star: 1,
        RatingPercentage: oneStar, //Math.round(((oneStar / totalRatings) * 100).toFixed(2))  || 0 ,
        ratingMeterValue:
          Math.round(
            Math.min(maxMeterValue, oneStar * (maxMeterValue / totalRatings))
          ) || 0,
      },
    ];

    return ratingDetails;
  };

  return (
    <>
      {" "}
      <div className="bg-[#F1F2F6] w-full p-10  ">
        <div className="flex flex-row gap-7 pb-12 max-[1024px]:flex-col">
          <div className=" w-[246px] max-[1024px]:w-full  bg-white h-[219px] p-6  rounded-2xl pt-9 ">
            <center className="text-[#366CFE] text-5xl  w-full pb-5">
              {parseFloat(data?.star_average?.average_rating ?? 5.0).toFixed(1)}
            </center>
            <center>
              <Rating
                precision={0.5}
                value={parseFloat(
                  data?.star_average?.average_rating ?? 5.0
                ).toFixed(1)}
                readOnly
              />
            </center>
            <center className="text-base">Average Rating</center>
          </div>
          <div className=" w-[80%] max-[1024px]:w-full h-[219px] max-[724px]:h-full  bg-white  p-6  rounded-2xl px-12">
            {ratingDetails &&
              ratingDetails?.map((rd, i) => {
                return (
                  <div
                    key={i}
                    className="flex  w-full pb-3 justify-between max-[724px]:flex-col"
                  >
                    <div className="md:w-[65%] ">
                      <meter
                        value={rd.ratingMeterValue}
                        min="1"
                        max="100"
                        className="w-full"
                      ></meter>
                    </div>
                    <div className="md:w-[30%] flex  md:justify-start  justify-between">
                      <span className="flex justify-start ">
                        <Rating
                          precision={0.5}
                          value={parseFloat(rd.star).toFixed(1)}
                          readOnly
                        />
                      </span>
                      <span className=" pl-4 flex md:justify-start text-[#366CFE] text-sm">
                        {rd.RatingPercentage}
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className=" bg-white  p-6  rounded-2xl pt-9 px-10">
          {data &&
            data?.reviews?.map((reviewData, i) => {
              return (
                <div
                  key={i}
                  className="border border-[#CBDDFF] px-12 py-7 mb-3.5"
                >
                  <div className="flex justify-between max-[724px]:flex-col  pb-5">
                    <span className="flex">
                      {/* <Image
                src={reviewData.reviewerProfileUrl}
                width={40}
                height={40}
                alt="profile"
              ></Image> */}
                      <img
                        src="/profile-image.webp"
                        className="w-12 h-12 rounded-full"
                      />
                      <span className="pl-4">
                        <div className="text-[#0B1029] text-base">
                          {reviewData?.client?.first_name}{" "}
                          {reviewData?.client?.last_name}
                        </div>
                        <div className="text-[##595B66] text-[10px]">
                          {moment(reviewData?.meta?.created_on).format(
                            "MM.DD.YYYY"
                          )}
                        </div>
                      </span>
                    </span>
                    <span>
                      <Rating
                        precision={0.5}
                        value={parseFloat(reviewData?.rating).toFixed(1)}
                        readOnly
                      />
                    </span>
                  </div>
                  <div className="text-[#545454] text-base">
                    {reviewData.description}
                  </div>
                </div>
              );
            })}
        </div>

        <div className="flex justify-end pt-10  w-full ">
          <div className="bg-white rounded-2xl h-11	flex items-center">
            {/* <Pagination count={10} color="primary" /> */}
          </div>
        </div>
      </div>
      {getRatingLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={getRatingLoading}
          // onClick={handleClose}
        >
          <CircularProgress style={{ color: "#083558" }} />
        </Backdrop>
      ) : null}
    </>
  );
}

export default GuardReviews;

// const ratingDetails = [
//   {
//     starts: 5,
//     RatingPercentage: "100%",
//     ratingMeterValue: 2,
//   },
//   {
//     starts: 4,
//     RatingPercentage: "0%",
//     ratingMeterValue: 0,
//   },
//   {
//     starts: 3,
//     RatingPercentage: "0%",

//     ratingMeterValue: 0,
//   },
//   {
//     starts: 2,
//     RatingPercentage: "0%",

//     ratingMeterValue: 0,
//   },
//   {
//     starts: 1,
//     RatingPercentage: "0%",

//     ratingMeterValue: 0,
//   },
// ];
