import {
  GET_GUARD_DASHBOARD,
  GET_GUARD_DASHBOARD_ERROR,
  GET_GUARD_DASHBOARD_SUCCESS,
  GET_GUARD_PROFILE,
  GET_GUARD_PROFILE_ERROR,
  GET_GUARD_PROFILE_SUCCESS,
  GET_JOBLIST,
  GET_JOBLIST_ERROR,
  GET_JOBLIST_SUCCESS,
  GET_JOBS,
  GET_JOBS_ANALYSIS,
  GET_JOBS_ANALYSIS_ERROR,
  GET_JOBS_ANALYSIS_SUCCESS,
  GET_JOBS_ERROR,
  GET_JOBS_SUCCESS,
  GET_LICENCE,
  GET_LICENCE_ERROR,
  GET_LICENCE_SUCCESS,
  GET_RATING,
  GET_RATING_ERROR,
  GET_RATING_SUCCESS,
  PATCH_JOB_STATUS,
  PATCH_JOB_STATUS_ERROR,
  PATCH_JOB_STATUS_SUCCESS,
  POST_JOB_APPLY,
  POST_JOB_APPLY_ERROR,
  POST_JOB_APPLY_SUCCESS,
  POST_LICENCE,
  POST_LICENCE_ERROR,
  POST_LICENCE_SUCCESS,
  PUT_LICENCE,
  PUT_LICENCE_ERROR,
  PUT_LICENCE_SUCCESS,
  PUT_PROFILE,
  PUT_PROFILE_ERROR,
  PUT_PROFILE_SUCCESS,
  UPDATE_PROFILE_IMAGE,
  UPDATE_PROFILE_IMAGE_ERROR,
  UPDATE_PROFILE_IMAGE_SUCCESS,
  INVITE_CO_WORKERS,
  INVITE_CO_WORKERS_SUCCSESS,
  INVITE_CO_WORKERS_ERROR,
  REFER_GUARD,
  REFER_GUARD_SUCCESS,
  REFER_GUARD_ERROR,
  REFER_CO_WORKERS,
  REFER_CO_WORKERS_SUCCSESS,
  REFER_CO_WORKERS_ERROR,
} from "../actions";

export const getLicenceAction = (info) => ({
  type: GET_LICENCE,
  payload: info,
});
export const getLicenceSuccess = (info) => ({
  type: GET_LICENCE_SUCCESS,
  payload: info,
});
export const getLicenceError = (error) => ({
  type: GET_LICENCE_ERROR,
  payload: error,
});
export const getRatingAction = (info) => ({
  type: GET_RATING,
  payload: info,
});
export const getRatingSuccess = (info) => ({
  type: GET_RATING_SUCCESS,
  payload: info,
});
export const getRatingError = (error) => ({
  type: GET_RATING_ERROR,
  payload: error,
});
export const getJobsAction = (info) => ({
  type: GET_JOBS,
  payload: info,
});
export const getJobsSuccess = (info) => ({
  type: GET_JOBS_SUCCESS,
  payload: info,
});
export const getJobsError = (error) => ({
  type: GET_JOBS_ERROR,
  payload: error,
});
export const getJobsAnalysisAction = (info) => ({
  type: GET_JOBS_ANALYSIS,
  payload: info,
});
export const getJobsAnalysisSuccess = (info) => ({
  type: GET_JOBS_ANALYSIS_SUCCESS,
  payload: info,
});
export const getJobsAnalysisError = (error) => ({
  type: GET_JOBS_ANALYSIS_ERROR,
  payload: error,
});
export const getJobListAction = (info) => ({
  type: GET_JOBLIST,
  payload: info,
});
export const getJobListSuccess = (info) => ({
  type: GET_JOBLIST_SUCCESS,
  payload: info,
});
export const getJobListError = (error) => ({
  type: GET_JOBLIST_ERROR,
  payload: error,
});

export const getGuardDashboardAction = (info) => ({
  type: GET_GUARD_DASHBOARD,
  payload: info,
});
export const getGuardDashboardSuccess = (info) => ({
  type: GET_GUARD_DASHBOARD_SUCCESS,
  payload: info,
});
export const getGuardDashboardError = (error) => ({
  type: GET_GUARD_DASHBOARD_ERROR,
  payload: error,
});
export const getGuardProfileAction = (info) => ({
  type: GET_GUARD_PROFILE,
  payload: info,
});
export const getGuardProfileSuccess = (info) => ({
  type: GET_GUARD_PROFILE_SUCCESS,
  payload: info,
});
export const getGuardProfileError = (error) => ({
  type: GET_GUARD_PROFILE_ERROR,
  payload: error,
});

export const postLicenceAction = (info) => {
  return {
    type: POST_LICENCE,
    payload: info,
  };
};

export const postLicenceSuccess = (info) => ({
  type: POST_LICENCE_SUCCESS,
  payload: info,
});
export const postLicenceError = (error) => ({
  type: POST_LICENCE_ERROR,
  payload: error,
});

export const putProfileAction = (info) => ({
  type: PUT_PROFILE,
  payload: info,
});
export const putProfileSuccess = (info) => ({
  type: PUT_PROFILE_SUCCESS,
  payload: info,
});
export const putProfileError = (error) => ({
  type: PUT_PROFILE_ERROR,
  payload: error,
});

export const updateProfileImageAction = (info) => ({
  type: UPDATE_PROFILE_IMAGE,
  payload: info,
});
export const updateProfileImageActionSuccess = (info) => ({
  type: UPDATE_PROFILE_IMAGE_SUCCESS,
  payload: info,
});
export const updateProfileImageActionError = (error) => ({
  type: UPDATE_PROFILE_IMAGE_ERROR,
  payload: error,
});

export const postJobApplyAction = (info) => ({
  type: POST_JOB_APPLY,
  payload: info,
});
export const postJobApplySuccess = (info) => ({
  type: POST_JOB_APPLY_SUCCESS,
  payload: info,
});
export const postJobApplyError = (error) => ({
  type: POST_JOB_APPLY_ERROR,
  payload: error,
});
export const patchJobStatusAction = (info) => ({
  type: PATCH_JOB_STATUS,
  payload: info,
});
export const patchJobStatusSuccess = (info) => ({
  type: PATCH_JOB_STATUS_SUCCESS,
  payload: info,
});
export const patchJobStatusError = (error) => ({
  type: PATCH_JOB_STATUS_ERROR,
  payload: error,
});
export const putLicenceAction = (info) => ({
  type: PUT_LICENCE,
  payload: info,
});
export const putLicenceSuccess = (info) => ({
  type: PUT_LICENCE_SUCCESS,
  payload: info,
});
export const putLicenceError = (error) => ({
  type: PUT_LICENCE_ERROR,
  payload: error,
});
export const inviteCoWorkersAction = (info) => ({
  type: INVITE_CO_WORKERS,
  payload: info,
});
export const inviteCoWorkersSuccess = (info) => ({
  type: INVITE_CO_WORKERS_SUCCSESS,
  payload: info,
});
export const inviteCoWorkersError = (error) => ({
  type: INVITE_CO_WORKERS_ERROR,
  payload: error,
});

export const getReferedGuardsAction = (info) => ({
  type: REFER_GUARD,
  payload: info,
});
export const getReferedGuardsSuccess = (info) => ({
  type: REFER_GUARD_SUCCESS,
  payload: info,
});
export const getReferedGuardsError = (error) => ({
  type: REFER_GUARD_ERROR,
  payload: error,
});

export const referCoWorkersAction = (info) => ({
  type: REFER_CO_WORKERS,
  payload: info,
});
export const referCoWorkersSuccess = (info) => ({
  type: REFER_CO_WORKERS_SUCCSESS,
  payload: info,
});
export const referCoWorkersError = (error) => ({
  type: REFER_CO_WORKERS_ERROR,
  payload: error,
});
