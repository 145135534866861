import React from 'react'

const CalendarIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <g clipPath="url(#clip0_2230_2527)">
                <path d="M0 5.83333V11.0833C0 12.6916 1.30842 14 2.91667 14H11.0833C12.6916 14 14 12.6916 14 11.0833V5.83333H0ZM8.75 10.5H5.25C4.928 10.5 4.66667 10.2387 4.66667 9.91667C4.66667 9.59467 4.928 9.33333 5.25 9.33333H8.75C9.072 9.33333 9.33333 9.59467 9.33333 9.91667C9.33333 10.2387 9.072 10.5 8.75 10.5ZM0 4.66667V4.08333C0 2.47508 1.30842 1.16667 2.91667 1.16667H3.5V0.583333C3.5 0.261333 3.76133 0 4.08333 0C4.40533 0 4.66667 0.261333 4.66667 0.583333V1.16667H9.33333V0.583333C9.33333 0.261333 9.59467 0 9.91667 0C10.2387 0 10.5 0.261333 10.5 0.583333V1.16667H11.0833C12.6916 1.16667 14 2.47508 14 4.08333V4.66667H0Z" fill="#366CFE" />
            </g>
            <defs>
                <clipPath id="clip0_2230_2527">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default CalendarIcon