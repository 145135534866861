import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {getGuardDashboardAction} from "../../../redux/guard/action"
import { Backdrop, CircularProgress } from '@mui/material';
import GuardRecentJob from './guardRecentJob';
import GuardDashboardMyJobs from './guardMyJobs';

function GuardDashboard() {
    const dispatch = useDispatch();
    const { getGuardDashboard } = useSelector((state) => state.GuardSection);
  const { getGuardDashboardLoading } = useSelector(
    (state) => state.GuardSection
  );

  const [dashboardData, setDashboardData] = useState();


  useEffect(() => {
    dispatch(getGuardDashboardAction());
  }, [])

  useEffect(() => {
    if (getGuardDashboard?.success) {
      setDashboardData(getGuardDashboard?.data);
    }
  }, [getGuardDashboard]);
  
  return (
    <> <div className="p-10 bg-[#F1F2F6] w-full">
    <div className="grid grid-cols-3 w-full gap-5">
      <GuardDashboardMyJobs data={dashboardData?.myJobsData} />
      <GuardRecentJob data={dashboardData?.mostRecentJobs} />
    </div>
  </div>

  {getGuardDashboardLoading ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={getGuardDashboardLoading}
      // onClick={handleClose}
    >
      <CircularProgress style={{ color: "#083558" }} />
    </Backdrop>
  ) : null}</>
  )
}

export default GuardDashboard